import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'jstree';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { prdtRuleDetailsModel } from 'src/app/common/models/ProductRuleDetailsModel';
import { SIR_SHOW_MORE_COLUMNS } from 'src/app/common/models/PureFnModel';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { CFDetails, recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj, SirDetails } from 'src/app/common/models/SirModel';
import { VIEW_MINIMUM_RULES_COLUMNS, VIEW_MINIMUM_RULES_COLUMNS_EXCEL } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { GlobalVariableUpdateService } from 'src/app/common/services/global-variable-update.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from '../../../environments/environment';
import { AssessRequestRaService } from '../assess-request-ra.service';
@Component({
  selector: 'app-update-rules-ra',
  templateUrl: './update-rules-ra.component.html',
  styleUrls: ['./update-rules-ra.component.scss']
})
export class UpdateRulesRaComponent implements OnInit {
  overlayText = ''; loadingMessage = ''; selectedRPC = '';
  hideOverlay = true;
  unileverMarketObj = {
    clusterId: null,
    clusterName: null,
    marketFullText: null,
    marketId: '',
    marketName: 'Unilever',
    parentMarket: ''
  };
  element: HTMLElement;
  marketsToSelect = []; recipeData = []; rpcdata = []; markets = [];
  activeTabUR = 'tmrm-mapping';
  expandedView = false; isExpanded = false; disableTooltip = false;
  pureCodesList: any; division: any; formulationclass: any; formulationtype: any; formulationsubclass: any; formulationCategory: any;
  updtForm: FormGroup;
  market: any; rpcDataTop;
  selectedMarket; sentPureCodes;
  checkedPureCodes = []; switchedPureCodes = []; switchedSirDetails = []; selectedSirDetails = []; viewRulesData = [];
  loading = false; isDisabled = false; isCompleted = false; panelOpenState = true; completed = false; disableUpdateBtn = true;
  state: string;
  readOnlyValue = true;
  selectedIndex = 0; validationCount = 0;
  selectedPureDescription = 'Not Available';
  selectedRuleBucket; componentGlobal;
  sirDetails = Array<SirDetails>();
  selectedSir; commentsToUser = ''; requestId;
  senderEmail; expandedPureCode; selectedSirName; expandedPureSummary;
  sirIssuesResolvedFlag; checkedFlag; recipeModelTemp;
  goToWorklistConfirmation = false; notifyGPCDialog = false; showMoreDialog = false; isViewRules = false;
  environmentVariables = environment;
  showMoreColumns = []; showMoreData = []; rpcToSelect = [];
  refreshMsg = 'Refresh Mapped Regulatory Product Classification';
  prefMsg = 'Create Market RPC/TFC-Market RPC mapping';
  viewSubMsg = 'View all existing rules for the substance';
  viewSubDataMsg = 'Expand to view the substance data';
  viewRuleColumns = VIEW_MINIMUM_RULES_COLUMNS.filter(f => f.filter !== 'N');
  viewRuleColumnsExcel = VIEW_MINIMUM_RULES_COLUMNS_EXCEL;
  exstingRulesTitle = '';
  messageParent = 'CAS no. fetched from Parent substance in SIR';
  warningTextOpen = false;
  tablewarningComment = '';
  commentRD = '';
  isColumnsHasHyperLinks = 'Yes';
  userDetails;
  isRAAndRD = false;
  isRACountryRAGlobal = false;
  disableTabs = false;
  constructor(
    private dataService: DataService, private assessRequestRa: AssessRequestRaService, public formBuilder: FormBuilder,
    public matSnackBar: MatSnackBar, public globalVariableUpdate: GlobalVariableUpdateService, private route: Router, private worklistService: WorklistService
  ) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    const isRAGlobalUser = this.userDetails.roles.filter(f =>  f.roleId === 130 || f.roleId === 140 || f.roleId === 150);
    const reqDivisionId = (recipeModel.tfc.tfcLevel === 5) ? recipeModel.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : recipeModel.tfc.parentTFC.parentTFC.parentTFC.tfcId;
    const reqCategoryID = (recipeModel.tfc.tfcLevel === 5) ? recipeModel.tfc.parentTFC.parentTFC.parentTFC.tfcId : recipeModel.tfc.parentTFC.parentTFC.tfcId;
    const isRARAGlobalRowMatched = (isRAGlobalUser.length > 0 && isRAGlobalUser.filter(m => ((m.market !== null && m.market.marketId === recipeModel.marketDetails.marketId) || m.market === null) && ((m.tfc !== null && (m.tfc.tfcId === reqDivisionId || m.tfc.tfcId === reqCategoryID)) || m.tfc === null)).length > 0);
    if (isRARAGlobalRowMatched) {
      this.disableTabs = false;
    } else  {
      this.disableTabs = true;
    }
    this.dataService.getComponentNameSvc.subscribe((val) => {
      this.componentGlobal = val;
      if (val === 'CreateInsertRule') {
        this.activeTabUR = 'update-rule';
      } else {
        this.activeTabUR = 'tmrm-mapping';
      }
    });
    this.dataService.getRuleTypeVal().subscribe((val) => {
      if (val === 'Substance Based Rules') {
        this.setRuleBucket('Substance-Based');
      } else if (val === 'Substance Group Rules') {
        this.setRuleBucket('Substance-Group');
      } else if (val === 'Product Based Rules') {
        this.setRuleBucket('Product-Based');
      }
    });
    this.dataService.datarecipe.subscribe((values) => {
      this.requestId = values.requestDisplayID;
    });
    this.dataService.setSelectedStep(1);
    this.createUpdtFormStructure();
    if (this.activeTabUR === 'tmrm-mapping') {
      this.setRuleBucket('Substance-Based');
    }
    this.pureCodesList = recipeModel.pureCodeList;
    this.showMoreColumns = SIR_SHOW_MORE_COLUMNS;
    if (recipeModel.marketDetails) {
      this.selectedMarket = recipeModel.marketDetails.marketName;
      this.market = recipeModel.marketDetails.marketName;
      pureRulesDetailsModel.Market = recipeModel.marketDetails;
      groupRulesDetailsModel.Market = recipeModel.marketDetails;
      prdtRuleDetailsModel.Market = recipeModel.marketDetails;

      this.getFallbackMarkets();
    }
    this.requestId = recipeModel.requestDisplayID;
    this.updtForm = this.formBuilder.group({
      division: recipeModel.division,
      formulationType: recipeModel.formulationType,
      formulationClass: recipeModel.formulationClass,
      formulationSubClass: recipeModel.formulationSubClass,
      formulationCategory: recipeModel.formulationCategory
    });
    if (this.selectedMarket) {
      this.loading = true;
      this.loadingMessage = 'Fetching mapped RPC details';
    }
    if (recipeModel.requestStatus === 'In Progress – RA') {
      this.checkedFlag = true;
    }
  }

  onTabChange(activeTab) {
    ruleFlags.editRule = false;
    ruleFlags.viewRule = false;
    this.activeTabUR = activeTab;
    if (this.activeTabUR === 'update-rule' && recipeModel.requestStatus === 'In Progress – R&D') {
      const requestStatus = 'In Progress – RA';
      const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + this.requestId + '/status/' + requestStatus + '/comment/SystemGenerated';
      this.loading = true;
      this.loadingMessage = 'Request Status Update';
      this.assessRequestRa.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
        if (statusresponse.message !== null) {
          recipeModel.requestStatus = requestStatus;
          this.loadingMessage = 'Fetching data from SIR';
          const val: any = {
            requestID: recipeModel.requestDisplayID,
            requestStatus: requestStatus
          };
          this.worklistService.setRecipeData(val);
        }
      }, (error) => {
        this.loading = false;
      });
    }
    if (this.pureCodesList && this.activeTabUR === 'update-rule') {
      this.loading = true;
      this.loadingMessage = 'Fetching data from SIR';
      this.getSirDetails(this.pureCodesList);
    }
  }

  displayShowMoreDialog(pureCode) {
    this.showMoreDialog = true;
    const apiurl = environment.APIFAsT + '/getPureLevelSpecData/' + this.requestId + '/' + pureCode;
    this.loadingMessage = 'Getting Pure Level Specification';
    this.loading = true;
    this.showMoreData = [];
    this.assessRequestRa.getData(apiurl).subscribe((response) => {
      this.loading = false;
      this.showMoreData = response;
    });
  }

  closeShowMore() {
    this.showMoreDialog = false;
  }

  createUpdtFormStructure() {
    this.updtForm = this.formBuilder.group({
      division: [''],
      formulationType: [''],
      formulationClass: [''],
      formulationSubClass: [''],
      formulationCategory: ['']
    });
  }
  getSirDetails(pureCodes) {
    this.checkedPureCodes = []; // emptying the checked list for disabling notify sir btn
    this.switchedPureCodes = [];
    this.switchedSirDetails = []; // emptying the switched list for disabling notify gpc btn
    const apiurl = environment.APIFAsT + '/sircf/' + pureCodes + '/request/' + this.requestId;
    this.loading = true;
    this.assessRequestRa.getData(apiurl).subscribe((response) => {
      this.sirDetails = response;
      this.globalVariableUpdate.updateSIRIds(this.sirDetails);
      this.loading = false;
      if (this.sirDetails || this.sirDetails !== null) {
        for (const value of this.sirDetails) {
          if (value.sirId === 'SIR ID Not found') {
            this.isDisabled = true;
          }
          if (value.cfDetails !== null) {
            if (value.cfDetails.length > 1) {
              value.selectedCF = value.cfDetails[0].cfDisplayText;
            }
          }
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }

  notifySIRClicked(event) {
    this.sentPureCodes = this.checkedPureCodes.toString();
    if (this.checkedPureCodes.length !== 0) {
      const data = {
        NotificationGroup: 'SIR',
        Subject: null,
        EmailBody: null,
        ToEmailList: null,
        FromEmail: null,
        CCEmailList: null,
        EmailType: 'PlainText',
        UPD: recipeModel.division,
        PureCodes: this.sentPureCodes,
        RequestID: recipeModel.requestDisplayID
      };
      const apiurl = environment.APIFAsT + '/email';
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
        if (response.message !== null) {
          const requestStatus = 'In Progress – SIR';
          const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
          this.loading = true;
          this.loadingMessage = 'Request Status Update';
          this.assessRequestRa.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
            if (statusresponse.message !== null) {
              this.checkedFlag = false;
              this.loading = false;
              const val: any = {
                requestID: recipeModel.requestDisplayID,
                requestStatus: requestStatus
              };
              this.worklistService.setRecipeData(val);
              this.matSnackBar.open('Email message sent to SIR team successfully', 'close', {
                duration: 5000,
                panelClass: ['success']
              });
            }
          }, (error) => {
            this.loading = false;
          });
        }
      }, (error) => {
      });
    } else {
      this.matSnackBar.open('Select the Pure Codes', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  notifyGPCClicked(event, selectedSirDetails) {
    this.removefunctionattr();
    this.selectedSirDetails = selectedSirDetails;
    this.notifyGPCDialog = true;
  }
  removefunctionattr() {
    this.switchedSirDetails.forEach((element) => {
        element.selectedIssueCategory = '';
        element.commentsToUser = '';
    });
}
  /* istanbul ignore next */
  sendMailtoGPC() {
    let emailData = '';
    this.sentPureCodes = this.switchedPureCodes.toString();
    this.selectedSirDetails.forEach(element => {
      emailData = emailData + '\r\nPure Code:' + ' ' + element.pureCode + '\r\n[SIR ID :' + ' ' + element.sirId + ']' +
        '\r\nBackbone Identity :' + ' ' + element.selectedIssueCategory.map((s) => s.value).toString() + '. ' + '\r\n' + 'Comments : ' + ' ' + element.commentsToUser + '\r\n'
        + 'Pure Description : ' + ' ' + element.pureDescription + '\r\n';
    });
    const data = {
      NotificationGroup: 'GPC',
      Subject: null,
      EmailBody: emailData,
      ToEmailList: null,
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: recipeModel.division,
      PureCodes: this.sentPureCodes,
      RequestID: recipeModel.requestDisplayID
    };
    const apiurl = environment.APIFAsT + '/email';
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      if (response.message !== null) {
        const requestStatus = 'In Progress – SIR';
        const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
        this.loading = true;
        this.loadingMessage = 'Request Status Update';
        this.assessRequestRa.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
          if (statusresponse.message !== null) {
            this.loading = false;
            this.checkedFlag = false;
            const val: any = {
              requestID: recipeModel.requestDisplayID,
              requestStatus: requestStatus
            };
            this.worklistService.setRecipeData(val);
            this.matSnackBar.open('Email message sent to GPC team successfully', 'close', {
              duration: 5000,
              panelClass: ['success']
            });
          }
        }, (error) => {
          this.loading = false;
          // this.handleApiErrors(error);
        });
      }
    }, (error) => {
    });
  }
  onCheck(event, checkedPureCode) {
    if (event.checked === true) {
      this.checkedPureCodes.push(checkedPureCode);
    } else if (event.checked === false) {
      const index = this.checkedPureCodes.findIndex(d => d === checkedPureCode);
      this.checkedPureCodes.splice(index, 1);
    }
  }
  onExpand(pureSirDetails) {
    this.expandedView = true;
    this.expandedPureCode = pureSirDetails.pureCode;
    this.expandedPureSummary = pureSirDetails.pureDescription;
    this.dataService.setSelectedPureValue(this.expandedPureCode);
    this.sendSirId(pureSirDetails);
    Object.assign(sirDetailObj, pureSirDetails);
    pureRulesDetailsModel.SirId = pureSirDetails.sirId;
    pureRulesDetailsModel.selectedRpcs = [];
    localStorage.setItem('pureSirDetails', JSON.stringify(pureSirDetails));
  }
  backToListClick() {
    this.dataService.setExpandablePanel(0);
    this.dataService.setComponentObs(null);
    this.expandedView = false;
    // take default CF value from recipie model
    this.recipeModelTemp = recipeModel;
    this.recipeModelTemp.formulationRequestDetails.forEach(r => {
      this.sirDetails.forEach(sirdata => {
        if (sirdata.pureCode === r.pureCode) {
          sirdata.selectedCF = r.userSelectedCFDetails !== null ? r.userSelectedCFDetails.cfDisplayText : null;
        }
      });
    });
    pureRulesDetailsModel.Market = recipeModel.marketDetails;
  }
  setRuleBucket(ruleBucketValue) {
    this.selectedRuleBucket = ruleBucketValue;
    this.dataService.setExpandablePanel(0);
    localStorage.setItem('selectedRuleBucket', ruleBucketValue);
  }
  /* istanbul ignore next */
  getRPCs(market) {
    this.loading = true;
    this.loadingMessage = 'Getting RPC Details...';
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfcFullText)));
    const apiurl = environment.APIFAsT + '/markets/' + market + '/tfcname/' + encodedTfcName + '/rpcs/fortree';
    this.assessRequestRa.getData(apiurl).subscribe((data) => {
      this.rpcdata = data;
      if (this.rpcdata) {
        for (const value of this.rpcdata) {
          if (value.text === 'No Regulatory Product Class is Mapped') {
            this.disableUpdateBtn = true;
          } else {
            this.disableUpdateBtn = false;
          }
        }
      }
      this.createRpcTree(this.rpcdata);
      this.loading = false;
    }
      , (error) => {
        this.loading = false;
        this.disableUpdateBtn = true;
      });
  }
  /* istanbul ignore next */
  createRpcTree(rpcdata) {

    $('#treeDiv').on('changed.jstree', (evt, data) => {
      $('#treeDiv').jstree(true).set_icon(data.node, 'https://jstree.com/tree-icon.png');
    });

    // tslint:disable-next-line: no-shadowed-variable
    $('#treeDiv').on('changed.jstree', (e, rpcdata) => {
    }).jstree({
      plugins: ['changed'],
      checkbox: {
        three_state: false

      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',

      // tslint:disable-next-line: no-shadowed-variable  // tslint:disable-next-line: space-before-function-paren
    }).bind('loaded.jstree', function (event, rpcdata) {
      $(this).jstree('open_all');
    });

    //  $('#search-tree').jstree(true).refresh();
    $('#treeDiv').jstree(true).redraw(true);
  }

  /* istanbul ignore next */
  onMarketChange(filterVal: any) {
    this.selectedRPC = '';
    this.rpcdata = [];
    $('#treeDiv').jstree('destroy').empty();
    this.getRPCDataBasedOnMarket(filterVal.value.marketName);
    this.market = filterVal.value.marketName;
    this.disableUpdateBtn = true;
  }

  handleSwitch(event, sirvalue) {
    sirvalue.issueCategoryList = [
      { name: 'CAS#', value: 'CAS#' },
      { name: 'ParentCAS#', value: 'ParentCAS#' },
      { name: 'E#', value: 'E#' },
      { name: 'INS#', value: 'INS#' },
      { name: 'EINECS#', value: 'EINECS#' },
      { name: 'INCI', value: 'INCI' },
      { name: 'Synonym', value: 'Synonym' },
      { name: 'CF details', value: 'CF details' },
    ];
    sirvalue.selectedIssueCategory = [];

    if (event.checked === true) {
      this.switchedSirDetails.push(sirvalue);
      this.switchedPureCodes.push(sirvalue.pureCode);
    } else if (event.checked === false) {
      const index = this.switchedPureCodes.findIndex(d => d === sirvalue.pureCode);
      this.switchedPureCodes.splice(index, 1);
      this.switchedSirDetails.splice(index, 1);
    }
  }

  onRefresh() {
    this.loadingMessage = 'Fetching Regulatory Product Class';
    $('#treeDiv').jstree('destroy').empty();
    this.getRPCDataBasedOnMarket(this.market);
  }
  expandPanel() {
    this.isExpanded = !this.isExpanded;
  }
  onChangeCfDetails(event, sirDetail) {
    const selectedCFDropdown = sirDetail.cfDetails[event.target.selectedIndex];
    this.recipeModelTemp = recipeModel;
    this.recipeModelTemp.formulationRequestDetails.forEach(r => {
      if (r.pureCode === sirDetail.pureCode) {
        r.userSelectedCFDetails = new CFDetails();
        r.userSelectedCFDetails.cf = selectedCFDropdown.cf;
        r.userSelectedCFDetails.cfDisplayText = selectedCFDropdown.cfDisplayText;
        r.userSelectedCFDetails.component = selectedCFDropdown.component;
        r.userSelectedCFDetails.componentMolWeight = selectedCFDropdown.componentMolWeight;
        r.userSelectedCFDetails.expressedAs = selectedCFDropdown.expressedAs;
        r.userSelectedCFDetails.numberOfExpressedAs = selectedCFDropdown.numberOfExpressedAs;
        pureRulesDetailsModel.selectedCF = r.userSelectedCFDetails;
      }
    });
    Object.assign(recipeModel, this.recipeModelTemp);

    this.sirDetails.forEach(t => {
      if (t.pureCode === sirDetail.pureCode) {
        t.selectedCF = selectedCFDropdown.cfDisplayText;

      }
    });
  }

  navigateToWorklist() {
    this.route.navigate(['/worklist/master-worklist']);
  }

  onPrepareRuleNextClicked() {
    this.dataService.setSelectedStep(2);
    this.route.navigate(['assess-request-ra/execute-rules']);
  }

  rulesListForPure(value) {
    console.log('rules from Db', value);
  }
  rulesListForGroup(value) {
    console.log('rules from Db', value);
  }
  handleSirIssueSwitch(event) {
    let requestStatus;
    if (event.checked) {
      requestStatus = 'In Progress – RA';
    } else if (!event.checked) {
      requestStatus = 'In Progress – SIR';
    }
    const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
    this.loading = true;
    this.loadingMessage = 'Request Status Update';
    this.assessRequestRa.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
      if (statusresponse.message !== null) {
        this.loading = false;
        const val: any = {
          requestID: recipeModel.requestDisplayID,
          requestStatus: requestStatus
        };
        this.worklistService.setRecipeData(val);
      }
    }, (error) => {
      this.loading = false;
    });
  }
  goToPrefClicked() {
    const url = environment.PREFADMIN;
    window.open(url, '_blank');
  }
  getFallbackMarkets() {
    this.loading = true;
    const apiurl = environment.APIFAsT + '/markets/' + recipeModel.marketDetails.marketName + '/assessmentOrder';
    this.assessRequestRa.getData(apiurl).subscribe((data) => {
      this.loading = false;
      if (data !== null) {
        const fallBackMarkets = data;
        for (const value of fallBackMarkets) {
          this.marketsToSelect.push(value);
        }
      } else {
        this.marketsToSelect.push(recipeModel.marketDetails);
      }
      this.getRPCDataBasedOnMarket(this.market);
    }, (error) => {
      this.loading = false;
    });
  }

  getRPCDataBasedOnMarket(marketSelected) {
    this.selectedRPC = '';
    this.rpcdata = [];
    this.loading = true;
    const result = this.marketsToSelect.filter(x => x.marketName === marketSelected)[0];
    const url = environment.APIFAsT + '/marketid/' + result.marketId + '/tfcid/' + recipeModel.tfc.tfcId + '/rpclist';
    this.assessRequestRa.getData(url).subscribe((response) => {
      this.loading = false;
      if (response.length > 0) {
        this.rpcToSelect = response;
        this.onRPCChangedLoad(response[0]);
      } else {
        this.disableUpdateBtn = true;
        this.rpcToSelect = [];
        this.selectedRPC = '';
        this.getTreeDataBasedOnMarket(response);
        this.matSnackBar.open('No RPCs are found for market ' + this.market, 'close', {
          duration: 3000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }
  onRPCChanged(selectedRPC) {
    this.getTreeDataBasedOnMarket(selectedRPC.value);
  }
  onRPCChangedLoad(selectedRPCOnLoad) {
    this.getTreeDataBasedOnMarket(selectedRPCOnLoad);
  }
  /* istanbul ignore next */
  getTreeDataBasedOnMarket(rpcVal) {
    this.loading = true;
    $('#treeDiv').jstree('destroy').empty();
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfc.tfcFullText)));
    let url;
    const result = this.marketsToSelect.filter(x => x.marketName === this.market)[0];
    if (rpcVal.rpcId !== undefined) {
      url = environment.APIFAsT + '/marketid/' + result.marketId + '/tfcid/' + recipeModel.tfc.tfcId + '/rpcs/fortree/rpc/' + rpcVal.rpcId;
    } else {
      url = environment.APIFAsT + '/marketid/' + result.marketId + '/tfcid/' + recipeModel.tfc.tfcId + '/rpcs/fortree/rpc/0';
    }

    this.assessRequestRa.getData(url).subscribe((data) => {
      this.loading = false;
      this.rpcdata = data;
      if (this.rpcdata.length > 0) {
        for (const value of this.rpcdata) {
          if (value.text === 'No Regulatory Product Class is Mapped') {
            this.disableUpdateBtn = true;
          } else {
            this.disableUpdateBtn = false;
          }
        }
        this.createRpcTree(this.rpcdata);
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  showExistingRules(item: any) {
    this.exstingRulesTitle = `Existing Rules for ${item.pureCode}`;
    const primaryMarket = this.marketsToSelect[0]; // this.marketsToSelect.filter(m => m.marketLevel === 0);
    const payLoad = {
      MarketList: [primaryMarket],
      RPCList: this.rpcdata.map(rpc => {
        return {
          rpcId: rpc.id,
          rpcName: rpc.text,
          rpcFullText: rpc.fullText
        };
      }),
      SIRIds: [item.sirId],
      ruleClassifications: [
        'Substance Based Rules'
      ],
      RuleFlow: '2445',
      ActiveStatusList: ['Y']
    };
    this.loading = true;
    this.loadingMessage = this.exstingRulesTitle;
    const apiurl = `${environment.APIFAsTRule}/rules/rulesFilter`;
    this.assessRequestRa.postData(apiurl, payLoad).subscribe((response) => {
      const versionDetails = [];
      response.forEach((i) => {
        i.ruleVersion = i.ruleVersion === undefined || null ? '' : `V${i.ruleVersion}`;
        i.cf = i.cfDetails === null ? '' : i.cfDetails.cf;
        i.warningText = (i.warningText !== null) ? i.warningText : ((i.warningStatement !== null) ? i.warningStatement : '');
        if (i.warningText !== '') {
          i.originalWarningText = i.warningText,
            i.warningText = this.changeComment(i.warningText);
          i.warningTextStripped = this.handleSpecialCharacters(i.originalWarningText);
          const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet(i.originalWarningText);
          i.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          i.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          i.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          i.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          i.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          i.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          i.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
        }
        if (i.ruleTypeID !== 45) {
          versionDetails.push(i);
        }
      });
      this.viewRulesData = versionDetails;
      this.isViewRules = true;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });

  }

  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }

  hoverViewExistingRules(flag) {
    this.disableTooltip = flag;
  }

  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }

  sendSirId(data) {
    this.assessRequestRa.transposeSubstanceBaseData(data);
  }
}
