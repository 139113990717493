import { Component, OnInit, Input } from '@angular/core';
import * as fs from 'file-saver';
import * as Excel from 'exceljs';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import * as _ from 'lodash';
import { K } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-export-excel-button',
  templateUrl: './export-excel-button.component.html',
  styleUrls: ['./export-excel-button.component.scss']
})
export class ExportExcelButtonComponent implements OnInit {
  @Input() columnsData;
  @Input() rowsData = [];
  @Input() sheetName;
  @Input() isIcon;
  @Input() buttonName;
  @Input() isMultipleSheet = false;
  @Input() isFileNameAmendDate = 'No';
  @Input() isColumnsHasHyperLinks = 'No';
  @Input() HyperLinksStartColumnNumber = 0;
  @Input() isColumnValuesExchange = 'No';
  @Input() fileName = '';
  loading = false;
  constructor() { }

  ngOnInit() {
  }

  async exportToExcel() {
    this.loading = true;
    if (!this.isMultipleSheet) {
      await this.downloadExcel();
    } else {
      await this.downloadMultpleSheetExcel();
    }
  }

  downloadExcel() {
    const wb = new Excel.Workbook();
    // Sheet name should not be more than 32 characters
    const sSheetName = this.sheetName.substring(0, 31);
    const ws = wb.addWorksheet(sSheetName);
    ws.getRow(1).fill = {
      type: 'gradient',
      gradient: 'angle',
      degree: 0,
      stops: [
        { position: 0, color: { argb: 'A5E1F7' } },
        { position: 1, color: { argb: 'A5E1F7' } }
      ]
    };

    const COLUMNS = [];

    if (!this.columnsData) {
      this.loading = false;
      return;
    }

    this.columnsData.forEach(column => {
      if (column.field) {
        COLUMNS.push({
          header: column.header,
          key: column.field,
          width: column.header.length + 10
        });
      }
      if (column.fieldName) {
        if (column.fieldName !== 'check') {
          COLUMNS.push({
            header: column.fieldDescription,
            key: column.fieldName,
            width: column.fieldDescription.length + 10
          });
        }
      }
    });
    ws.columns = COLUMNS;
    if (!this.rowsData) {
      this.loading = false;
      return;
    }
    this.rowsData.forEach((row, index) => {
      const rowdata = this.buildRowData(COLUMNS, row);
      const res = ws.addRow(rowdata);
      if (row.tfc1status === 0) {
        for (let i = 1; i < COLUMNS.length; i++) {
          if (COLUMNS[i - 1]['key'] === 'requestDisplayID' || COLUMNS[i - 1]['key'] === 'division' || COLUMNS[i - 1]['key'] === 'rDFormulationCategory' || COLUMNS[i - 1]['key'] === 'formulationType' || COLUMNS[i - 1]['key'] === 'formulationClass' || COLUMNS[i - 1]['key'] === 'formulationSubClass') {
            const col = res.getCell(i);
            col.font = { underline: false, color: { argb: 'ff0000' } };

          }
        }
        this.setTooltTip(ws, index + 2);
      }
      // debugger;
      if (row.requestNotification === '1') {
        for (let i = 1; i < COLUMNS.length; i++) {
          if (COLUMNS[i - 1]['key'] === 'requestDisplayID' || COLUMNS[i - 1]['key'] === 'division' || COLUMNS[i - 1]['key'] === 'rDFormulationCategory' || COLUMNS[i - 1]['key'] === 'formulationType' || COLUMNS[i - 1]['key'] === 'formulationClass' || COLUMNS[i - 1]['key'] === 'formulationSubClass') {
            const col = res.getCell(i);
            col.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'f8bf49' }
            };

          }
        }
        this.setTooltTipRequestNotification(ws, index + 2);
      }
    });
    if (this.isColumnsHasHyperLinks.toLowerCase() === 'yes') {
      ws.eachRow((row, rowNumber) => {
        row.eachCell((cell, colNumber) => {
          if (colNumber >= this.HyperLinksStartColumnNumber && rowNumber > 1) {
            cell.font = { bold: true, underline: true, color: { argb: '003e66' } };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
        });
      });
    }
    const d = new Date();
    const date = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();

    wb.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${this.sheetName}-${this.isFileNameAmendDate === 'Yes' ? date : recipeModel.requestDisplayID === (null || undefined) ? date : recipeModel.requestDisplayID}.xlsx`);
      this.loading = false;
    });
  }

  downloadMultpleSheetExcel() {
    const wb = new Excel.Workbook();
    let sheetNames = [];
    let ishyperlinks = [];
    if (this.columnsData.length > 0) {
      sheetNames = this.sheetName.split(',');
      ishyperlinks = this.isColumnsHasHyperLinks.split(',');
      let index = 0;
      this.columnsData.forEach(subColumnsData => {
        const ws = wb.addWorksheet(sheetNames[index].toString());
        ws.getRow(1).fill = {
          type: 'gradient',
          gradient: 'angle',
          degree: 0,
          stops: [
            { position: 0, color: { argb: 'A5E1F7' } },
            { position: 1, color: { argb: 'A5E1F7' } }
          ]
        };

        const COLUMNS = [];
        subColumnsData.forEach(column => {
          if (column.field) {
            COLUMNS.push({
              header: column.header,
              key: column.field,
              width: column.header.length + 10
            });
          }
        });
        ws.columns = COLUMNS;
        if (!this.rowsData) {
          this.loading = false;
          return;
        }
        for (let rowIndex = 0; rowIndex < this.rowsData.length; rowIndex++) {
          if (index === rowIndex) {
            for (let row = 0; row < this.rowsData[rowIndex].length; row++) {
              const rowdata = this.buildRowData(COLUMNS, this.rowsData[rowIndex][row]);
              ws.addRow(rowdata);
            }
          }
        }
        if (index < ishyperlinks.length && ishyperlinks[index].toLowerCase() === 'yes') {
          ws.eachRow((row, rowNumber) => {
            row.eachCell((cell, colNumber) => {
              if (colNumber >= this.HyperLinksStartColumnNumber && rowNumber > 1) {
                cell.font = { bold: true, underline: true, color: { argb: '003e66' } };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
              }
            });
          });
        }
        index = index + 1;
      });
      const d = new Date();
      const date = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
      this.fileName = this.fileName !== '' ? this.fileName : sheetNames[0];
      wb.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, `${this.fileName}-${this.isFileNameAmendDate === 'Yes' ? date : recipeModel.requestDisplayID === (null || undefined) ? date : recipeModel.requestDisplayID}.xlsx`);
        this.loading = false;
      });
    }
    this.loading = false;
  }

  buildRowData(columns, row) {
    const tempRowData = [];
    columns.forEach(column => {
      if (column['key'] === 'rdComment' && this.isColumnValuesExchange.toLowerCase() === 'yes') {
        tempRowData.push(row.rdPlainComment);
      } else if (column['key'] === 'reportComments' && this.isColumnValuesExchange.toLowerCase() === 'yes') {
        tempRowData.push(row.PlainReportComment);
      } else if (column['key'] === 'warningText' && this.isColumnValuesExchange.toLowerCase() === 'yes') {
        tempRowData.push(row.warningTextStrippedExcel);
      } else if (column['key'] === 'otherRestrictionsAndRequirements' && this.isColumnValuesExchange.toLowerCase() === 'yes') {
        tempRowData.push(row.otherRestrictionsAndRequirementsStrippedExcel);
      } else {
        tempRowData.push(row[column['key']]);
      }
    });
    return tempRowData;
  }
  /* istanbul ignore next */
  setTooltTip(worksheet, rowNumber) {
    const tfcpathtooltip = 'The PLM TFC has been remapped/retired. Please create a new request with updated PLM TFC.';
    const columnLetter = worksheet.getColumnKey('requestDisplayID').letter;
    worksheet.getCell(columnLetter + rowNumber).note = {
      texts: [{ font: { size: 9, bold: true, color: { argb: 'e82532' } }, text: tfcpathtooltip }],
      margins: {
        insetmode: 'auto',
      },
      protection: {
        locked: true,
        lockText: true
      }
    };
  }
  /* istanbul ignore next */
  setTooltTipRequestNotification(worksheet, rowNumber) {
    const notificationtip = 'For the most up to date Formulation Assessment, please submit a new request.';
    const columnLetter = worksheet.getColumnKey('requestDisplayID').letter;
    worksheet.getCell(columnLetter + rowNumber).note = {
      texts: [{ font: { size: 9, bold: true, color: { argb: 'e82532' } }, text: notificationtip }],
      margins: {
        insetmode: 'auto',
      },
      protection: {
        locked: true,
        lockText: true
      }
    };
  }
}
