import 'jstree';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as $ from 'jquery';
// import { DataService } from 'src/app/common/services/data.service';
import {
  DETAILS_POPUP,
  PRODUCT_DETAILS_COLUMNS,
  PRODUCT_VIEW_DETAILED_REPORT_COLUMNS,
  PROPERTY_DETAIL_COLUMNS,
  REPORT_PURE_DETAILS,
  VIEW_DETAILED_REPORT,
  DETAILS_INGREDIENT_POPUP,
  PRODUCT_VIEW_DETAILED_REPORT_COLUMNS_VIEW,
  PRODUCT_DETAILS_COLUMNS_VIEW,
  PROPERTY_DETAIL_COLUMNS_VIEW,
  PROPERTY_DETAIL_COLUMNS_TAB_VIEW,
} from 'src/app/common/models/PureFnModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { VIEW_MINIMUM_RULES_COLUMNS, VIEW_PRODUCT_SUBSTANCE_RULE_COLUMNS } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { ExportReportExcelService } from 'src/app/common/services/export-report-excel.service';
import { environment } from 'src/environments/environment';

import { WorklistService } from '../../../../app/worklist/worklist.service';
import { AssessRequestRaService } from '../../assess-request-ra.service';
import { ReportSubmitService } from '../report-submit.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-submit-report-plm',
  templateUrl: './submit-report-plm.component.html',
  styleUrls: ['./submit-report-plm.component.scss']
})
export class SubmitReportPlmComponent implements OnInit {
  blinkcomment = false;
  editorData: '';
  compTotalPure = 0;
  compostionTab = [];
  prodTab = [];
  overlayText = '';
  hideOverlay = true;
  loadingMessage = '';
  loading = false;
  isViewProductSubstanceRules = false;
  viewRulesRpcdata = [];
  unileverrpcdata = [];
  marketrpcdata = [];
  formulationRequestDetailsSIRIds = [];
  reportData = {
    requestID: '',
    formulationRequestDetails: [],
    marketRPCList: [],
    marketDetails: {
      marketId: 0,
      marketName: ''
    },
    isMultiComponent: '',
    multiComponent: [{
      multiComponentID: 0,
      multiComponentName: ''
    }],
    tfc: {
      tfcId: '262',
      tfcName: 'TestString@123',
      tfcFullText: 'TestString@123',
      tfcLevel: 5,
      parentTFC: {
        tfcId: '653',
        tfcName: 'Bar',
        tfcFullText: 'TestString@123',
        tfcLevel: 4,
        parentTFC: {
          tfcId: '15',
          tfcName: 'TestString@123',
          tfcFullText: 'TestString@123',
          tfcLevel: 3,
          parentTFC: {
            tfcId: '4',
            tfcName: 'TestString@123',
            tfcFullText: 'TestString@123',
            tfcLevel: 2,
            parentTFC: {
              tfcId: '1',
              tfcName: 'TestString@123',
              tfcFullText: 'TestString@123',
              tfcLevel: 1,
              parentTFC: null
            }
          }
        }
      }
    },
    compositionTabData: [],
    rulesResults: [],
    groupRuleResults: [],
    fragranceRuleResults: [],
    productWarnings: [],
    productPropertiesResults: [],
  };
  allSubstancesMandatory = '';
  allSubstancesMandatoryMessage = false;
  compositionReportBarTooltipText = '';
  compositionReportBarMessage = '';
  compositionOverallStatus = '';
  productReportBarTooltipText = '';
  tfcStatus = 1;
  productReportBarMessage = '';
  productOverallStatus = '';
  uniquePures = [];
  multiComponentUniquePures = [];
  initialUniquePures = [];
  uniquePureStatus = [];
  pureListToggle = false;
  pureDetailsColumns = [];
  pureDetailsData = [];
  pureStatus = [];
  viewProductSubstanceRuleColumns = VIEW_PRODUCT_SUBSTANCE_RULE_COLUMNS.filter(f => f.show !== 'N');
  viewProductSubstanceRuleColumnsExcel = VIEW_PRODUCT_SUBSTANCE_RULE_COLUMNS.filter(f => f.filter !== 'N');
  viewProductSubstanceRuleData = [];
  viewProductSubstanceRuleExcelData = [];
  viewProductSubstanceRule = 'Substance List';
  detailsPopupColumns = [];
  detailsFragrancePopupColumns = [];
  detailsPopupData = [];
  detailsFragrancePopupData = [];
  detailsPopupResultData = [];
  totalCompositionPures;
  pureTabContent = [];
  showDetailsPopup = false;
  showViewAll = false;
  displaySize = '217';
  assessmentModel = recipeModel;
  viewDetailedReportDiv = false;
  viewDetailedReportColumns = VIEW_DETAILED_REPORT;
  viewDetailedReportData = [];
  viewDetailedReportDataOrdering = [];
  viewDetailedReportPopupData = [];
  viewDetailedFragranceReportPopupData = [];
  viewDetaildReportPopupFlag = false;
  viewFragranceDetaildReportPopupFlag = false;
  uniqueSpecIDs = [];
  rpcLeafNodes = [];
  unileverRpcPlugins = [];
  marketRpcPlugins = [];
  filteredReportDataRulesResults = [];
  compositionOrProduct = true;
  uniqueProperties = [];
  initialuniqueProperties = [];
  filteredReportDataProductPropertiesResults = [];
  totalProperties = 0;
  initialtotalProperties = 0;
  uniquePropertyStatus = [];
  propertyStatus = [];
  propertyTabContent = [];
  propertyShowViewAll = false;
  propertyDetailsColumns = [];
  // propertyDetailsColumnsTab = [];
  propertyDetailsColumnsNew = [];
  showPropertyProductFlag = false;
  uniqueProductStatus = [];
  uniqueProducts = [];
  totalProducts = 0;
  productStatus = [];
  productTabContent = [];
  reportProductDetailsColumns = [];
  productViewDetailedReport = [];
  productViewDetailedReportColumns = [];
  productViewDetailedReportDiv = false;
  productViewReportDiv = false;
  propertyProductStatus = [];
  totalProductsProperties = 0;
  filteredReportDataGroupRuleResults = [];
  filteredDetailsPopupData = [];
  filteredReportDataformulationRequestDetails = [];
  productOpened = false;
  refinementDetailsPop = false;
  filteredProductData = [];
  isMultiComp = '';
  completeJSON = [];
  uniqueMarkets = [];
  filterFormulation = [];
  findUniqueMarkets = [];
  pageMarketRPC = [];
  pageUnileverRPC = [];
  clusterMarketRPCs = [];
  rpcRefinementRulesResults = [];
  rpcRefinementProductPropertiesResults = [];
  rpcRefinementProductWarnings = [];
  rpcRefinementGroupRuleResults = [];
  multiComponentTabData = [];
  selectedComponentId = 0;
  sortedCompositionData = [];
  selectedMultiComponents = [];
  multiComponentData = [];
  componentIds = [];
  formulationDetailsPopup = false;
  uniqueMultiComponentFormulationRequestDetails = [];
  formulationAlreadyThere = false;
  pipe = new DatePipe('en-US');
  isRpcViewAll = false;
  isCompositionOverAllStatusSet = false;
  isProductOverAllStatusSet = false;
  productPropertyTabCount = [];
  isTabItemSet = false;
  isTabPanel = 'Product';
  compositionChartData = {
    labels: [],
    datasets: [],
    tooltipFontSize: 0,
    tooltipTemplate: '',
    percentageInnerCutout: 0,
  };
  productChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      }
    ]
  };
  compositionChartOptions = {
    title: {
      display: false
    },
    legend: {
      display: false,
      backgroundColor: '',
      labels: {
        fontColor: '',
        fontSize: '',
        usePointStyle: false,
      },
    },
    tooltips: {
      titleFontSize: 0,
      bodyFontSize: 0,
      backgroundColor: '',
      callbacks: {
        labelTextColor: (tooltipItem: any, data: any) => '',
        opacity: (tooltipItem: any, chart: any) => 0,
        afterBody: (tooltipItem: any, data: any) => [],
        label: (tooltipItem: any, data: any) => []
      }
    },
    responsive: false,
    maintainAspectRatio: false
  };
  productChartOptions = {
    title: {
      display: false
    },
    legend: {
      display: false,
    },
    tooltips: {
      titleFontSize: 8,
      bodyFontSize: 8,
      backgroundColor: '',
      callbacks: {
        labelTextColor: (tooltipItem: any, data: any) => '',
        opacity: (tooltipItem: any, chart: any) => 0,
        afterBody: (tooltipItem: any, data: any) => [],
        label: (tooltipItem: any, data: any) => []
      }
    },
    responsive: false,
    maintainAspectRatio: false
  };
  isComposition = true;
  reportMessage = 'This is a system generated compliance report for requested market. Please contact local RA for any queries/clarification.';
  exstingRulesTitle = '';
  isViewRules = false;
  viewRuleColumns = VIEW_MINIMUM_RULES_COLUMNS.filter(f => f.show !== 'N');
  viewRuleColumnsExcel = VIEW_MINIMUM_RULES_COLUMNS.filter(f => f.filter !== 'N');
  viewRulesData = [];
  viewRulesExcelData = [];
  showFragranceDetailsPopup = false;
  detailFragrancePopupResultData = [];
  newOverlayText = '';
  newHideOverlay = true;
  viewReortOverlayText = '';
  viewReortHideOverlay = true;
  popupHideOverlay = true;
  popupOverlayText: '';
  fragranceFlag: string;
  fragranceMessage = '';
  filteredRows: any[] = [];
  isComments = false;
  commentsPayLoad;
  isCompleted = false;
  initialtotalCompositionPures = 0;
  saveCommentButton = false;
  tfcPathMessage = '';
  completedDate: any;
  divisionData = '';
  requestIdData: string;
  divData = '';
  isFRdivision = false;
  requestid = '';
  actualRequestStatus = '';
  iswarningLink = false;
  warningiconenable = false;
  newWarningVal = '';
  isRuleDoesntExistTabtoShow = false;
  newORRVal = '';
  isORRclicked = false;
  isColumnsHasHyperLinks = 'Yes';
  isPopupWarningLink = false;
  newPopupWarningVal = '';
  newPopupORRVal = '';
  isPopupORRclicked = false;
  isPopupCompleted = false;
  keyDate = '';
  validityArea = '';
  validityAreaKeyDate = '';
  showMulticomponent = true;
  constructor(
    private route: Router,
    private reportSubmitService: ReportSubmitService,
    public assessraService: AssessRequestRaService,
    public worklistservice: WorklistService,
    private dataTrans: DataService,
    public matSnackBar: MatSnackBar,
    private exportReportExcelService: ExportReportExcelService,
    private msalSvc: MsalService,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('IsPLmRequest', 'yes');
    const urlId = this.route.url.split('/');
    this.requestid = urlId[3];
    this.divData = urlId[4];
    this.actualRequestStatus = 'Completed';
    this.fragranceFlag = 'true';
    this.isColumnsHasHyperLinks = 'Yes';
    this.assessmentModel = recipeModel;
    this.isColumnsHasHyperLinks = 'Yes';
    this.assessmentModel = recipeModel;

    // this.dataTrans.castUser.subscribe(res => {
    //   this.requestIdData = res;
    //   this.requestid = res !== null ? res.requestDisplayID : recipeModel.requestDisplayID;
    //   this.actualRequestStatus = res !== null ? res.requestStatus : recipeModel.requestStatus;
    //   if (res.division === undefined || res.division === null) {
    //     this.divData = res.tfc.tfcLevel === 5 ? res.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : res.tfc.parentTFC.parentTFC.parentTFC.tfcName;
    //   } else {
    //     this.divData = res.division;
    //   }
    // });
    // this.divData = (this.divData === null || this.divData === '') ? recipeModel.division : this.divData;
    // this.fragranceFlag = localStorage.getItem('FeatureFlagFragrance');
    this.completeRequest();
    this.getRuleAssessmentRequest();
    this.productReportBarTooltipText = '';
    this.pureDetailsColumns = REPORT_PURE_DETAILS;
    this.detailsPopupColumns = DETAILS_POPUP.filter(w => w.show !== 'N');
    this.detailsFragrancePopupColumns = DETAILS_INGREDIENT_POPUP;
    // this.dataTrans.castUser.subscribe(res => {
    //   this.requestIdData = res;
    //   this.requestid = res.requestDisplayID;
    // });
  }
  /* istanbul ignore next */
  viewProductRules(rule) {
    this.viewProductSubstanceRuleData = [];
    this.loading = true;
    this.closeLinkpopups();
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
     this.loadingMessage = 'Fetching Product substance list';
    }
    if (rule.ruleTypeID === 45) {
      this.allSubstancesMandatoryMessage = true;
    } else {
      this.allSubstancesMandatoryMessage = false;
    }
    this.formulationRequestDetailsSIRIds = Array.from(new Set(this.reportData.formulationRequestDetails.map(pure => pure.substanceID)));
    const apiurl = environment.APIFAsTRule + '/rules/ruleID/' + rule.ruleID + '/ruleType/' + rule.ruleTypeID + '/ruleVersion/' + rule.ruleVersion;
    this.assessraService.getData(apiurl).subscribe((response) => {
      this.loading = false;
      this.isViewProductSubstanceRules = true;
      this.allSubstancesMandatory = (response[0].allSubstancesMandatory !== null && response[0].allSubstancesMandatory !== undefined) ? response[0].allSubstancesMandatory : '';
      if ((rule.ruleTypeID === 45 || rule.ruleTypeID === 11) && response[0].substanceList != null) {
        response[0].substanceList.forEach((substance) => {
          this.viewProductSubstanceRuleData.push({
            ruleId: response[0].ruleId,
            ruleType: response[0].ruleType,
            ruleVersion: 'V' + response[0].ruleVersion.toString(),
            ruleDescription: response[0].ruleDescription,
            pureCode: substance.pureCode,
            pureDescription: substance.pureDescription,
            sirId: substance.sirId,
            substanceName: substance.substanceName,
            functionName: (response[0].functionList !== null && response[0].functionList.length >= 1) ? response[0].functionList[0].functionName : '',
            warningText: (response[0].negativeStatement !== null && response[0].negativeStatement !== '') ? response[0].negativeStatement : '',
            warningicon: this.findLinkWarning(((response[0].negativeStatement !== null && response[0].negativeStatement !== '') ? response[0].negativeStatement : '')),
            warningTextStripped: (response[0].negativeStatement !== null && response[0].negativeStatement !== '') ? this.handleSpecialCharacters(response[0].negativeStatement) : '',
            highlight: false
          });
        });
      } else {
        this.viewProductSubstanceRuleData.push({
          ruleId: response[0].ruleId,
          ruleType: response[0].ruleType,
          ruleVersion: 'V' + response[0].ruleVersion.toString(),
          ruleDescription: response[0].ruleDescription,
          pureCode: '',
          pureDescription: '',
          sirId: '',
          substanceName: '',
          functionName: (response[0].functionList !== null && response[0].functionList.length === 1) ? response[0].functionList[0].functionName : '',
          warningText: (response[0].negativeStatement !== null && response[0].negativeStatement !== '') ? response[0].negativeStatement : '',
          warningicon: this.findLinkWarning(((response[0].negativeStatement !== null && response[0].negativeStatement !== '') ? response[0].negativeStatement : '')),
          warningTextStripped: (response[0].negativeStatement !== null && response[0].negativeStatement !== '') ? this.handleSpecialCharacters(response[0].negativeStatement) : '',
          highlight: false
        });
      }

      if (this.allSubstancesMandatory === 'Y') {
        this.viewProductSubstanceRuleData.forEach(substance => {
          if (this.formulationRequestDetailsSIRIds.indexOf(substance.sirId) === -1) {
            substance.highlight = true;
            this.allSubstancesMandatory = 'Y  (Red highlighted substance(s) are mandatory but not present in formulation)';
          }
        });
      }
      this.viewProductSubstanceRuleData.forEach(view => {
        const warningLinkRuleData = this.assessraService.hyperLinksToSheet(view.warningText);
        this.viewProductSubstanceRuleExcelData.push({
          ruleId: view.ruleId,
          ruleType: view.ruleType,
          ruleVersion: view.ruleVersion,
          ruleDescription: view.ruleDescription,
          pureCode: view.pureCode,
          pureDescription: view.pureDescription,
          sirId: view.sirId,
          substanceName: view.substanceName,
          functionName: view.functionName,
          warningText: view.warningTextStripped,
          warningicon: this.findLinkWarning(view.warningText),
          warningTextStripped: view.warningTextStripped,
          highlight: false,
          warningText1: warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '',
          warningText2: warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '',
          warningText3: warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '',
          warningText4: warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '',
          warningText5: warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '',
          warningText6: warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '',
          warningText7: warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : ''
        });
      });

    });
  }
  handleSpecialCharacters(text) {
    if (text === undefined) {
      return '';
    } else {
    return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
    }
  }
  /* istanbul ignore next */
  viewRules(rule) {
    this.exstingRulesTitle = `Existing Rules for ${rule.pureCode}`;
    this.loading = true;
    this.closeLinkpopups();
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
    this.loadingMessage = this.exstingRulesTitle;
    }
    const rpcList = [];
    this.rpcLeafNodes.forEach(rpc => {
      if (rpc.market === rule.marketNameForDisplay && rpc.isChecked === 'checked') {
        rpcList.push({
          rpcId: rpc.id,
          rpcName: rpc.leaf,
          rpcFullText: rpc.fullText
        });
      }
    });
    const payload = {
      MarketList: [],
      RPCList: rpcList,
      SIRIds: [rule.substanceID],
      ruleClassifications: ['Substance Based Rules', 'Substance Group Rules'],
      RuleFlow: '2625',
      ActiveStatusList: ['Y']
    };
    this.reportData.marketRPCList.forEach((market, index) => {
      if (market.marketName === rule.marketNameForDisplay) {
        if (payload.MarketList.length === 0) {
          payload.MarketList.push({
            clusterId: null,
            clusterName: null,
            isCluster: null,
            marketFullText: null,
            marketId: market.marketId,
            marketLevel: 0,
            marketName: market.marketName,
            parentMarket: null
          });
        }
      }
    });
    const apiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    this.assessraService.postData(apiurl, payload).subscribe((response2) => {
      const versionDetails = [];
      if (response2 !== null) {
        response2.forEach((i) => {
          if (i.ruleTypeID !== 45) {
            i.ruleVersion = i.ruleVersion === undefined || null ? '' : `V${i.ruleVersion}`;
            i.cf = i.cfDetails === null ? '' : i.cfDetails.cf;
            i.warningText = (i.warningText == null || i.warningText === '') ? ((i.warningStatement !== null) ? i.warningStatement : '') : i.warningText;
            i.warningicon = this.findLinkWarning(((i.warningText == null || i.warningText === '') ? ((i.warningStatement !== null) ? i.warningStatement : '') : i.warningText));
            i.warningTextStripped = (i.warningText == null || i.warningText === '') ? '' : this.handleSpecialCharacters(i.warningText);
            versionDetails.push(i);
          }
        });
      }
      this.viewRulesData = versionDetails;
      if (versionDetails !== null) {
        versionDetails.forEach((result) => {
          const warningLinkRuleData = this.assessraService.hyperLinksToSheet(result.warningStatement);
          this.viewRulesExcelData.push({
            allSubstancesMandatory: result.allSubstancesMandatory,
            cas: result.cas,
            parentCAS: result.parentCAS,
            cascadeMarket: result.cascadeMarket,
            cascadeRpc: result.cascadeRpc,
            cfDetails: result.cfDetails,
            claimText: result.claimText,
            combinationRatio: result.combinationRatio,
            combinationRatioToDisplay: result.combinationRatioToDisplay,
            combinationSubstance: result.combinationSubstance,
            componentName: result.componentName,
            conflictingRegulatoryGroup: result.conflictingRegulatoryGroup,
            conflictingSubstance: result.conflictingSubstance,
            createdByUserDisplayName: result.createdByUserDisplayName,
            createdByUserId: result.createdByUserId,
            createdDate: result.createdDate,
            cutOffConcentration: result.cutOffConcentration,
            eNumber: result.eNumber,
            einecs: result.einecs,
            equalityCondition: result.equalityCondition,
            functionID: result.functionID,
            functionName: result.functionName,
            hStatement: result.hStatement,
            hazardCategory: result.hazardCategory,
            hazardClass: result.hazardClass,
            inciName: result.inciName,
            ins: result.ins,
            intendedUse: result.intendedUse,
            isActive: result.isActive,
            isAllergen: result.isAllergen,
            isDosageApplicable: result.isDosageApplicable,
            isExempted: result.isExempted,
            isFunctionMandatory: result.isFunctionMandatory,
            isMandatory: result.isMandatory,
            isProductStandard: result.isProductStandard,
            isRestricted: result.isRestricted,
            isServingSizeApplicable: result.isServingSizeApplicable,
            market: result.market,
            marketID: result.marketID,
            maxLimit: result.maxLimit,
            minLimit: result.minLimit,
            modifiedByUserDisplayName: result.modifiedByUserDisplayName,
            modifiedByUserId: result.modifiedByUserId,
            modifiedDate: result.modifiedDate,
            otherRestrictionsAndRequirements: result.otherRestrictionsAndRequirements,
            phasedOutDate: result.phasedOutDate,
            positiveStatement: result.positiveStatement,
            preparationMethod: result.preparationMethod,
            pureCode: result.pureCode,
            pureDescription: result.pureDescription,
            routeOfExposure: result.routeOfExposure,
            rpc: result.rpc,
            rpcid: result.rpcid,
            ruleClassification: result.ruleClassification,
            ruleDescription: result.ruleDescription,
            ruleId: result.ruleId,
            ruleSource: result.ruleSource,
            ruleSourceType: result.ruleSourceType,
            ruleStatus: result.ruleStatus,
            ruleType: result.ruleType,
            ruleTypeID: result.ruleTypeID,
            ruleVersion: result.ruleVersion,
            specificConditionText: result.specificConditionText,
            substanceID: result.substanceID,
            substanceName: result.substanceName,
            textualMaxLimit: result.textualMaxLimit,
            textualMinLimit: result.textualMinLimit,
            uoM: result.uoM,
            validityDate: result.validityDate,
            validityStartDate: result.validityStartDate,
            warningStatement: result.warningStatement,
            warningText: result.warningTextStripped,
            yearAdopted: result.yearAdopted,
            warningText1: warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '',
            warningText2: warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '',
            warningText3: warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '',
            warningText4: warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '',
            warningText5: warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '',
            warningText6: warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '',
            warningText7: warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : ''
          });
        });
      }
      this.loading = false;
      this.isViewRules = true;
    }, (error) => {
      this.loading = false;
    });
  }
  createRule(rule) {
    this.route.navigate(['create-insert/select-rpc']);
    const ruleData = {
      marketId: this.reportData.marketDetails.marketId,
      marketName: rule.marketNameForDisplay,
      rpcPath: rule.rpcListForDisplay,
      rpcId: rule.requestRpcId
    };
    localStorage.setItem('ruleData', JSON.stringify(ruleData));
  }
  tabSwitch(event) {
    if (event.index === 0) {
      this.isComposition = true;
      this.viewDetailedReportDiv = false;
      this.showViewAll = false;
    } else {
      this.isComposition = false;
      this.showPropertyProductFlag = false;
      this.propertyShowViewAll = false;
      this.productViewDetailedReportDiv = false;
      this.productViewReportDiv = false;
    }
  }
  openProduct() {
    this.productOpened = true;
  }
  closeProduct() {
    this.productOpened = false;
  }
  switchPropertyProduct() {
    this.showPropertyProductFlag = !this.showPropertyProductFlag;
  }
  openViewDetailedReportPopup(pureCode, multiComponentID) {
    this.closeLinkpopups();
    this.viewDetaildReportPopupFlag = true;
    this.viewDetailedReportPopupData = [];
    this.filteredReportDataRulesResults.forEach(pure => {
      if (pure.ruleTypeID !== 41 || pure.ruleTypeID !== 11 || pure.ruleTypeID !== 10 || pure.ruleTypeID !== 45) {
        if (pure.pureCode === pureCode && pure.multiComponentID === multiComponentID) {
          this.viewDetailedReportPopupData.push(pure);
        }
      }
    });
  }
  /* istanbul ignore next */
  openViewFragranceDetailedReportPopup(pureCode, multiComponentID) {
    this.viewDetailedReportPopupData = [];
    let multiComponentdata = multiComponentID === '' ? null : multiComponentID;
    this.filteredReportDataRulesResults.forEach(pure => {
      if (pure.ruleTypeID !== 41 || pure.ruleTypeID !== 11 || pure.ruleTypeID !== 10 || pure.ruleTypeID !== 45) {
        if (pure.pureCode === pureCode && pure.multiComponentID === multiComponentdata) {
          this.viewDetailedReportPopupData.push(pure);
        }
      }
    });
    this.viewFragranceDetaildReportPopupFlag = true;
    this.viewDetailedFragranceReportPopupData = [];
    multiComponentdata = multiComponentID === null ? '' : multiComponentID;
    this.filteredReportDataRulesResults.forEach(pure => {
      if (pure.ruleTypeID !== 41 || pure.ruleTypeID !== 11 || pure.ruleTypeID !== 10 || pure.ruleTypeID !== 45) {
        if (pure.pureCode === pureCode && pure.multiComponentID === multiComponentdata) {
          this.viewDetailedFragranceReportPopupData.push(pure);
        }
      }
    });
  }
  closeViewDetailedReportPopup(dtviewDetaildReportPopupFlag) {
    this.clearDetailFilters(dtviewDetaildReportPopupFlag);
    this.viewDetaildReportPopupFlag = false;
  }

  clearDetailFilters(dtviewDetaildReportPopupFlag) {
    dtviewDetaildReportPopupFlag.reset();
    this.viewDetaildReportPopupFlag = false;
    this.filteredRows = [];
    for (const val of this.detailsPopupColumns) {
      const colname = val.field + '_val';
      // tslint:disable-next-line: whitespace
      if (
        <HTMLInputElement> document.getElementById(colname) &&
        (<HTMLInputElement> document.getElementById(colname)).value
      ) {
        // tslint:disable-next-line: whitespace
        (<HTMLInputElement>document.getElementById(colname)).value = '';
      }
    }
  }
  closeViewFragranceDetailedReportPopup(dtfragranceReportpopup) {
    this.clearFragranceReportFilters(dtfragranceReportpopup);
    this.viewFragranceDetaildReportPopupFlag = false;
  }

  clearFragranceReportFilters(dtfragranceReportpopup) {
    this.viewFragranceDetaildReportPopupFlag = false;
    dtfragranceReportpopup.reset();
    this.filteredRows = [];
    for (const val of this.detailsFragrancePopupColumns) {
      const colname = val.field + '_val';
      // tslint:disable-next-line: whitespace
      if (
        <HTMLInputElement> document.getElementById(colname) &&
        (<HTMLInputElement> document.getElementById(colname)).value
      ) {
        // tslint:disable-next-line: whitespace
        (<HTMLInputElement>document.getElementById(colname)).value = '';
      }
    }
  }
  openFormulationPopup() {
    this.formulationDetailsPopup = true;
  }
  productViewDetailedReportFunction(switchContent) {
    if (switchContent === 'detailed') {
      this.productViewDetailedReportDiv = true;
      this.productViewReportDiv = true;
    } else {
      this.productViewDetailedReportDiv = false;
      this.productViewReportDiv = false;
    }
  }
  viewDetailedReport() {
    this.viewDetailedReportDiv = true;
    this.showViewAll = false;
  }
  viewPureTabs() {
    this.viewDetailedReportDiv = false;
  }
  /* istanbul ignore next */
  openDetails(rowData, tabHeader) {
    this.showDetailsPopup = true;
    this.detailsPopupData = [];
    if (rowData.ruleID === 0 || rowData.ruleID === '') {
      this.detailsPopupResultData.forEach(item => {
        if (item.pureCode === rowData.pureCode && item.multiComponentID === rowData.multiComponentID && item.ruleID === rowData.ruleID) {
          item.allowedFuncFlag = '';
          item.phaseOutDate = (item.phaseOutDate !== null) ? item.phaseOutDate : '';
          item.propertySource = '';
          item.propertyClass = '';
          item.propertyName = '';
          item.ruleSourceType = (item.ruleSourceType !== null) ? item.ruleSourceType : '';
          item.intendedUse = (item.intendedUse !== null) ? item.intendedUse : '';
          if (item.statusCode === '00') {
            item.convertedRecipeQty = '';
            item.finalUoM = '';
            item.recipeQuantity = '';
            item.recipeUoM = '';
          }
          item.commentForDisplay = (item.commentForDisplay === null || item.commentForDisplay === '') ? '' : item.commentForDisplay;
          if (item.commentForDisplay !== '') {
            item.commentForDisplayStripped = this.handleSpecialCharacters(item.commentForDisplay);
          } else {
            item.commentForDisplayStripped = '';
          }
          item.warningText = (item.warningText === null || item.warningText === '') ? '' : item.warningText;
          item.warningicon = this.findLinkWarning(item.warningText);
          if (item.warningText !== '') {
            item.warningTextStripped = this.handleSpecialCharacters(item.warningText);
          } else {
            item.warningTextStripped = '';
          }
          item.otherRestrictionsAndRequirements = (item.otherRestrictionsAndRequirements === null || item.otherRestrictionsAndRequirements === '') ? '' : item.otherRestrictionsAndRequirements;
          item.otherRestrictionsAndRequirementsicon = this.findLinkORR(item.otherRestrictionsAndRequirements);
          if (item.otherRestrictionsAndRequirements !== '') {
            item.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(item.otherRestrictionsAndRequirements);
          } else {
            item.otherRestrictionsAndRequirementsStripped = '';
          }
          this.detailsPopupData.push(item);
        }
      });
    } else {
      this.detailsPopupResultData.forEach(item => {
        if (item.ruleID === rowData.ruleID && item.multiComponentID === rowData.multiComponentID) {
          item.allowedFuncFlag = '';
          item.phaseOutDate = (item.phaseOutDate !== null) ? item.phaseOutDate : '';
          item.propertySource = '';
          item.propertyClass = '';
          item.propertyName = '';
          item.ruleSourceType = (item.ruleSourceType !== null) ? item.ruleSourceType : '';
          item.intendedUse = (item.intendedUse !== null) ? item.intendedUse : '';
          if (item.statusCode === '00') {
            item.convertedRecipeQty = '';
            item.finalUoM = '';
            item.recipeQuantity = '';
            item.recipeUoM = '';
          }
          item.commentForDisplay = (item.commentForDisplay === null || item.commentForDisplay === '') ? '' : item.commentForDisplay;
          if (item.commentForDisplay !== '') {
            item.commentForDisplayStripped = this.handleSpecialCharacters(item.commentForDisplay);
          } else {
            item.commentForDisplayStripped = '';
          }
          item.warningText = (item.warningText === null || item.warningText === '') ? '' : item.warningText;
          item.warningicon = this.findLinkWarning(item.warningText);
          if (item.warningText !== '') {
            item.warningTextStripped = this.handleSpecialCharacters(item.warningText);
          } else {
            item.warningTextStripped = '';
          }
          item.otherRestrictionsAndRequirements = (item.otherRestrictionsAndRequirements === null || item.otherRestrictionsAndRequirements === '') ? '' : item.otherRestrictionsAndRequirements;
          item.otherRestrictionsAndRequirementsicon = this.findLinkORR(item.otherRestrictionsAndRequirements);
          if (item.otherRestrictionsAndRequirements !== '') {
            item.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(item.otherRestrictionsAndRequirements);
          } else {
            item.otherRestrictionsAndRequirementsStripped = '';
          }
          this.detailsPopupData.push(item);
        }
      });
    }
  }
  /* istanbul ignore next */
  openFragranceDetails(rowData, tabHeader) {
    this.showFragranceDetailsPopup = true;
    this.detailsFragrancePopupData = [];
    if (rowData.ruleID === 0 || rowData.ruleID === '') {
      this.detailFragrancePopupResultData.forEach(item => {
        if (item.pureCode !== '' && item.multiComponentID === rowData.multiComponentID) {
          item.intendedUse = (item.intendedUse !== null) ? item.intendedUse : '';
          if (item.statusCode === '00') {
            item.convertedRecipeQty = '';
            item.finalUoM = '';
            item.recipeQuantity = '';
            item.recipeUoM = '';
          }
          item.commentForDisplay = (item.commentForDisplay === null || item.commentForDisplay === '') ? '' : item.commentForDisplay;
          if (item.commentForDisplay !== '') {
            item.commentForDisplayStripped = this.handleSpecialCharacters(item.commentForDisplay);
          } else {
            item.commentForDisplayStripped = '';
          }
          this.detailsFragrancePopupData.push(item);
        } else if (item.specId !== '') {
          item.intendedUse = (item.intendedUse !== null) ? item.intendedUse : '';
          if (item.statusCode === '00') {
            item.convertedRecipeQty = '';
            item.finalUoM = '';
            item.recipeQuantity = '';
            item.recipeUoM = '';
          }
          item.commentForDisplay = (item.commentForDisplay === null || item.commentForDisplay === '') ? '' : item.commentForDisplay;
          if (item.commentForDisplay !== '') {
            item.commentForDisplayStripped = this.handleSpecialCharacters(item.commentForDisplay);
          } else {
            item.commentForDisplayStripped = '';
          }
          this.detailsFragrancePopupData.push(item);
        }
      });
    }
  }

  closeDetails(dtshowDetailsPopup) {
    if (dtshowDetailsPopup) {
      this.clearFilters(dtshowDetailsPopup);
    }
    this.showDetailsPopup = false;
  }
  closeFragranceDetails(dtFragranceDetailsPopup) {
    this.clearFragranceFilters(dtFragranceDetailsPopup);
    this.showFragranceDetailsPopup = false;
  }
  closeRefinementPopup() {
    this.refinementDetailsPop = false;
  }
  viewAll() {
    this.showViewAll = true;
  }
  goBack() {
    this.showViewAll = false;
  }
  viewAllProperties() {
    this.propertyShowViewAll = true;
  }
  goBackProperties() {
    this.propertyShowViewAll = false;
  }
  changePure(status, pureCode, multiComponentID) {
    this.multiComponentTabData.forEach(component => {
      component.compositionTabContent.forEach(tab => {
        if (tab.status === status && component.multiComponentID === multiComponentID) {
          tab.selectedPure = pureCode;
        }
      });
    });
  }
  changeProperty(status, propertyId) {
    this.propertyTabContent.forEach(tab => {
      if (tab.status === status) {
        tab.selectedProperty = propertyId;
      }
    });
  }
  changeComponent(componentId) {


    this.selectedComponentId = componentId;
    this.multiComponentTabData.forEach(component => {
      component.compositionTabContent.forEach(tab => {
        this.isTabItemSet = false;
        if (tab.pures !== undefined && tab.pures.length > 0) {
          if (component.multiComponentID === componentId) {
            tab.pures.forEach((pure, index) => {
              if (pure.pureDetails !== undefined && pure.pureDetails.length > 0 && !this.isTabItemSet) {
                this.isTabItemSet = true;
                this.changePure(tab.status, pure.pureCode, componentId);
              }
            });
          }
        }
      });
    });
  }
  /* istanbul ignore next */
  formPureTabContent() {
    // property tabs
    this.propertyTabContent = [
      {
        status: 'Failed',
        tabHeader: 'Failed',
        color: 'lightRed',
        count: 0,
        tabTitle: 'Failed Substances:',
        tabTitleText: 'Product failed to meet property rules defined. Need appropriate actions.',
        tabDescription: 'Failed Substances Description',
        selectedProperty: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        properties: [],
        viewAllProperties: []
      },
      {
        status: 'Restrictions / Warning',
        tabHeader: 'Warnings',
        color: 'yellow',
        count: 0,
        tabTitle: 'Restrictions / Warning Substances:',
        tabTitleText: 'Product warnings/restrictions needing attention/action.',
        tabDescription: 'Restrictions / Warning Substances Description',
        selectedProperty: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        properties: [],
        viewAllProperties: []
      },
      {
        status: 'Incorrect/Missing PLM data',
        tabHeader: 'Input Missing / Data Mismatch',
        color: 'black',
        count: 0,
        tabTitle: 'Incorrect/Missing PLM data:',
        tabTitleText: 'Missing inputs/Data mismatch identified for product. Need appropriate actions for complete assessment.',
        tabDescription: 'Incorrect/Missing PLM data Description',
        selectedProperty: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        properties: [],
        viewAllProperties: []
      },
      {
        status: 'Rule does not exist',
        tabHeader: 'Rule does not exist',
        color: 'grey',
        count: 0,
        tabTitle: 'Rule does not exist:',
        tabTitleText: 'No product based rules found.',
        tabDescription: 'Rule does not exist Description',
        selectedProperty: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        properties: [],
        viewAllProperties: []
      },
      {
        status: 'Consult RA',
        tabHeader: 'Consult RA',
        color: 'brown',
        count: 0,
        tabTitle: 'Consult RA:',
        tabTitleText: 'Rule/s did not get executed due to data mismatch/data errors (e.g UoM or function mismatch).',
        tabDescription: 'Consult RA Description',
        selectedProperty: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        properties: [],
        viewAllProperties: []
      },
      {
        status: 'No Issues Identified',
        tabHeader: 'No issues identified',
        color: 'green',
        count: 0,
        tabTitle: 'No Issues Identified Substances:',
        tabTitleText: 'Product meets the rules defined, No issues identified.',
        tabDescription: 'No Issues Identified Substances Description',
        selectedProperty: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        properties: [],
        viewAllProperties: []
      }
    ];
    if (this.isRuleDoesntExistTabtoShow === false)  {
      this.propertyTabContent.splice(3, 1);
    }
    this.uniquePropertyStatus.forEach(property => {
      this.propertyTabContent.forEach(tab => {
        if (property.propertyStatus === tab.status) {
          tab.count++;
          tab.properties.push({
            propertyId: property.propertyId,
            propertyDetails: []
          });
        }
      });
    });
    this.propertyTabContent.forEach(tab => {
      const percentage = ((tab.count / this.totalProperties) * 100).toFixed(2);
      tab.percentage = { width: percentage + '%' };
      tab.tooltip = tab.status + '-' + percentage + '%';
      tab.class = { lightRed: tab.color === 'lightRed', yellow: tab.color === 'yellow', grey: tab.color === 'grey', black: tab.color === 'black', green: tab.color === 'green' };
      if (tab.properties.length !== 0) {
        if (tab.selectedProperty === 0) {
          tab.selectedProperty = tab.properties[0].propertyId;
        }
        tab.properties.forEach(property => {
          this.filteredReportDataProductPropertiesResults.forEach(propertyDetail => {
            propertyDetail.propertyMaxLimit = (propertyDetail.propertyMaxLimit == null) ? '' : propertyDetail.propertyMaxLimit;
            propertyDetail.propertyMinLimit = (propertyDetail.propertyMinLimit == null) ? '' : propertyDetail.propertyMinLimit;
            propertyDetail.rpcList = (propertyDetail.rpcList == null) ? '' : propertyDetail.rpcList;
            propertyDetail.ruleRPC = (propertyDetail.ruleRPC == null) ? '' : propertyDetail.ruleRPC;
            propertyDetail.propertyTextualMaxLimits = (propertyDetail.propertyTextualMaxLimits == null) ? '' : propertyDetail.propertyTextualMaxLimits;
            propertyDetail.propertyTextualMinLimits = (propertyDetail.propertyTextualMinLimits == null) ? '' : propertyDetail.propertyTextualMinLimits;
            propertyDetail.ruleTypeDisplayName = (propertyDetail.ruleTypeDisplayName == null) ? '' : propertyDetail.ruleTypeDisplayName;
            propertyDetail.ruleUoM = (propertyDetail.ruleUoM == null) ? '' : propertyDetail.ruleUoM;
            propertyDetail.warning = (propertyDetail.warning == null) ? '' : propertyDetail.warning;
            propertyDetail.ruleSource = (propertyDetail.ruleSource == null) ? '' : propertyDetail.ruleSource;
            propertyDetail.ruleCreatedByUserDisplayName = (propertyDetail.ruleCreatedByUserDisplayName == null) ? '' : propertyDetail.ruleCreatedByUserDisplayName;
            propertyDetail.ruleCreatedDate = (propertyDetail.ruleCreatedDate == null) ? '' : this.pipe.transform(propertyDetail.ruleCreatedDate, 'yyyy-MM-dd');
            propertyDetail.ruleModifiedByUserDisplayName = (propertyDetail.ruleModifiedByUserDisplayName == null) ? '' : propertyDetail.ruleModifiedByUserDisplayName;
            propertyDetail.ruleModifiedDate = (propertyDetail.ruleModifiedDate == null) ? '' : this.pipe.transform(propertyDetail.ruleModifiedDate, 'yyyy-MM-dd');
            propertyDetail.validityDate = (propertyDetail.validityDate == null) ? '' : this.pipe.transform(propertyDetail.validityDate, 'yyyy-MM-dd');
            propertyDetail.propertySource = '';
            propertyDetail.componentType = '';
            propertyDetail.preparationMethod = (propertyDetail.preparationMethod == null) ? '' : propertyDetail.preparationMethod;
            propertyDetail.intendedUse = (propertyDetail.intendedUse == null) ? '' : propertyDetail.intendedUse;
            // tslint:disable-next-line: triple-equals
            propertyDetail.warning = (propertyDetail.warning == null || propertyDetail.warning == '') ? ((propertyDetail.warningStatement == null || propertyDetail.warningStatement == '') ? '' : propertyDetail.warningStatement) : propertyDetail.warning;
            propertyDetail.warningicon = this.findLinkWarning(((propertyDetail.warning == null || propertyDetail.warning === '') ? ((propertyDetail.warningStatement == null || propertyDetail.warningStatement === '') ? '' : propertyDetail.warningStatement) : propertyDetail.warning));
            propertyDetail.warningStripped = this.handleSpecialCharacters(propertyDetail.warning);
            propertyDetail.otherRestrictionsAndRequirements = (propertyDetail.otherRestrictionsAndRequirements == null || propertyDetail.otherRestrictionsAndRequirements === '') ? '' : propertyDetail.otherRestrictionsAndRequirements;
            propertyDetail.otherRestrictionsAndRequirementsicon = this.findLinkORR(((propertyDetail.otherRestrictionsAndRequirements == null || propertyDetail.otherRestrictionsAndRequirements === '') ? '' : propertyDetail.otherRestrictionsAndRequirements));
            propertyDetail.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(propertyDetail.otherRestrictionsAndRequirements);
            propertyDetail.commentForDisplay = (propertyDetail.commentForDisplay == null || propertyDetail.commentForDisplay === '') ? '' : propertyDetail.commentForDisplay;
            propertyDetail.commentForDisplayStripped = this.handleSpecialCharacters(propertyDetail.commentForDisplay);
            if (propertyDetail.statusCode === '30') {
              propertyDetail.icon = 'failed'; // failed
              propertyDetail.tooltip = 'Failed';
            } else if (propertyDetail.statusCode === '20') {
              propertyDetail.icon = 'warning'; // warning
              propertyDetail.tooltip = 'Warnings';
            } else if (propertyDetail.statusCode === '41' || propertyDetail.statusCode === '00') {
              propertyDetail.icon = 'no-rule-found'; // Rule does not exist
              propertyDetail.tooltip = 'Rule does not exist';
            } else if (propertyDetail.statusCode === '45') {
              propertyDetail.icon = 'rule-execution-issues'; // Consult RA
              propertyDetail.tooltip = 'Consult RA';
            } else if (propertyDetail.statusCode === '40' || propertyDetail.statusCode === '42') {
              propertyDetail.icon = 'plm'; // Incorrect/Missing PLM data
              propertyDetail.tooltip = 'Input Missing / Data Mismatch';
            } else if (propertyDetail.statusCode === '10') {
              propertyDetail.icon = 'passed'; // Green
              propertyDetail.tooltip = 'No Issues Identified';
            }
            if (propertyDetail.icon === undefined) {
              propertyDetail.icon = null; // if status is null
            }
            if (propertyDetail.propertyId === property.propertyId) {
              property.propertyClass = propertyDetail.propertyClass;
              property.propertyName = propertyDetail.propertyName;
              property.propertyDetails.push(propertyDetail);
            }
          });
        });
      }
    });
    if (recipeModel.rpcRefinementData !== undefined) {
      recipeModel.rpcRefinementData.productOverallStatus = this.productReportBarTooltipText;
      recipeModel.rpcRefinementData.productOverallStatusColor = this.productOverallStatus;

      this.propertyTabContent.forEach(tab => {
        tab.properties.forEach(property => {
          property.propertyDetails.forEach(propertyDetail => {
            tab.viewAllProperties.push(propertyDetail);
          });
        });
      });
      recipeModel.rpcRefinementData.propertyTabContent = this.propertyTabContent;
    }
    //  product tabs
    this.productTabContent = [
      {
        status: 'Failed',
        tabHeader: 'Failed',
        color: 'lightRed',
        count: 0,
        tabTitle: 'Failed Substances:',
        tabTitleText: 'Product failed to meet product standards defined. Need appropriate actions.',
        tabDescription: 'Failed Substances Description',
        percentage: 0,
        tooltip: '',
        class: '',
        products: []
      },
      {
        status: 'Restrictions / Warning',
        tabHeader: 'Warnings',
        color: 'yellow',
        count: 0,
        tabTitle: 'Restrictions / Warning Substances:',
        tabTitleText: 'Product warnings/restrictions needing attention/action.',
        tabDescription: 'Restrictions / Warning Substances Description',
        percentage: 0,
        tooltip: '',
        class: '',
        products: []
      },
      {
        status: 'Incorrect/Missing PLM data',
        tabHeader: 'Input Missing / Data Mismatch',
        color: 'black',
        count: 0,
        tabTitle: 'Incorrect/Missing PLM data:',
        tabTitleText: 'Missing inputs/Data mismatch identified for product. Need appropriate actions for complete assessment.',
        tabDescription: 'Incorrect/Missing PLM data Description',
        percentage: 0,
        tooltip: '',
        class: '',
        products: []
      },
      {
        status: 'Rule does not exist',
        tabHeader: 'Rule does not exist',
        color: 'grey',
        count: 0,
        tabTitle: 'Rule does not exist:',
        tabTitleText: 'No product based rules found.',
        tabDescription: 'Rule does not exist Description',
        percentage: 0,
        tooltip: '',
        class: '',
        products: []
      },
      {
        status: 'Consult RA',
        tabHeader: 'Consult RA',
        color: 'brown',
        count: 0,
        tabTitle: 'Consult RA:',
        tabTitleText: 'Rule/s did not get executed due to data mismatch/data errors (e.g UoM or function mismatch).',
        tabDescription: 'Consult RA Description',
        percentage: 0,
        tooltip: '',
        class: '',
        products: []
      },
      {
        status: 'No Issues Identified',
        tabHeader: 'No issues identified',
        color: 'green',
        count: 0,
        tabTitle: 'No Issues Identified Substances:',
        tabTitleText: 'Product meets the rules defined, No issues identified.',
        tabDescription: 'No Issues Identified Substances Description',
        percentage: 0,
        tooltip: '',
        class: '',
        products: []
      }
    ];
    this.uniqueProductStatus = [];
    this.productStatus = [];
    const productRuleIds = [];
    this.productTabContent.forEach(tab => {
      this.filteredProductData.forEach(product => {
        product.commentForDisplay = (product.commentForDisplay == null || product.commentForDisplay === '') ? '' : product.commentForDisplay;
        product.commentForDisplayStripped = (product.commentForDisplay == null || product.commentForDisplay === '') ? '' : this.handleSpecialCharacters(product.commentForDisplay);
        product.warningText = (product.warningText == null || product.warningText === '') ? '' : product.warningText;
        product.warningicon = this.findLinkWarning(product.warningText);
        product.warningTextStripped = (product.warningText == null || product.warningText === '') ? '' : this.handleSpecialCharacters(product.warningText);
        product.otherRestrictionsAndRequirements = (product.otherRestrictionsAndRequirements == null || product.otherRestrictionsAndRequirements === '') ? '' : product.otherRestrictionsAndRequirements;
        product.otherRestrictionsAndRequirementsicon = this.findLinkORR(product.otherRestrictionsAndRequirements);
        product.otherRestrictionsAndRequirementsStripped = (product.otherRestrictionsAndRequirements == null || product.otherRestrictionsAndRequirements === '') ? '' : this.handleSpecialCharacters(product.otherRestrictionsAndRequirements);
        const isRuleExist = productRuleIds.filter(f => f === product.ruleID).length <= 0 ? 0 : product.ruleID;
        if (product.statusCode === '30' && tab.status === 'Failed') {
          product.icon = 'failed';
          product.tooltip = 'Failed';
          tab.products.push(product); // failed
          if (isRuleExist === 0) {
            tab.count++;
            productRuleIds.push(product.ruleID);
          }
        } else if (product.statusCode === '20' && tab.status === 'Restrictions / Warning') {
          product.icon = 'warning';
          product.tooltip = 'Warnings';
          tab.products.push(product); // warning
          if (isRuleExist === 0) {
            tab.count++;
            productRuleIds.push(product.ruleID);
          }
        } else if (this.isRuleDoesntExistTabtoShow === true && (product.statusCode === '41' || product.statusCode === '00') && tab.status === 'Rule does not exist') {
          product.icon = 'no-rule-found';
          product.tooltip = 'Rule does not exist';
          tab.products.push(product); // Rule does not exist
          if (isRuleExist === 0) {
            tab.count++;
            productRuleIds.push(product.ruleID);
          }
        } else if ((product.statusCode === '45') && tab.status === 'Consult RA') {
          product.icon = 'rule-execution-issues';
          product.tooltip = 'Consult RA';
          tab.products.push(product); // Consult RA
          if (isRuleExist === 0) {
            tab.count++;
            productRuleIds.push(product.ruleID);
          }
        } else if ((product.statusCode === '40' || product.statusCode === '42') && tab.status === 'Incorrect/Missing PLM data') {
          product.icon = 'plm';
          product.tooltip = 'Input Missing / Data Mismatch';
          tab.products.push(product); // Incorrect/Missing PLM data
          if (isRuleExist === 0) {
            tab.count++;
            productRuleIds.push(product.ruleID);
          }
        } else if (product.statusCode === '10' && tab.status === 'No Issues Identified') {
          product.icon = 'passed';
          product.tooltip = 'No Issues Identified';
          tab.products.push(product); // Green
          if (isRuleExist === 0) {
            tab.count++;
            productRuleIds.push(product.ruleID);
          }
        }
      });
    });
    if (this.isRuleDoesntExistTabtoShow === false)  {
      this.productTabContent.splice(3, 1);
    }
    this.productTabContent.forEach(tab => {
      if (tab.products.length > 0) {
        tab.products.forEach(product => {
          if (product.icon === undefined) {
            product.icon = null;
          }
        });
      }
    });
    this.totalProducts = 0;
    this.productTabContent.forEach(tab => {
      this.totalProducts = this.totalProducts + tab.count;
    });
    if (recipeModel.rpcRefinementData !== undefined) {
      recipeModel.rpcRefinementData.productTabContent = this.productTabContent;
    }
    this.productPropertyTabCount = [
      {
        status: 'Failed',
        tabHeader: 'Failed',
        tabMessage: 'Product part of recipe has failed',
        color: 'lightRed',
        count: 0
      },
      {
        status: 'Restrictions / Warning',
        tabHeader: 'Warnings',
        tabMessage: 'Product part of recipe has no issues but has warnings',
        color: 'yellow',
        count: 0
      },
      {
        status: 'Incorrect/Missing PLM data',
        tabHeader: 'Input Missing / Data Mismatch',
        tabMessage: 'Product part of recipe has missing input or data mismatch',
        color: 'black',
        count: 0
      },
      {
        status: 'Rule does not exist',
        tabHeader: 'Rule does not exist',
        tabMessage: 'Product part of recipe has no rules available',
        color: 'grey',
        count: 0
      },
      {
        status: 'Consult RA',
        tabHeader: 'Consult RA',
        tabMessage: 'Product part of recipe has rule execution issues',
        color: 'brown',
        count: 0
      },
      {
        status: 'No Issues Identified',
        tabHeader: 'No issues identified',
        tabMessage: 'Product part of recipe has no issues',
        color: 'green',
        count: 0
      }
    ];
    this.productPropertyTabCount.forEach(tab => {
      this.propertyTabContent.forEach(propertyTab => {
        if (tab.tabHeader === propertyTab.tabHeader) {
          tab.count += propertyTab.count;
        }
      });
      this.productTabContent.forEach(productTab => {
        if (tab.tabHeader === productTab.tabHeader) {
          tab.count += productTab.count;
        }
      });
    });
    this.isProductOverAllStatusSet = false;
    this.totalProductsProperties = this.totalProperties + this.totalProducts;
    this.productPropertyTabCount.forEach(tab => {
      if (tab.count > 0 && !this.isProductOverAllStatusSet) {
        if (tab.color === 'grey' && tab.count === this.totalProductsProperties) {
          this.isProductOverAllStatusSet = true;
          this.productReportBarTooltipText = tab.tabHeader;
          this.productReportBarMessage = tab.tabMessage;
          this.productOverallStatus = tab.color;
        }
        if (tab.color !== 'grey') {
          this.isProductOverAllStatusSet = true;
          this.productReportBarTooltipText = tab.tabHeader;
          this.productReportBarMessage = tab.tabMessage;
          this.productOverallStatus = tab.color;
        }
      }
    });
    this.productViewDetailedReport = [];
    this.filteredProductData.forEach(rule => {
      let iconStatus;
      let tooltip;
      if (rule.statusCode === '30') {
        iconStatus = 'failed';
        tooltip = 'Failed';
      } else if (rule.statusCode === '20') {
        iconStatus = 'warning';
        tooltip = 'Warnings';
      } else if (rule.statusCode === '41' || rule.statusCode === '00') {
        iconStatus = 'no-rule-found';
        tooltip = 'Rule does not exist';
      } else if (rule.statusCode === '45') {
        iconStatus = 'rule-execution-issues';
        tooltip = 'Consult RA';
      } else if (rule.statusCode === '40' || rule.statusCode === '42') {
        iconStatus = 'plm';
        tooltip = 'Incorrect/Missing PLM data';
      } else if (rule.statusCode === '10') {
        iconStatus = 'passed';
        tooltip = 'No Issues Identified';
      }
      if (iconStatus === undefined) {
        iconStatus = null;
      }
      if (tooltip === undefined) {
        tooltip = null;
      }
      const ruleWarningText = (rule.warningText === undefined || rule.warningText == null || rule.warningText === '') ? ((rule.warningStatement === undefined || rule.warningStatement == null || rule.warningStatement === '') ? '' : rule.warningStatement) : rule.warningText;
      this.productViewDetailedReport.push({
        icon: iconStatus,
        commentForDisplay: (rule.commentForDisplay == null || rule.commentForDisplay === '') ? '' : rule.commentForDisplay,
        commentForDisplayStripped: (rule.commentForDisplay == null || rule.commentForDisplay === '') ? '' : this.handleSpecialCharacters(rule.commentForDisplay),
        status: rule.ruleExecutionStatus,
        ruleID: rule.ruleID,
        ruleVersion: rule.ruleVersion === null ? rule.ruleVersion : 'V' + rule.ruleVersion,
        market: rule.marketNameForDisplay,
        rpcPath: rule.rpcListForDisplay,
        ruleRPC: rule.ruleRPC,
        ruleType: rule.ruleTypeDisplayName,
        propertySource: rule.propertySource,
        property: null,
        subProperty: null,
        pureCode: (rule.pureCode === undefined) ? '' : rule.pureCode,
        pureDescription: (rule.pureDescription === undefined) ? '' : rule.pureDescription,
        inci: (rule.inciNumber === undefined) ? '' : rule.inciNumber,
        function: (rule.ruleFunctionName === undefined) ? '' : rule.ruleFunctionName,
        minValue: (rule.minLimit === undefined) ? '' : rule.minLimit,
        equalityCondition: (rule.equalityCondition === undefined) ? '' : rule.equalityCondition,
        maxValue: rule.maxLimit,
        uom: (rule.ruleUoM === undefined) ? '' : rule.ruleUoM,
        claims: (rule.ruleClaimName === undefined) ? '' : rule.ruleClaimName,
        ruleSpecificConditionName: (rule.ruleSpecificConditionName === undefined) ? '' : rule.ruleSpecificConditionName,
        warningText: ruleWarningText,
        warningicon: this.findLinkWarning(ruleWarningText),
        warningTextStripped: ruleWarningText === '' ? '' : this.handleSpecialCharacters(ruleWarningText),
        otherRestrictionsAndRequirements: (rule.otherRestrictionsAndRequirements == null || rule.otherRestrictionsAndRequirements === '') ? '' : rule.otherRestrictionsAndRequirements,
        otherRestrictionsAndRequirementsicon: this.findLinkORR(((rule.otherRestrictionsAndRequirements == null || rule.otherRestrictionsAndRequirements === '') ? '' : rule.otherRestrictionsAndRequirements)),
        otherRestrictionsAndRequirementsStripped: (rule.otherRestrictionsAndRequirements == null || rule.otherRestrictionsAndRequirements === '') ? '' : this.handleSpecialCharacters(rule.otherRestrictionsAndRequirements),
        substanceID: (rule.substanceID === undefined) ? '' : rule.substanceID,
        casNumber: (rule.casNumber === undefined) ? '' : rule.casNumber,
        parentCASNumber: (rule.parentCASNumber === undefined) ? '' : rule.parentCASNumber,
        einecsNumber: (rule.einecsNumber === undefined) ? '' : rule.einecsNumber,
        eNumber: (rule.eNumber === undefined) ? '' : rule.eNumber,
        insNumber: (rule.insNumber === undefined) ? '' : rule.insNumber,
        componentName: (rule.componentName === undefined) ? '' : rule.componentName,
        preparationMethod: rule.preparationMethod,
        intendedUse: rule.intendedUse,
        ruleCreatedByUserDisplayName: rule.ruleCreatedByUserDisplayName,
        ruleCreatedDate: this.pipe.transform(rule.ruleCreatedDate, 'yyyy-MM-dd'),
        ruleModifiedByUserDisplayName: rule.ruleModifiedByUserDisplayName,
        ruleModifiedDate: this.pipe.transform(rule.ruleModifiedDate, 'yyyy-MM-dd'),
        source: rule.ruleSource,
        validityDate: this.pipe.transform(rule.validityDate, 'yyyy-MM-dd'),
        tooltip: tooltip,
        propertyTypicalQuantity: rule.propertyTypicalQuantity !== undefined ? rule.propertyTypicalQuantity : '',
        propertyMinQuantity: rule.propertyMinQuantity !== undefined ? rule.propertyMinQuantity : '',
        propertyMaxQuantity: rule.propertyMaxQuantity !== undefined ? rule.propertyMaxQuantity : '',
        propertyTargetQuantity: rule.propertyTargetQuantity !== undefined ? rule.propertyTargetQuantity : '',
        propertynQuantity: rule.propertynQuantity !== undefined ? rule.propertynQuantity : '',
        propertymQuantity: rule.propertymQuantity !== undefined ? rule.propertymQuantity : '',
        propertycQuantity: rule.propertycQuantity !== undefined ? rule.propertycQuantity : '',
        propertyMQuantity: rule.propertyMQuantity !== undefined ? rule.propertyMQuantity : '',
        propertyUoM: rule.propertyUoM !== undefined ? rule.propertyUoM : '',
        propertyMethodQuantity: rule.propertyMethodQuantity !== undefined ? rule.propertyMethodQuantity : '',
      });
    });
    this.filteredReportDataProductPropertiesResults.forEach(rule => {
      let icon;
      let tooltip;
      let isRecordtoInclude = false;
      if (rule.statusCode === '30') {
        icon = 'failed';
        tooltip = 'Failed';
        isRecordtoInclude = true;
      } else if (rule.statusCode === '20') {
        icon = 'warning';
        tooltip = 'Warnings';
        isRecordtoInclude = true;
      } else if (this.isRuleDoesntExistTabtoShow === true && (rule.statusCode === '41' || rule.statusCode === '00')) {
        icon = 'no-rule-found';
        tooltip = 'Rule does not exist';
        isRecordtoInclude = true;
      } else if (rule.statusCode === '45') {
        icon = 'rule-execution-issues';
        tooltip = 'Consult RA';
        isRecordtoInclude = true;
      } else if (rule.statusCode === '40' || rule.statusCode === '42') {
        icon = 'plm';
        tooltip = 'Incorrect/Missing PLM data';
        isRecordtoInclude = true;
      } else if (rule.statusCode === '10') {
        icon = 'passed';
        tooltip = 'No Issues Identified';
        isRecordtoInclude = true;
      }
      if (icon === undefined) {
        icon = null; // if status is null
      }
      if (tooltip === undefined) {
        tooltip = null; // if status is null
      }
      if (isRecordtoInclude === true) {
        const ruleRecordWarningText = (rule.warningText === undefined || rule.warningText == null || rule.warningText === '') ? ((rule.warningStatement === undefined || rule.warningStatement == null || rule.warningStatement === '') ? '' : rule.warningStatement) : rule.warningText;
        this.productViewDetailedReport.push({
          icon: icon,
          commentForDisplay: (rule.commentForDisplay == null || rule.commentForDisplay === '') ? '' : rule.commentForDisplay,
          commentForDisplayStripped: (rule.commentForDisplay == null || rule.commentForDisplay === '') ? '' : this.handleSpecialCharacters(rule.commentForDisplay),
          tooltip: tooltip,
          status: rule.ruleExecutionStatus,
          ruleID: rule.ruleID,
          ruleVersion: rule.ruleVersion === null ? rule.ruleVersion : 'V' + rule.ruleVersion,
          market: rule.marketNameForDisplay,
          rpcPath: rule.rpcList,
          ruleRPC: rule.ruleRPC,
          ruleType: rule.ruleTypeDisplayName,
          propertySource: rule.propertySource,
          property: rule.propertyClass,
          subProperty: rule.propertyName,
          pureCode: null,
          pureDescription: null,
          inci: null,
          function: null,
          minValue: rule.propertyMinLimit,
          equalityCondition: rule.equalityCondition,
          maxValue: rule.propertyMaxLimit,
          uom: (rule.ruleUoM === undefined) ? '' : rule.ruleUoM,
          claims: rule.ruleClaimName,
          ruleSpecificConditionName: rule.ruleSpecificConditionName,
          warningText: ruleRecordWarningText,
          warningicon: this.findLinkWarning(ruleRecordWarningText),
          warningTextStripped: ruleRecordWarningText === '' ? '' : this.handleSpecialCharacters(ruleRecordWarningText),
          otherRestrictionsAndRequirements: (rule.otherRestrictionsAndRequirements == null || rule.otherRestrictionsAndRequirements === '') ? '' : rule.otherRestrictionsAndRequirements,
          otherRestrictionsAndRequirementsicon: this.findLinkORR(((rule.otherRestrictionsAndRequirements == null || rule.otherRestrictionsAndRequirements === '') ? '' : rule.otherRestrictionsAndRequirements)),
          otherRestrictionsAndRequirementsStripped: (rule.otherRestrictionsAndRequirements == null || rule.otherRestrictionsAndRequirements === '') ? '' : this.handleSpecialCharacters(rule.otherRestrictionsAndRequirements),
          substanceID: null,
          casNumber: null,
          parentCASNumber: null,
          einecsNumber: null,
          eNumber: null,
          insNumber: null,
          componentName: rule.componentName,
          preparationMethod: null,
          intendedUse: null,
          ruleCreatedByUserDisplayName: rule.ruleCreatedByUserDisplayName,
          ruleCreatedDate: this.pipe.transform(rule.ruleCreatedDate, 'yyyy-MM-dd'),
          ruleModifiedByUserDisplayName: rule.ruleModifiedByUserDisplayName,
          ruleModifiedDate: this.pipe.transform(rule.ruleModifiedDate, 'yyyy-MM-dd'),
          source: rule.ruleSource,
          validityDate: this.pipe.transform(rule.validityDate, 'yyyy-MM-dd'),
          propertyTypicalQuantity: rule.propertyTypicalQuantity !== undefined ? rule.propertyTypicalQuantity : '',
          propertyMinQuantity: rule.propertyMinQuantity !== undefined ? rule.propertyMinQuantity : '',
          propertyMaxQuantity: rule.propertyMaxQuantity !== undefined ? rule.propertyMaxQuantity : '',
          propertyTargetQuantity: rule.propertyTargetQuantity !== undefined ? rule.propertyTargetQuantity : '',
          propertynQuantity: rule.propertynQuantity !== undefined ? rule.propertynQuantity : '',
          propertymQuantity: rule.propertymQuantity !== undefined ? rule.propertymQuantity : '',
          propertycQuantity: rule.propertycQuantity !== undefined ? rule.propertycQuantity : '',
          propertyMQuantity: rule.propertyMQuantity !== undefined ? rule.propertyMQuantity : '',
          propertyUoM: rule.propertyUoM !== undefined ? rule.propertyUoM : '',
          propertyMethodQuantity: rule.propertyMethodQuantity !== undefined ? rule.propertyMethodQuantity : '',
        });
      }
    });
    this.propertyProductStatus = [
      {
        count: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        color: 'lightRed',
        status: 'Failed',
      },
      {
        count: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        color: 'yellow',
        status: 'Restrictions / Warning',
      },
      {
        count: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        color: 'grey',
        status: 'Rule does not exist',
      },
      {
        count: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        color: 'black',
        status: 'Incorrect/Missing PLM data',
      },
      {
        count: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        color: 'green',
        status: 'No Issues Identified',
      },
    ];
    this.propertyProductStatus.forEach(statusBarTab => {
      this.propertyTabContent.forEach(propertyTab => {
        if (statusBarTab.status === propertyTab.status) {
          statusBarTab.count += propertyTab.count;
        }
      });
    });
    this.propertyProductStatus.forEach(statusBarTab => {
      this.productTabContent.forEach(productTab => {
        if (statusBarTab.status === productTab.status) {
          statusBarTab.count += productTab.count;
          const percentage = ((statusBarTab.count / this.totalProductsProperties) * 100).toFixed(2);
          statusBarTab.percentage = { width: percentage + '%' };
          statusBarTab.tooltip = statusBarTab.status + '-' + percentage + '%';
          statusBarTab.class = { lightRed: statusBarTab.color === 'lightRed', yellow: statusBarTab.color === 'yellow', grey: statusBarTab.color === 'grey', black: statusBarTab.color === 'black', green: statusBarTab.color === 'green' };
        }
      });
    });
    const productTabCount = this.productPropertyTabCount.map(tab => tab.count);
    this.prodTab = productTabCount;
    this.productChartData = {
      labels: ['Failed', 'Warnings', 'Input Missing / Data Mismatch', 'Rule does not exist', 'Consult RA', 'No Issues Identified', 'Non – Restricted'],
      datasets: [
        {
          data: productTabCount,
          backgroundColor: [
            '#DC3545',
            '#f3ad42',
            '#333333',
            '#9b979a',
            '#D17E29',
            '#24aa49',
            '#1a8eea'
          ],
          hoverBackgroundColor: [
            '#DC3545',
            '#f3ad42',
            '#333333',
            '#9b979a',
            '#D17E29',
            '#24aa49',
            '#1a8eea'
          ]
        }
      ]
    };
    this.productChartOptions = {
      title: {
        display: false
      },
      legend: {
        display: false,
      },
      tooltips: {
        titleFontSize: 8,
        bodyFontSize: 8,
        backgroundColor: '#ffffff',
        callbacks: {
          labelTextColor: (tooltipItem, chart) => {
            return '#000000';
          },
          opacity: (tooltipItem, chart) => {
            return 1;
          },
          afterBody: (tooltipItem, data) => {
            const multistringText = [];
            const labelToSplitText = [data.labels[tooltipItem[0].index]].toString();
            if (labelToSplitText.indexOf('/') > 0) {
              multistringText.push(labelToSplitText.split(' / ')[1] + ' ' + [data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index]]);
            } else {
              multistringText.push([data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index]]);
            }

            return multistringText;
          },
          label: (tooltipItem, data) => {
            const multistringText = [];
            const labelToSplitText = [data.labels[tooltipItem.index]].toString();
            if (labelToSplitText.indexOf('/') > 0) {
              multistringText.push(labelToSplitText.split(' / ')[0]);
            } else {
              multistringText.push([data.labels[tooltipItem.index]]);
            }
            return multistringText;
          }
        }
      },
      responsive: false,
      maintainAspectRatio: false
    };
    this.multiComponentTabData.forEach(component => {
      component.compositionTabContent = [
        {
          status: 'Banned',
          tabHeader: 'Banned',
          color: 'red',
          count: 0,
          tabTitle: 'Banned Substances:',
          tabTitleText: 'Substances (s) are completely banned from use in formulation.',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        },
        {
          status: 'Failed',
          tabHeader: 'Failed',
          color: 'lightRed',
          count: 0,
          tabTitle: 'Failed Substances:',
          tabTitleText: 'Substance (s) failed to meet the rules defined. Need appropriate actions.',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        },
        {
          status: 'Restrictions / Warning',
          tabHeader: 'Warnings',
          color: 'yellow',
          count: 0,
          tabTitle: 'Restrictions / Warning Substances:',
          tabTitleText: 'Substance (s) warnings/restrictions needing attention/action.',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        },
        {
          status: 'Incorrect/Missing PLM data',
          tabHeader: 'Input Missing / Data Mismatch',
          color: 'black',
          count: 0,
          tabTitle: 'Incorrect/Missing PLM data:',
          tabTitleText: 'Missing inputs/Data mismatch identified for substance(s). Need appropriate actions for complete assessment.',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        },
        {
          status: 'Rule does not exist',
          tabHeader: 'Rule does not exist',
          color: 'grey',
          count: 0,
          tabTitle: 'Rule does not exist:',
          tabTitleText: 'Substance rules are yet to be created/in progress.',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        },
        {
          status: 'Consult RA',
          tabHeader: 'Consult RA',
          color: 'brown',
          count: 0,
          tabTitle: 'Consult RA:',
          tabTitleText: 'Rule/s did not get executed due to data mismatch/data errors (e.g UoM or function mismatch).',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        },
        {
          status: 'No Issues Identified',
          tabHeader: 'No Issues Identified',
          color: 'green',
          count: 0,
          tabTitle: 'No Issues Identified Substances:',
          tabTitleText: 'Substance(s) meet the rules defined, no issues identified.',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        },
        {
          status: 'Non - Restricted Substances',
          tabHeader: 'Non – Restricted',
          color: 'blue',
          count: 0,
          tabTitle: 'Non - Restricted Substances Substances:',
          tabTitleText: 'Substance (s) are not regulated for use in the formulation.',
          tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
          selectedPure: 0,
          percentage: 0,
          tooltip: '',
          class: '',
          pures: [],
          groupPures: [],
          viewAllPures: []
        }
      ];
      this.uniquePureStatus.forEach(pure => {
        component.compositionTabContent.forEach(tab => {
          if (pure.pureStatus ===  tab.status && pure.multiComponentName === component.multiComponentName) {
            tab.pures.push({
              pureCode: pure.pureCode,
              pureDetails: [],
              pureStatus: tab.tabHeader,
              multiComponentName: component.multiComponentName
            });
          }
        });
      });
      this.filteredReportDataRulesResults.forEach(pureDetail => {
        pureDetail.ruleFunctionName = (pureDetail.ruleFunctionName === null || pureDetail.ruleFunctionName === '') ? 'Any' : pureDetail.ruleFunctionName;
        pureDetail.maxLimit = (pureDetail.maxLimit == null) ? '' : pureDetail.maxLimit;
        pureDetail.minLimit = (pureDetail.minLimit == null) ? '' : pureDetail.minLimit;
        pureDetail.recipeQuantity = (pureDetail.recipeQuantity == null) ? '' : pureDetail.recipeQuantity;
         // pureDetail.recipeQuantity = this.reportData.isMultiComponent === 'Y' ? ((pureDetail.originalRecipeQuantity == null) ? '' : pureDetail.originalRecipeQuantity) :  (pureDetail.recipeQuantity == null) ? '' : pureDetail.recipeQuantity;
        pureDetail.rpcListForDisplay = (pureDetail.rpcListForDisplay == null) ? '' : pureDetail.rpcListForDisplay;
        pureDetail.ruleTypeDisplayName = (pureDetail.ruleTypeDisplayName == null) ? '' : pureDetail.ruleTypeDisplayName;
        // tslint:disable-next-line: triple-equals
        pureDetail.warningText = (pureDetail.warningText === undefined || pureDetail.warningText == null || pureDetail.warningText == '') ? ((pureDetail.warningStatement !== null) ? pureDetail.warningStatement : '') : pureDetail.warningText;
        // pureDetail.warningicon = (pureDetail.warningText == null || pureDetail.warningText === '') ? false : this.findLinkWarning(pureDetail.warningText);
        pureDetail.warningicon = this.findLinkWarning(((pureDetail.warningText === undefined || pureDetail.warningText === undefined || pureDetail.warningText == null || pureDetail.warningText === '') ? ((pureDetail.warningStatement == null || pureDetail.warningStatement === '') ? '' : pureDetail.warningStatement) : pureDetail.warningText));
        pureDetail.warningTextStripped = (pureDetail.warningText === undefined || pureDetail.warningText == null || pureDetail.warningText === '') ? ' ' : this.handleSpecialCharacters(pureDetail.warningText);
        // added by srini for test
        pureDetail.otherRestrictionsAndRequirements = (pureDetail.otherRestrictionsAndRequirements !== null) ? pureDetail.otherRestrictionsAndRequirements : '';
        pureDetail.otherRestrictionsAndRequirementsicon = (pureDetail.otherRestrictionsAndRequirements !== null) ? this.findLinkORR(pureDetail.otherRestrictionsAndRequirements)  : false;
        pureDetail.otherRestrictionsAndRequirementsStripped = (pureDetail.otherRestrictionsAndRequirements == null || pureDetail.otherRestrictionsAndRequirements === '') ? '' : this.handleSpecialCharacters(pureDetail.otherRestrictionsAndRequirements);
        pureDetail.commentForDisplayStripped = (pureDetail.commentForDisplay == null || pureDetail.commentForDisplay === '') ? ' ' : this.handleSpecialCharacters(pureDetail.commentForDisplay);
        if (pureDetail.ruleSource !== null && pureDetail.ruleSource !== '') {
          pureDetail.isruleSourceLink = false;
          if (pureDetail.ruleSource.startsWith('http')) {
            pureDetail.isruleSourceLink = true;
          }
        }
        if (pureDetail.ruleTypeDisplayName === 'Banned Substance' || pureDetail.ruleTypeDisplayName === 'Banned list - Group') {
          pureDetail.icon = 'banned'; // banned
          pureDetail.tooltip = 'Banned';
        } else if (pureDetail.statusCode === '30') {
          pureDetail.icon = 'failed'; // failed
          pureDetail.tooltip = 'Failed';
        } else if (pureDetail.statusCode === '20') {
          pureDetail.icon = 'warning'; // warning
          pureDetail.tooltip = 'Warnings';
        } else if (pureDetail.statusCode === '41' || pureDetail.statusCode === '00') {
          pureDetail.icon = 'no-rule-found'; // Rule does not exist
          pureDetail.tooltip = 'Rule does not exist';
        } else if (pureDetail.statusCode === '45') {
          pureDetail.icon = 'rule-execution-issues'; // Consult RA
          pureDetail.tooltip = 'Consult RA';
        } else if (pureDetail.statusCode === '40' || pureDetail.statusCode === '42') {
          pureDetail.icon = 'plm'; // Incorrect/Missing PLM data
          pureDetail.tooltip = 'Input Missing / Data Mismatch';
        } else if (pureDetail.statusCode === '10') {
          if (pureDetail.ruleTypeID === 17 || pureDetail.ruleTypeID === 25) {
            pureDetail.icon = 'non-restricted'; // non restricted
            pureDetail.tooltip = 'Non - Restricted';
          } else {
            pureDetail.icon = 'passed'; // passed
            pureDetail.tooltip = 'No Issues Identified';
          }
        }
        if (pureDetail.icon === undefined) {
          pureDetail.icon = null; // if status is null
        }
        if (pureDetail.tooltip === undefined) {
          pureDetail.tooltip = null; // if status is null
        }
        component.compositionTabContent.forEach(tab => {
          if (tab.pures.length !== 0) {
            tab.pures.forEach(pure => {
              if (component.multiComponentID !== null) {
                if (pureDetail.pureCode === pure.pureCode && pureDetail.multiComponentID === component.multiComponentID) {
                  pure.pureDescription = pureDetail.pureDescription;
                  pure.pureDetails.push(pureDetail);
                }
              } else {
                if (pureDetail.pureCode === pure.pureCode) {
                  pure.pureDescription = pureDetail.pureDescription;
                  pure.pureDetails.push(pureDetail);
                }
              }
            });
          }
        });

        // pureDetail.warningTextStripped
      });
      component.compositionTabContent.forEach(tab => {
        tab.pures.forEach(pure => {
          if (pure.pureDetails.length >= 1) {
            tab.count++;
          }
          pure.pureDetails.forEach(pureDetail => {
            tab.viewAllPures.push(pureDetail);
          });
        });
        if (tab.pures.length !== 0) {
          if (tab.selectedPure === 0) {
            tab.selectedPure = tab.pures[0].pureCode;
          }
        }
      });
      component.propertyTabContent = this.propertyTabContent;
      component.productTabContent = this.productTabContent;
      component.propertyProductViewDetailedReport = this.productViewDetailedReport;
    });
    this.selectedComponentId = this.multiComponentTabData[0].multiComponentID;
    this.pureTabContent = [
      {
        status: 'Banned',
        tabHeader: 'Banned',
        tabMessage: 'Composition part of recipe has Banned substance(s)',
        color: 'red',
        count: 0,
        tabTitle: 'Banned Substances',
        tabDescription: 'Banned Substances Description',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      },
      {
        status: 'Failed',
        tabHeader: 'Failed',
        tabMessage: 'Composition part of recipe has failed',
        color: 'lightRed',
        count: 0,
        tabTitle: 'Failed Substances',
        tabDescription: 'Failed Substances Description',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      },
      {
        status: 'Restrictions / Warning',
        tabHeader: 'Warnings',
        tabMessage: 'Composition part of recipe has no issues but has warnings',
        color: 'yellow',
        count: 0,
        tabTitle: 'Restrictions / Warning Substances',
        tabDescription: 'Restrictions / Warning Substances Description',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      },
      {
        status: 'Incorrect/Missing PLM data',
        tabHeader: 'Input Missing / Data Mismatch',
        tabMessage: 'Composition part of recipe has input missing or data mismatch',
        color: 'black',
        count: 0,
        tabTitle: 'Incorrect/Missing PLM data',
        tabDescription: 'Incorrect/Missing PLM data Description',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      },
      {
        status: 'Rule does not exist',
        tabHeader: 'Rule does not exist',
        tabMessage: 'Composition part of recipe has no rules available',
        color: 'grey',
        count: 0,
        tabTitle: 'Rule does not exist',
        tabDescription: 'Rule does not exist Description',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      },
      {
        status: 'Consult RA',
        tabHeader: 'Consult RA',
        tabMessage: 'Composition part of recipe has rule execution issues',
        color: 'brown',
        count: 0,
        tabTitle: 'Consult RA:',
        tabTitleText: 'Substance rules which has execution issues or not executed',
        tabDescription: 'Disclaimer: PURE quantity displayed in the output report is the computed quantity based on respective Contribution Factor/Dosage/Serving size as applicable.',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      },
      {
        status: 'No Issues Identified',
        tabHeader: 'No Issues Identified',
        tabMessage: 'Composition part of recipe has no issues',
        color: 'green',
        count: 0,
        tabTitle: 'No Issues Identified Substances',
        tabDescription: 'No Issues Identified Substances Description',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      },
      {
        status: 'Non - Restricted Substances',
        tabHeader: 'Non – Restricted',
        tabMessage: 'Composition part of recipe has Non-Restricted substance(s)',
        color: 'blue',
        count: 0,
        tabTitle: 'Non - Restricted Substances Substances',
        tabDescription: 'Non - Restricted Substances Description',
        selectedPure: 0,
        percentage: 0,
        tooltip: '',
        class: '',
        pures: [],
        groupPures: [],
        viewAllPures: []
      }
    ];
    this.uniquePureStatus.forEach(pure => {
      this.pureTabContent.forEach(tab => {
        if (pure.pureStatus === tab.status) {
          tab.count++;
          tab.pures.push({
            pureCode: pure.pureCode,
            pureStatus: pure.pureStatus,
            pureDetails: []
          });
        }
      });
    });
    this.filteredReportDataRulesResults.forEach(pureDetail => {
      pureDetail.ruleFunctionName = (pureDetail.ruleFunctionName == null) ? '' : pureDetail.ruleFunctionName;
      pureDetail.maxLimit = (pureDetail.maxLimit == null) ? '' : pureDetail.maxLimit;
      pureDetail.minLimit = (pureDetail.minLimit == null) ? '' : pureDetail.minLimit;
      pureDetail.recipeQuantity = (pureDetail.recipeQuantity == null) ? '' : pureDetail.recipeQuantity;
      pureDetail.rpcListForDisplay = (pureDetail.rpcListForDisplay == null) ? '' : pureDetail.rpcListForDisplay;
      pureDetail.ruleTypeDisplayName = (pureDetail.ruleTypeDisplayName == null) ? '' : pureDetail.ruleTypeDisplayName;
      pureDetail.warningText = (pureDetail.warningText === undefined || pureDetail.warningText == null || pureDetail.warningText === '') ? ((pureDetail.warningStatement !== null) ? pureDetail.warningStatement : '') : pureDetail.warningText;
      pureDetail.warningicon = this.findLinkWarning(pureDetail.warningText === undefined || pureDetail.warningText == null || pureDetail.warningText === ''  ? (pureDetail.warningStatement !== null ? pureDetail.warningStatement : '') : pureDetail.warningText);
      pureDetail.warningTextStripped = pureDetail.warningText === undefined || pureDetail.warningText == null || pureDetail.warningText === '' ? (pureDetail.warningStatement !== null ? this.handleSpecialCharacters(pureDetail.warningStatement) : '') : this.handleSpecialCharacters(pureDetail.warningText);
      pureDetail.otherRestrictionsAndRequirements = (pureDetail.otherRestrictionsAndRequirements !== null) ? pureDetail.otherRestrictionsAndRequirements : '';
      pureDetail.otherRestrictionsAndRequirementsicon = (pureDetail.otherRestrictionsAndRequirements !== null) ? this.findLinkORR(pureDetail.otherRestrictionsAndRequirements)  : false;
      pureDetail.otherRestrictionsAndRequirementsStripped = (pureDetail.otherRestrictionsAndRequirements == null || pureDetail.otherRestrictionsAndRequirements === '') ? '' : this.handleSpecialCharacters(pureDetail.otherRestrictionsAndRequirements);
      pureDetail.commentForDisplayStripped = (pureDetail.commentForDisplay == null || pureDetail.commentForDisplay === '') ? ' ' : this.handleSpecialCharacters(pureDetail.commentForDisplay);
      if (pureDetail.ruleTypeDisplayName === 'Banned Substance' || pureDetail.ruleTypeDisplayName === 'Banned list - Group') {
        pureDetail.icon = 'banned'; // banned
      } else if (pureDetail.statusCode === '30') {
        pureDetail.icon = 'failed'; // failed
      } else if (pureDetail.statusCode === '20') {
        pureDetail.icon = 'warning'; // warning
      } else if (pureDetail.statusCode === '41' || pureDetail.statusCode === '00') {
        pureDetail.icon = 'no-rule-found'; // Rule does not exist
      } else if (pureDetail.statusCode === '45') {
        pureDetail.icon = 'rule-execution-issues'; // Rule does not exist
      } else if (pureDetail.statusCode === '40' || pureDetail.statusCode === '42') {
        pureDetail.icon = 'plm'; // Incorrect/Missing PLM data
      } else if (pureDetail.statusCode === '10') {
        if (pureDetail.ruleTypeID === 17 || pureDetail.ruleTypeID === 25) {
          pureDetail.icon = 'non-restricted'; // non restricted
        } else {
          pureDetail.icon = 'passed'; // passed
        }
      }
      if (pureDetail.icon === undefined) {
        pureDetail.icon = null; // if status is null
      }
      this.pureTabContent.forEach(tab => {
        if (tab.pures.length !== 0) {
          tab.pures.forEach(pure => {
            if (pureDetail.pureCode === pure.pureCode) {
              pure.pureDescription = pureDetail.pureDescription;
              pure.pureDetails.push(pureDetail);
            }
          });
        }
      });
    });
    this.isCompositionOverAllStatusSet = false;
    this.pureTabContent.forEach(tab => {
      const percentage = ((tab.count / this.totalCompositionPures) * 100).toFixed(2);
      tab.percentage = { width: percentage + '%' };
      tab.tooltip = tab.status + '-' + percentage + '%';
      tab.class = { red: tab.color === 'red', lightRed: tab.color === 'lightRed', yellow: tab.color === 'yellow', grey: tab.color === 'grey', black: tab.color === 'black', green: tab.color === 'green', blue: tab.color === 'blue' };
      if (tab.pures.length !== 0) {
        if (tab.selectedPure === 0) {
          tab.selectedPure = tab.pures[0].pureCode;
        }
      }
      if (tab.count > 0 && !this.isCompositionOverAllStatusSet) {
        if (tab.color === 'grey' && tab.count === this.totalCompositionPures) {
          this.isCompositionOverAllStatusSet = true;
          this.compositionReportBarTooltipText = tab.tabHeader;
          this.compositionOverallStatus = tab.color;
          this.compositionReportBarMessage = tab.tabMessage;
        }
        if (tab.color !== 'grey') {
          this.isCompositionOverAllStatusSet = true;
          this.compositionReportBarTooltipText = tab.tabHeader;
          this.compositionOverallStatus = tab.color;
          this.compositionReportBarMessage = tab.tabMessage;
        }
      }
    });
    if (recipeModel.rpcRefinementData !== undefined) {
      recipeModel.rpcRefinementData.compositionOverallStatus = this.compositionReportBarTooltipText;
      recipeModel.rpcRefinementData.compositionOverallStatusColor = this.compositionOverallStatus;
    }
    this.pureTabContent.forEach(tab => {
      tab.pures.forEach(pure => {
        pure.pureDetails.forEach(pureDetail => {
          tab.viewAllPures.push(pureDetail);
        });
      });
    });
    const compostionTabCount = this.pureTabContent.map(tab => tab.count);
    this.compostionTab = compostionTabCount;
    this.compositionChartData = {
      labels: ['Banned', 'Failed', 'Warnings', 'Input Missing / Data Mismatch', 'Rule does not exist', 'Consult RA', 'No Issues Identified', 'Non – Restricted'],
      datasets: [
        {
          data: compostionTabCount,
          backgroundColor: [
            '#8C0707',
            '#DC3545',
            '#f3ad42',
            '#333333',
            '#9b979a',
            '#D17E29',
            '#24aa49',
            '#1a8eea'
          ],
          hoverBackgroundColor: [
            '#8C0707',
            '#DC3545',
            '#f3ad42',
            '#333333',
            '#9b979a',
            '#D17E29',
            '#24aa49',
            '#1a8eea'
          ]
        }
      ],
      tooltipFontSize: 10,
      tooltipTemplate: '<%if (label){%><%=label%>: <%}%><%= value %>hrs',
      percentageInnerCutout: 70,
    };
    this.compositionChartOptions = {
      title: {
        display: false
      },
      legend: {
        display: false,
        backgroundColor: '#ffffff',
        labels: {
          fontColor: '#000000',
          fontSize: '10px',
          usePointStyle: true,
        },
      },
      tooltips: {
        titleFontSize: 8,
        bodyFontSize: 8,
        backgroundColor: '#ffffff',
        callbacks: {
          labelTextColor: (tooltipItem, chart) => {
            return '#000000';
          },
          opacity: (tooltipItem, chart) => {
            return 1;
          },
          afterBody: (tooltipItem, data) => {
            const multistringText = [];
            const labelToSplitText = [data.labels[tooltipItem[0].index]].toString();
            if (labelToSplitText.indexOf('/') > 0) {
              multistringText.push(labelToSplitText.split(' / ')[1] + ' ' + [data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index]]);
            } else {
              multistringText.push([data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index]]);
            }

            return multistringText;
          },
          label: (tooltipItem, data) => {
            const multistringText = [];
            const labelToSplitText = [data.labels[tooltipItem.index]].toString();
            if (labelToSplitText.indexOf('/') > 0) {
              multistringText.push(labelToSplitText.split(' / ')[0]);
            } else {
              multistringText.push([data.labels[tooltipItem.index]]);
            }
            return multistringText;
          }
        }
      },
      responsive: false,
      maintainAspectRatio: false
    };
    if (this.reportData.isMultiComponent === 'Y') {
      this.multiComponentTabData.forEach(component => {
        component.compositionTabContent.forEach(tab => {
          tab.pures.forEach(pure => {
            pure.pureDetails = pure.pureDetails.filter(item => item.statusCode !== '41' && item.statusCode !== '00' && component.multiComponentID === item.multiComponentID).concat(pure.pureDetails.filter(item => (item.statusCode === '41' || item.statusCode === '00') && component.multiComponentID === item.multiComponentID));
          });
        });
      });
    } else {
      this.multiComponentTabData.forEach(component => {
        component.compositionTabContent.forEach(tab => {
          tab.pures.forEach(pure => {
            pure.pureDetails = pure.pureDetails.filter(item => item.statusCode !== '41' && item.statusCode !== '00').concat(pure.pureDetails.filter(item => item.statusCode === '41' || item.statusCode === '00'));
          });
        });
      });
    }
    this.multiComponentTabData[0].propertyTabContent.forEach(tab => {
      tab.properties.forEach(property => {
        property.propertyDetails = property.propertyDetails.filter(item => item.statusCode !== '41' && item.statusCode !== '00').concat(property.propertyDetails.filter(item => item.statusCode === '41' || item.statusCode === '00'));
      });
    });
    this.multiComponentTabData[0].productTabContent.forEach(tab => {
      tab.products = tab.products.filter(item => item.statusCode !== '41' && item.statusCode !== '00').concat(tab.products.filter(item => item.statusCode === '41' || item.statusCode === '00'));
    });

    if (recipeModel.rpcRefinementData) {
      recipeModel.rpcRefinementData.exportData = this.multiComponentTabData;
    }
  }
  /* istanbul ignore next */
  findPureStatus() {
    this.uniquePureStatus = [];
    if (this.reportData.isMultiComponent === 'Y') {
      this.multiComponentUniquePures.forEach(uniquePure => {
        this.pureStatus = [];
        this.filteredReportDataRulesResults.forEach(pure => {
          if (pure.pureCode === uniquePure.pureCode && pure.multiComponentName === uniquePure.multiComponentName) {
            if (pure.ruleTypeID === 11 || pure.ruleTypeID === 41 || pure.ruleTypeID === 10 || pure.ruleTypeID === 45) {
              this.pureStatus.push(5);
            } else {
              if (pure.ruleTypeDisplayName === 'Banned Substance' || pure.ruleTypeDisplayName === 'Banned list - Group') {
                this.pureStatus.push(1); // banned
              } else if (pure.statusCode === '30') {
                this.pureStatus.push(2); // failed
              } else if (pure.statusCode === '20') {
                this.pureStatus.push(3); // warning
              } else if (pure.statusCode === '41' || pure.statusCode === '00') {
                this.pureStatus.push(5); // Rule does not exist
              } else if (pure.statusCode === '45') {
                this.pureStatus.push(6); // Consult RA
              } else if (pure.statusCode === '40' || pure.statusCode === '42') {
                this.pureStatus.push(4); // Incorrect/Missing PLM data
              } else if (pure.statusCode === '10') {
                if (pure.ruleTypeID === 17 || pure.ruleTypeID === 25) {
                  this.pureStatus.push(8); // non restricted
                } else {
                  this.pureStatus.push(7); // passed
                }
              }
            }
          }
        });
        this.pureStatus.sort();
        let status;
        if (this.pureStatus[0] === 1) {
          status = 'Banned';
        } else if (this.pureStatus[0] === 2) {
          status = 'Failed';
        } else if (this.pureStatus[0] === 3) {
          status = 'Restrictions / Warning';
        } else if (this.pureStatus[0] === 5) {
          status = 'Rule does not exist';
        } else if (this.pureStatus[0] === 6) {
          status = 'Consult RA';
        } else if (this.pureStatus[0] === 7) {
          status = 'No Issues Identified';
        } else if (this.pureStatus[0] === 8) {
          status = 'Non - Restricted Substances';
        } else if (this.pureStatus[0] === 4) {
          status = 'Incorrect/Missing PLM data';
        }
        if (this.pureStatus.indexOf(5) >= 0 && this.pureStatus.indexOf(6) >= 0 && this.pureStatus.indexOf(1) === -1 && this.pureStatus.indexOf(2) === -1 && this.pureStatus.indexOf(3) === -1 && this.pureStatus.indexOf(4) === -1 && this.pureStatus.indexOf(8) === -1) {
          status = 'Consult RA';
        }
        if (this.pureStatus.indexOf(5) >= 0 && this.pureStatus.indexOf(7) >= 0 && this.pureStatus.indexOf(1) === -1 && this.pureStatus.indexOf(2) === -1 && this.pureStatus.indexOf(3) === -1 && this.pureStatus.indexOf(4) === -1 && this.pureStatus.indexOf(8) === -1) {
          status = 'No Issues Identified';
        }
        if (this.pureStatus.indexOf(5) >= 0 && this.pureStatus.indexOf(8) >= 0 && this.pureStatus.indexOf(1) === -1 && this.pureStatus.indexOf(2) === -1 && this.pureStatus.indexOf(3) === -1 && this.pureStatus.indexOf(4) === -1 && this.pureStatus.indexOf(7) === -1) {
          status = 'Non - Restricted Substances';
        }
        if (this.uniquePureStatus.length === 0) {
          this.uniquePureStatus.push({
            pureCode: uniquePure.pureCode,
            pureStatus: status,
            multiComponentName: uniquePure.multiComponentName
          });
        } else if (this.uniquePureStatus.filter(u => u.pureCode === uniquePure.pureCode && u.pureStatus.toLowerCase() === status.toLowerCase() && u.multiComponentName.toLowerCase() === uniquePure.multiComponentName.toLowerCase()).length === 0) {
          this.uniquePureStatus.push({
            pureCode: uniquePure.pureCode,
            pureStatus: status,
            multiComponentName: uniquePure.multiComponentName
          });
        }
      });
    } else {
      this.uniquePures.forEach(uniquePure => {
        this.pureStatus = [];
        this.filteredReportDataRulesResults.forEach(pure => {
          if (pure.pureCode === uniquePure) {
            if (pure.ruleTypeID === 11 || pure.ruleTypeID === 41 || pure.ruleTypeID === 10 || pure.ruleTypeID === 45) {
              this.pureStatus.push(5);
            } else {
              if (pure.ruleTypeDisplayName === 'Banned Substance' || pure.ruleTypeDisplayName === 'Banned list - Group') {
                this.pureStatus.push(1); // banned
              } else if (pure.statusCode === '30') {
                this.pureStatus.push(2); // failed
              } else if (pure.statusCode === '20') {
                this.pureStatus.push(3); // warning
              } else if (pure.statusCode === '41' || pure.statusCode === '00') {
                this.pureStatus.push(5); // Rule does not exist
              } else if (pure.statusCode === '45') {
                this.pureStatus.push(6); // Consult RA
              } else if (pure.statusCode === '40' || pure.statusCode === '42') {
                this.pureStatus.push(4); // Incorrect/Missing PLM data
              } else if (pure.statusCode === '10') {
                if (pure.ruleTypeID === 17 || pure.ruleTypeID === 25) {
                  this.pureStatus.push(8); // non restricted
                } else {
                  this.pureStatus.push(7); // passed
                }
              }
            }
          }
        });
        this.pureStatus.sort();
        let status;
        if (this.pureStatus[0] === 1) {
          status = 'Banned';
        } else if (this.pureStatus[0] === 2) {
          status = 'Failed';
        } else if (this.pureStatus[0] === 3) {
          status = 'Restrictions / Warning';
        } else if (this.pureStatus[0] === 5) {
          status = 'Rule does not exist';
        } else if (this.pureStatus[0] === 6) {
          status = 'Consult RA';
        } else if (this.pureStatus[0] === 7) {
          status = 'No Issues Identified';
        } else if (this.pureStatus[0] === 8) {
          status = 'Non - Restricted Substances';
        } else if (this.pureStatus[0] === 4) {
          status = 'Incorrect/Missing PLM data';
        }
        if (this.pureStatus.indexOf(5) >= 0 && this.pureStatus.indexOf(6) >= 0 && this.pureStatus.indexOf(1) === -1 && this.pureStatus.indexOf(2) === -1 && this.pureStatus.indexOf(3) === -1 && this.pureStatus.indexOf(4) === -1 && this.pureStatus.indexOf(8) === -1) {
          status = 'Consult RA';
        }
        if (this.pureStatus.indexOf(5) >= 0 && this.pureStatus.indexOf(7) >= 0 && this.pureStatus.indexOf(1) === -1 && this.pureStatus.indexOf(2) === -1 && this.pureStatus.indexOf(3) === -1 && this.pureStatus.indexOf(4) === -1 && this.pureStatus.indexOf(8) === -1) {
          status = 'No Issues Identified';
        }
        if (this.pureStatus.indexOf(5) >= 0 && this.pureStatus.indexOf(8) >= 0 && this.pureStatus.indexOf(1) === -1 && this.pureStatus.indexOf(2) === -1 && this.pureStatus.indexOf(3) === -1 && this.pureStatus.indexOf(4) === -1 && this.pureStatus.indexOf(7) === -1) {
          status = 'Non - Restricted Substances';
        }
        this.uniquePureStatus.push({
          pureCode: uniquePure,
          pureStatus: status,
          multiComponentName: null
        });
      });
    }
    this.uniquePropertyStatus = [];
    this.uniqueProperties.forEach(uniqueProperty => {
      this.propertyStatus = [];
      this.filteredReportDataProductPropertiesResults.forEach(property => {
        if (property.propertyId === uniqueProperty) {
          if (property.statusCode === '30') {
            this.propertyStatus.push(1); // failed
          } else if (property.statusCode === '20') {
            this.propertyStatus.push(2); // warning
          } else if (property.statusCode === '41' || property.statusCode === '00') {
            this.propertyStatus.push(3); // Rule does not exist
          } else if (property.statusCode === '45') {
            this.propertyStatus.push(6); // Consult RA
          } else if (property.statusCode === '40' || property.statusCode === '42') {
            this.propertyStatus.push(4); // Incorrect/Missing PLM data
          } else if (property.statusCode === '10') {
            this.propertyStatus.push(5); // Green
          }
        }
      });
      this.propertyStatus.sort();
      let status;
      if (this.propertyStatus[0] === 1) {
        status = 'Failed';
      } else if (this.propertyStatus[0] === 2) {
        status = 'Restrictions / Warning';
      } else if (this.propertyStatus[0] === 3) {
        status = 'Rule does not exist';
      } else if (this.propertyStatus[0] === 6) {
        status = 'Consult RA';
      } else if (this.propertyStatus[0] === 4) {
        status = 'Incorrect/Missing PLM data';
      } else if (this.propertyStatus[0] === 5) {
        status = 'No Issues Identified';
      }
      if (this.propertyStatus.indexOf(3) >= 0 && this.propertyStatus.indexOf(5) >= 0 && this.propertyStatus.indexOf(1) === -1 && this.propertyStatus.indexOf(2) === -1 && this.propertyStatus.indexOf(4) === -1) {
        status = 'No Issues Identified';
      }
      this.uniquePropertyStatus.push({
        propertyId: uniqueProperty,
        propertyStatus: status
      });
    });
  }
  /* istanbul ignore next */
  getUniquePures() {
    const purecodes = this.filteredReportDataRulesResults.map(pure => {
      return pure.pureCode;
    });
    this.uniquePures = [];
    this.uniquePures = purecodes.filter((pure, index, self) => self.indexOf(pure) === index).filter(pure => pure !== null);
    if (this.initialUniquePures.length === 0 && (this.rpcLeafNodes.filter(f => f.isChecked === 'checked').length ===  this.reportData.marketRPCList.filter(f => f.rpcId !== null).length)) {
      this.initialUniquePures = purecodes.filter((pure, index, self) => self.indexOf(pure) === index).filter(pure => pure !== null);
      this.initialtotalCompositionPures = this.initialUniquePures.length;
    }
    this.multiComponentUniquePures = [];
    this.filteredReportDataRulesResults.forEach(f => {
      if (this.uniquePures.filter(u => u === f.pureCode)) {
        if (this.multiComponentUniquePures.length === 0) {
          this.multiComponentUniquePures.push({
            pureCode: f.pureCode,
            multiComponentName: f.multiComponentName
          });
        } else if (this.multiComponentUniquePures.filter(m => m.pureCode !== f.pureCode && m.multiComponentName !== f.multiComponentName)) {
          this.multiComponentUniquePures.push({
            pureCode: f.pureCode,
            multiComponentName: f.multiComponentName
          });
        }
      }
    });
    this.totalCompositionPures = this.uniquePures.length;
    let propertyIds = [];
    if (this.isRuleDoesntExistTabtoShow === true) {
      propertyIds = this.filteredReportDataProductPropertiesResults.map(property => {
        return property.propertyId;
      });
    } else {
      propertyIds = this.filteredReportDataProductPropertiesResults.filter(f => f.statusCode !== '41' && f.statusCode !== '0').map(property => {
        return property.propertyId;
      });
    }
    this.uniqueProperties = [];
    this.uniqueProperties = propertyIds.filter((pure, index, self) => self.indexOf(pure) === index).filter(pure => pure !== null);
    if (this.initialuniqueProperties.length === 0 && (this.rpcLeafNodes.filter(f => f.isChecked === 'checked').length === this.reportData.marketRPCList.length)) {
      this.initialuniqueProperties = propertyIds.filter((pure, index, self) => self.indexOf(pure) === index).filter(pure => pure !== null);
      this.initialtotalProperties = this.initialuniqueProperties.length;
    }
    this.totalProperties = this.uniqueProperties.length;
  }
  formMultiComponent() {
    this.multiComponentTabData = [];
    if (this.reportData.isMultiComponent === 'Y') {
      this.componentIds = [];
      this.reportData.multiComponent.forEach(component => {
        this.componentIds.push(component.multiComponentID);
      });
      const uniqueComponentIds = this.componentIds.filter((componentId, index, self) => self.indexOf(componentId) === index);
      if (uniqueComponentIds !== null) {
        uniqueComponentIds.forEach(componentId => {
          let isPushed = false;
          this.reportData.multiComponent.forEach(component => {
            if (componentId === component.multiComponentID && !isPushed) {
              isPushed = true;
              this.multiComponentTabData.push({
                multiComponentID: component.multiComponentID,
                multiComponentName: component.multiComponentName
              });
            }
          });
        });
      }
    } else {
      this.multiComponentTabData.push({
        multiComponentID: null,
        multiComponentName: null
      });
    }
  }
  pureListCollapse() {
    this.pureListToggle = !this.pureListToggle;
    this.displaySize = this.pureListToggle === true ? '0' : '217';
  }
  /* istanbul ignore if */
  getMarketRPCs(marketId, tfcId) {
    this.loading = true;
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
      this.loadingMessage = 'Loading Market RPCs';
    }
    const rpcListAPI = environment.APIFAsT + '/marketid/' + marketId + '/tfcid/' + tfcId + '/rpclist';
    this.reportSubmitService.getRPCList(rpcListAPI).subscribe((data) => {
      this.pageMarketRPC = data;
      const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(this.reportData.tfc.tfcFullText)));
      if (data !== null) {
        data.forEach((item, index) => {
          const forTreeAPI = environment.APIFAsT + '/marketid/' + marketId + '/tfcid/' + tfcId + '/rpcs/fortree/rpc/' + item.rpcId;
          this.reportSubmitService.getRPC(forTreeAPI).subscribe((result) => {
            if (data.length === (index - 1)) {
              this.loading = false;
            }
            if (result !== null) {
              result.forEach(item2 => item2.market = this.reportData.marketDetails.marketName);
              result.forEach(item3 => {
                let isLeaf = true;
                result.forEach(item4 => {
                  if (item3.id === item4.parent) {
                    isLeaf = false;
                  }
                });
                if (isLeaf) {
                  item3.leaf = true;
                } else {
                  item3.leaf = false;
                }
              });
            }
            this.marketrpcdata = result;
            /* istanbul ignore if */
            if (this.marketrpcdata[0].text === 'No Regulatory Product Class is Mapped') {
              this.marketRpcPlugins = ['changed', 'contextmenu'];
            } /* istanbul ignore else */ else if (result !== null) {
              result.forEach(item5 => {
                if (item5.leaf) {
                  this.rpcLeafNodes.push({
                    market: item5.market,
                    leaf: item5.text,
                    id: item5.id,
                    isChecked: 'checked',
                    fullText: item5.fullText,
                    parentId: item5.parent
                  });
                }
              });
              this.marketRpcPlugins = ['changed', 'contextmenu', 'checkbox'];
            }
            this.createmarketRpcTree(this.marketrpcdata, index);
          }, (error) => {
            if (error.status !== 200) {
              this.loading = false;
              this.matSnackBar.open(error.message, 'close', {
                duration: 3000,
                panelClass: ['error']
              });
            }
          });
        });
      }
    });
  }
  getstaticMarketRPCs(marketId, tfcId) {
    this.loading = true;
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
      this.loadingMessage = 'Loading Market RPCs';
    }
    let rpcListAPI = '';
    const requestStatus = this.actualRequestStatus;
    if (requestStatus === 'Completed') {
      rpcListAPI = environment.APIFAsT + '/marketid/' + marketId + '/requestmarketid/' + this.reportData.requestID + '/rpcliststatic';
    } else {
      rpcListAPI = environment.APIFAsT + '/marketid/' + marketId + '/tfcid/' + tfcId + '/rpclist';
    }
    this.reportSubmitService.getRPCList(rpcListAPI).subscribe((data) => {
      this.pageMarketRPC = data;
      const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(this.reportData.tfc.tfcFullText)));
      if (data !== null) {
        if (data.length === 0) {
          this.loading = false;
          data.push({
            childRpcs: null,
            isHazard: false,
            mrpcId: 0,
            parentRpcDescription: null,
            parentRpcId: 0,
            parentRpcSearchValue: null,
            rpcDescription: 'No Regulatory Product Class is Mapped',
            rpcId: '0',
            rpcLevel: 0,
            rpcSearchValue: 'No Regulatory Product Class is Mapped',
            tmrmId: 0
          });
        }
        data.forEach((item, index) => {
          const forTreeAPI = environment.APIFAsT + '/marketid/' + marketId + '/requestmarketid/' + this.reportData.requestID + '/rpcs/fortree/rpc/' + item.rpcId;
          this.reportSubmitService.getRPC(forTreeAPI).subscribe((result) => {
            if (data.length === (index - 1)) {
              this.loading = false;
              this.getUnileverRPCs();
            }
            if (data.length === 1) {
              this.loading = false;
              this.getUnileverRPCs();
            }
            if (result !== null) {
              result.forEach(item2 => item2.market = this.reportData.marketDetails.marketName);
              result.forEach(item3 => {
                let isLeaf = true;
                result.forEach(item4 => {
                  if (item3.id === item4.parent) {
                    isLeaf = false;
                  }
                });
                if (isLeaf) {
                  item3.leaf = true;
                } else {
                  item3.leaf = false;
                }
              });
            }
            this.marketrpcdata = result;
            /* istanbul ignore if */
            if (this.marketrpcdata[0].text === 'No Regulatory Product Class is Mapped') {
              this.addDefaultRPCForNonRegulatoryProductClass(this.reportData.marketDetails.marketName);
              this.marketRpcPlugins = ['changed', 'contextmenu'];
            } /* istanbul ignore else */ else if (result !== null) {
              result.forEach(item5 => {
                if (item5.leaf) {
                  this.rpcLeafNodes.push({
                    market: item5.market,
                    leaf: item5.text,
                    id: item5.id,
                    isChecked: 'checked',
                    fullText: item5.fullText,
                    parentId: item5.parent
                  });
                }
              });
              this.marketRpcPlugins = ['changed', 'contextmenu', 'checkbox'];
            }
            this.createmarketRpcTree(this.marketrpcdata, index);
          }, (error) => {
            if (error.status !== 200) {
              this.loading = false;
              this.matSnackBar.open(error.message, 'close', {
                duration: 3000,
                panelClass: ['error']
              });
            }
          });
        });
      } else {
        this.getUnileverRPCs();
      }
    });
  }
  /* istanbul ignore if */
  getUnileverRPCs() {
    this.loading = true;
    let rpcListAPI = '';
    const requestStatus = this.actualRequestStatus;
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
      this.loadingMessage = 'Loading Unilever RPCs';
    }
    if (this.reportData.marketRPCList.find(item => item.marketName === 'Unilever') === undefined) {
      this.reportData.marketRPCList.push({
        ischecked: null,
        isCluster: false,
        level: 0,
        marketId: '283',
        marketName: 'Unilever',
        rpcFullText: 'No Regulatory Product Class is Mapped',
        rpcName: 'No Regulatory Product Class is Mapped',
        rpcId: '0',
      });
    }
    const unileverMarket = this.reportData.marketRPCList.find(item => item.marketName === 'Unilever');
    if (unileverMarket !== undefined) {
      if (requestStatus === 'Completed') {
        rpcListAPI = environment.APIFAsT + '/marketid/' + unileverMarket.marketId + '/requestmarketid/' + this.reportData.requestID + '/rpcliststatic';
      } else {
        rpcListAPI = environment.APIFAsT + '/marketid/' + unileverMarket.marketId + '/tfcid/' + this.reportData.tfc.tfcId + '/rpclist';
      }
      this.reportSubmitService.getRPCList(rpcListAPI).subscribe((data) => {
        if (data.length === 0) {
          const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(this.reportData.tfc.tfcFullText)));
          // const forTreeAPI = environment.APIFAsT + '/markets/' + unileverMarket.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/0';
          const forTreeAPI = environment.APIFAsT + '/marketid/' + unileverMarket.marketId + '/requestmarketid/' + this.reportData.requestID + '/rpcs/fortree/rpc/0';
          this.reportSubmitService.getRPC(forTreeAPI).subscribe((result) => {
            this.loading = false;
            this.getRpcTree();
            if (result !== null) {
              result.forEach(item2 => item2.market = unileverMarket.marketName);
              result.forEach(item3 => {
                let isLeaf = true;
                result.forEach(item4 => {
                  if (item3.id === item4.parent) {
                    isLeaf = false;
                  }
                });
                if (isLeaf) {
                  item3.leaf = true;
                } else {
                  item3.leaf = false;
                }
              });
            }
            this.unileverrpcdata = result;
            if (this.unileverrpcdata[0].text === 'No Regulatory Product Class is Mapped') {
              this.addDefaultRPCForNonRegulatoryProductClass(unileverMarket.marketName);
              this.unileverRpcPlugins = ['changed', 'contextmenu'];
            } else if (result !== null) {
              result.forEach(item5 => {
                if (item5.leaf) {
                  this.rpcLeafNodes.push({
                    market: item5.market,
                    leaf: item5.text,
                    id: item5.id,
                    isChecked: 'checked',
                    fullText: item5.fullText,
                    parentId: item5.parent
                  });
                }
              });
              this.unileverRpcPlugins = ['changed', 'contextmenu', 'checkbox'];
            }
            setTimeout(() => {
              this.createUnileverRpcTree(this.unileverrpcdata, 0);
            }, 1000);
          }, (error) => {
            if (error.status !== 200) {
              this.loading = false;
              this.matSnackBar.open(error.message, 'close', {
                duration: 3000,
                panelClass: ['error']
              });
            }
          });
        } else {
          this.pageUnileverRPC = data;
          const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(this.reportData.tfc.tfcFullText)));
          if (data !== null) {
            data.forEach((item, index) => {
              const forTreeAPI = environment.APIFAsT + '/marketid/' + unileverMarket.marketId + '/requestmarketid/' + this.reportData.requestID + '/rpcs/fortree/rpc/' + item.rpcId;
              // const forTreeAPI = environment.APIFAsT + '/marketid/' + unileverMarket.marketId + '/tfcid/' + this.reportData.tfc.tfcId + '/rpcs/fortree/rpc/' + item.rpcId;
              this.reportSubmitService.getRPC(forTreeAPI).subscribe((result) => {
                if (data.length === (index - 1)) {
                  this.loading = false;
                  this.getRpcTree();
                }
                if (data.length === 1) {
                  this.loading = false;
                  this.getRpcTree();
                }
                if (result != null) {
                  result.forEach(item2 => item2.market = unileverMarket.marketName);
                  result.forEach(item3 => {
                    let isLeaf = true;
                    result.forEach(item4 => {
                      if (item3.id === item4.parent) {
                        isLeaf = false;
                      }
                    });
                    if (isLeaf) {
                      item3.leaf = true;
                    } else {
                      item3.leaf = false;
                    }
                  });
                }
                this.unileverrpcdata = result;
                if (this.unileverrpcdata[0].text === 'No Regulatory Product Class is Mapped') {
                  this.unileverRpcPlugins = ['changed', 'contextmenu'];
                } else if (result !== null) {
                  result.forEach(item5 => {
                    if (item5.leaf) {
                      this.rpcLeafNodes.push({
                        market: item5.market,
                        leaf: item5.text,
                        id: item5.id,
                        isChecked: 'checked',
                        fullText: item5.fullText,
                        parentId: item5.parent
                      });
                    }
                  });
                  this.unileverRpcPlugins = ['changed', 'contextmenu', 'checkbox'];
                }
                this.createUnileverRpcTree(this.unileverrpcdata, index);
              }, (error) => {
                if (error.status !== 200) {
                  this.loading = false;
                  this.matSnackBar.open(error.message, 'close', {
                    duration: 3000,
                    panelClass: ['error']
                  });
                }
              });
            });
          }
        }
      });
    }

  }

  getTFCStatus(tfcId) {
    this.loading = true;
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
      this.loadingMessage = 'Loading Market RPCs';
    }
    let tfcstatusAPI = '';
    const requestStatus = this.actualRequestStatus;
    tfcstatusAPI = environment.APIFAsT + '/tfcidforstatus/' + tfcId;
    localStorage.setItem('tfcStatus', '1');
    this.tfcStatus = 1;
    this.reportSubmitService.getTFCStatus(tfcstatusAPI).subscribe((data) => {
      if (data !== null) {
        data.forEach(item => {
          if (item.tfcStatus === 0) {
            localStorage.setItem('tfcStatus', '0');
            this.tfcStatus = 0;
            this.tfcPathMessage = this.reportData.tfc.tfcFullText + '\n The PLM TFC has been remapped/retired. Please create a new request with updated PLM TFC.';
          }
        });
      }
    }, (error) => {
      if (error.status !== 200) {
        this.loading = false;
        this.matSnackBar.open(error.message, 'close', {
          duration: 3000,
          panelClass: ['error']
        });
      }
    });
  }
  /* istanbul ignore next */
  createmarketRpcTree(rpcdata, index) {
    let to = 0;
    $('#search-tree').keyup(() => {
      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(() => {
        let v: any;
        v = $('#search-tree').val();
        $('#pageMarketTreeDiv' + index).jstree(true).search(String(v));
      }, 250) ? 1 : 0;
    });
    $('#pageMarketTreeDiv' + index).on('changed.jstree', (evt, data) => {
      this.removeAddLeafNodes(data);
    });
    // tslint:disable-next-line: no-shadowed-variable
    $('#pageMarketTreeDiv' + index).on('changed.jstree', (e, rpcdata) => {
    }).jstree({
      plugins: this.marketRpcPlugins,
      contextmenu: {
        select_node: false,
        items: this.contextMenu
      },
      checkbox: {
        three_state: true
      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: no-shadowed-variable  // tslint:disable-next-line: space-before-function-paren
    }).bind('loaded.jstree', function (event, rpcdata) {
      $(this).jstree('open_all');
      $(this).jstree('check_all');
    }).bind('before.jstree', (event, data) => {
      switch (data.plugin) {
        case 'ui':
          if (data.inst.is_leaf(data.args[0])) {
            return false;
          }
          break;
        default:
          break;
      }
    });
  }
  /* istanbul ignore next */
  createUnileverRpcTree(rpcdata, index) {
    let to = 0;
    $('#search-tree').keyup(() => {
      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(() => {
        let v: any;
        v = $('#search-tree').val();
        $('#pageUnileverTreeDiv' + index).jstree(true).search(String(v));
      }, 250) ? 1 : 0;
    });
    $('#pageUnileverTreeDiv' + index).on('changed.jstree', (evt, data) => {
      let isRemoveaddCall = true;
      if (this.isRpcViewAll === true && data.action === 'deselect_node') {
        this.isRpcViewAll = false;
        if (data.node != null) {
          this.rpcLeafNodes.forEach((item) => {
            if (item.fullText === data.node.original.fullText && item.isChecked === 'unchecked') {
              isRemoveaddCall = false;
            }
          });
        }
      }
      if (isRemoveaddCall) {
        this.removeAddLeafNodes(data);
      }
    });
    // tslint:disable-next-line: no-shadowed-variable
    $('#pageUnileverTreeDiv' + index).on('changed.jstree', (e, rpcdata) => {
    }).jstree({
      plugins: this.unileverRpcPlugins,
      contextmenu: {
        select_node: false,
        items: this.contextMenu
      },
      checkbox: {
        three_state: false
      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: no-shadowed-variable  // tslint:disable-next-line: space-before-function-paren
    }).bind('loaded.jstree', function (event, rpcdata) {
      $(this).jstree('open_all');
      $(this).jstree('check_all');
    });
  }
  /* istanbul ignore next */
  contextMenu(node) {
    const items = { selezionaTutti: null };
    if (node.children.length > 0) {
      items.selezionaTutti = {
        action(questo) {
          // return null;
        }
      };
    }
    return items;
  }
  /* istanbul ignore next */
  removeAddLeafNodes(data) {
    const action = data.action;
    if (data.node !== undefined) {
      const node = data.node.text;
      const market = data.node.original.market;
      if (action === 'deselect_node') {
        this.rpcLeafNodes.forEach((item, index) => {
          if (item.leaf === node && item.market === market && item.parentId === data.node.parent) {
            item.isChecked = 'unchecked';
          }
        });
      } else {
        this.rpcLeafNodes.forEach((item, index) => {
          if (item.leaf === node && item.market === market && item.parentId === data.node.parent) {
            item.isChecked = 'checked';
          }
        });
      }
    }
    this.loadReportData();
    if (recipeModel.rpcRefinementData !== undefined) {
      recipeModel.rpcRefinementData.rpcLeafNodes = this.rpcLeafNodes;

      if (this.totalCompositionPures === 0) {
        this.compositionReportBarTooltipText = 'No PUREs Found';
        this.compositionOverallStatus = 'no';
      }

      recipeModel.rpcRefinementData.compositionOverallStatus = this.compositionReportBarTooltipText;
      recipeModel.rpcRefinementData.compositionOverallStatusColor = this.compositionOverallStatus;

      if (this.totalProductsProperties === 0) {
        this.productReportBarTooltipText = 'No Rules Found';
        this.productOverallStatus = 'no';
      }

      recipeModel.rpcRefinementData.productOverallStatus = this.productReportBarTooltipText;
      recipeModel.rpcRefinementData.productOverallStatusColor = this.productOverallStatus;
    }
  }
  getRPCRefinementMultiMarket() {
    this.getRpcTree();
    this.refinementDetailsPop = true;
  }
  getRpcTree() {
    this.clusterMarketRPCs = [];
    this.isRpcViewAll = true;
    this.uniqueMarkets.forEach((market, index) => {
      this.getMultiMarketRPCs(market, index);
      this.clusterMarketRPCs.push({
        marketName: market,
        rpcs: []
      });
    });
  }
  getMultiMarketRPCs(market, index) {
    this.marketrpcdata = [];
    const marketDetails = this.reportData.marketRPCList.find(item => item.marketName === market);
    this.loading = true;
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
      this.loadingMessage = 'Loading Market RPCs';
    }
    let rpcListAPI = '';
    const requestStatus = this.actualRequestStatus;
    if (requestStatus === 'Completed') {
      rpcListAPI = environment.APIFAsT + '/marketid/' + marketDetails.marketId + '/requestmarketid/' + this.reportData.requestID + '/rpcliststatic';
    } else {
      rpcListAPI = environment.APIFAsT + '/marketid/' + marketDetails.marketId + '/tfcid/' + this.reportData.tfc.tfcId + '/rpclist';
    }
    // const rpcListAPI = environment.APIFAsT + '/marketid/' + marketDetails.marketId + '/tfcid/' + this.reportData.tfc.tfcId + '/rpclist';
    this.reportSubmitService.getRPCList(rpcListAPI).subscribe((data) => {
      const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(this.reportData.tfc.tfcFullText)));
      if (data.length > 0) {
        data.forEach((item, rpcIndex) => {
          const forTreeAPI = environment.APIFAsT + '/marketid/' + marketDetails.marketId + '/requestmarketid/' + this.reportData.requestID + '/rpcs/fortree/rpc/' + item.rpcId;
          this.reportSubmitService.getRPC(forTreeAPI).subscribe((result) => {
            this.loading = false;
            this.clusterMarketRPCs.forEach(marketRPC => {
              if (marketRPC.marketName === market) {
                marketRPC.rpcs.push(result);
              }
            });
            if (result !== null) {
              result.forEach(item2 => item2.market = market);
              result.forEach(item3 => {
                let isLeaf = true;
                result.forEach(item4 => {
                  if (item3.id === item4.parent) {
                    isLeaf = false;
                  }
                });
                if (isLeaf) {
                  item3.leaf = true;
                } else {
                  item3.leaf = false;
                }
              });
            }
            this.marketrpcdata = result;
            if (this.marketrpcdata[0].text === 'No Regulatory Product Class is Mapped') {
              this.marketRpcPlugins = ['changed', 'contextmenu'];
              this.loadReportData();
              setTimeout(() => { this.createMultimarketRpcTree(result, index, rpcIndex, this.rpcLeafNodes, ['changed', 'contextmenu'], market); this.loading = false; }, 1000);
            } else if (result !== null) {
              result.forEach(item5 => {
                let isLeafAvailable = false;
                if (item5.leaf) {
                  this.rpcLeafNodes.forEach(marketLeaf => {
                    if (marketLeaf.market === item5.market && marketLeaf.leaf === item5.text && marketLeaf.parentId === item5.parent) {
                      isLeafAvailable = true;
                    }
                  });
                  if (!isLeafAvailable) {
                    this.rpcLeafNodes.push({
                      market: item5.market,
                      leaf: item5.text,
                      id: item5.id,
                      isChecked: 'checked',
                      fullText: item5.fullText,
                      parentId: item5.parent
                    });
                  }
                }
              });
              this.marketRpcPlugins = ['changed', 'contextmenu', 'checkbox'];
              setTimeout(() => {
                this.createMultimarketRpcTree(result, index, rpcIndex, this.rpcLeafNodes, ['changed', 'contextmenu', 'checkbox'], market); this.loading = false;
              }, 1000);
              this.loadReportData();
            }
          }, (error) => {
            if (error.status !== 200) {
              this.loading = false;
              this.matSnackBar.open(error.message, 'close', {
                duration: 3000,
                panelClass: ['error']
              });
            }
          });
        });
      } else {
        const forTreeAPI = environment.APIFAsT + '/marketid/' + marketDetails.marketId + '/tfcid/' + this.reportData.tfc.tfcId + '/rpcs/fortree/rpc/0';
        this.reportSubmitService.getRPC(forTreeAPI).subscribe((result) => {
          this.clusterMarketRPCs.forEach(marketRPC => {
            if (marketRPC.marketName === market) {
              marketRPC.rpcs.push(result);
            }
          });
          if (result !== null) {
            result.forEach(item2 => item2.market = market);
            result.forEach(item3 => {
              let isLeaf = true;
              result.forEach(item4 => {
                if (item3.id === item4.parent) {
                  isLeaf = false;
                }
              });
              if (isLeaf) {
                item3.leaf = true;
              } else {
                item3.leaf = false;
              }
            });
          }
          this.marketrpcdata = result;
          if (this.marketrpcdata[0].text === 'No Regulatory Product Class is Mapped') {
            this.marketRpcPlugins = ['changed', 'contextmenu'];
            setTimeout(() => { this.createMultimarketRpcTree(result, index, 0, this.rpcLeafNodes, ['changed', 'contextmenu'], market); this.loading = false; }, 1000);
          } else if (result !== null) {
            result.forEach(item5 => {
              let isLeafAvailable = false;
              if (item5.leaf) {
                this.rpcLeafNodes.forEach(marketLeaf => {
                  if (marketLeaf.market === item5.market && marketLeaf.leaf === item5.text && marketLeaf.parentId === item5.parent) {
                    isLeafAvailable = true;
                  }
                });
                if (!isLeafAvailable) {
                  this.rpcLeafNodes.push({
                    market: item5.market,
                    leaf: item5.text,
                    id: item5.id,
                    isChecked: 'checked',
                    fullText: item5.fullText,
                    parentId: item5.parent
                  });
                }
              }
            });
            this.marketRpcPlugins = ['changed', 'contextmenu', 'checkbox'];
            setTimeout(() => {
              this.createMultimarketRpcTree(result, index, 0, this.rpcLeafNodes, ['changed', 'contextmenu', 'checkbox'], market);
            }, 1000);
          }
          this.loadReportData();
        }, (error) => {
          if (error.status !== 200) {
            this.loading = false;
            this.matSnackBar.open(error.message, 'close', {
              duration: 3000,
              panelClass: ['error']
            });
          }
        });
      }
    }, (error) => {
      if (error.status !== 200) {
        this.loading = false;
        this.matSnackBar.open(error.message, 'close', {
          duration: 3000,
          panelClass: ['error']
        });
      }
    });
  }
  /* istanbul ignore next */
  createMultimarketRpcTree(rpcdata, index, rpcIndex, rpcLeafNodes, pluginsArray, market) {
    let to = 0;
    $('#search-tree').keyup(() => {
      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(() => {
        let v: any;
        v = $('#search-tree').val();
        $('#marketTreeDiv' + index + rpcIndex).jstree(true).search(String(v));
      }, 250) ? 1 : 0;
    });
    $('#marketTreeDiv' + index + rpcIndex).on('changed.jstree', (evt, data) => {
      let isRemoveaddCall = true;
      if (this.isRpcViewAll === true && data.action === 'deselect_node') {
        this.isRpcViewAll = false;
        if (data.node != null && this.rpcLeafNodes !== null) {
          this.rpcLeafNodes.forEach((item) => {
            if (item.fullText === data.node.original.fullText && item.isChecked === 'unchecked') {
              isRemoveaddCall = false;
            }
          });
        }
      }
      if (isRemoveaddCall) {
        this.removeAddLeafNodes(data);
      }
    });
    // tslint:disable-next-line: no-shadowed-variable
    $('#marketTreeDiv' + index + rpcIndex).on('changed.jstree', (e, rpcdata) => {
    }).jstree({
      plugins: pluginsArray,
      contextmenu: {
        select_node: false,
        items: this.contextMenu
      },
      checkbox: {
        three_state: true
      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: no-shadowed-variable  // tslint:disable-next-line: space-before-function-paren
    }).bind('loaded.jstree', function (event, rpcdata) {
      $(this).jstree('open_all');
      $(this).jstree('check_all');
      setTimeout(() => {
        rpcLeafNodes.forEach(item => {
          if (item.market === market) {
            if (item.isChecked === 'checked') {
              $('#marketTreeDiv' + index + rpcIndex).jstree('check_node', '#' + item.id + '_anchor');
            } else if (item.isChecked === 'unchecked') {
              $('#marketTreeDiv' + index + rpcIndex).jstree('uncheck_node', '#' + item.id + '_anchor');
            }
          }
        });
      }, 1000);
    });
  }
  /* istanbul ignore next */
  filterReportData() {
    this.filteredReportDataRulesResults = [];
    this.filteredReportDataGroupRuleResults = [];
    this.filteredReportDataProductPropertiesResults = [];
    this.filteredProductData = [];
    this.reportData.compositionTabData.forEach(formulation => {
      this.formulationAlreadyThere = false;
      /* istanbul ignore if */
      if (this.uniqueMultiComponentFormulationRequestDetails.length > 0) {
        this.uniqueMultiComponentFormulationRequestDetails.forEach(uniqueFormulation => {
          if (uniqueFormulation.pureCode === formulation.pureCode && uniqueFormulation.multiComponentID === formulation.multiComponentID && uniqueFormulation.ruleRPC === formulation.ruleRPC) {
            this.formulationAlreadyThere = true;
          }
        });
        /* istanbul ignore if */
        if (!this.formulationAlreadyThere) {
          this.uniqueMultiComponentFormulationRequestDetails.push(formulation);
        }
      } else {
        this.uniqueMultiComponentFormulationRequestDetails.push(formulation);
      }
    });
    /* istanbul ignore next */
    this.uniqueMultiComponentFormulationRequestDetails.forEach(formulation => {
      formulation.formulationStatus = [];
      formulation.rulesResults = [];
      this.reportData.rulesResults.forEach(rule => {
        /* istanbul ignore if */
        if (formulation.pureCode !== null && rule.pureCode !== null) {
          if (formulation.pureCode === rule.pureCode && formulation.multiComponentID === rule.multiComponentID) {
            rule.cfSubstanceExpressedAs = (rule.ruleCFDetails !== null) ? rule.ruleCFDetails.component : '';
            rule.cf = (rule.ruleCFDetails !== null) ? rule.ruleCFDetails.cf : '';
            rule.phaseOutDate = this.pipe.transform(rule.phaseOutDate, 'yyyy-MM-dd');
            rule.warningText = (rule.warningText === null || rule.warningText === '') ? rule.warningStatement : rule.warningText;
            rule.ruleSource = (rule.ruleSource === null || rule.ruleSource === '') ? rule.ruleSourceType : rule.ruleSource;
            rule.substanceID = formulation.substanceID;
            rule.groupApplicability = 'Not applicable';
            rule.convertedRecipeQty = this.reportSubmitService.exponentialToDecimal(rule.convertedRecipeQty);
            rule.recipeQuantity = this.reportSubmitService.exponentialToDecimal(rule.recipeQuantity);
            if (formulation.ruleTypeDisplayName === 'Banned Substance' || formulation.ruleTypeDisplayName === 'Banned list - Group') {
              formulation.formulationStatus.push(1); // banned
            } else if (formulation.statusCode === '30') {
              formulation.formulationStatus.push(2); // failed
            } else if (formulation.statusCode === '20') {
              formulation.formulationStatus.push(3); // warning
            } else if (formulation.statusCode === '41' || formulation.statusCode === '00') {
              formulation.formulationStatus.push(4); // Rule does not exist
            } else if (formulation.statusCode === '45') {
              formulation.formulationStatus.push(5); // Consult RA
            } else if (formulation.statusCode === '40' || formulation.statusCode === '42') {
              formulation.formulationStatus.push(8); // Incorrect/Missing PLM data
            } else if (formulation.statusCode === '10') {
              if (formulation.ruleTypeID === 17 || formulation.ruleTypeID === 25) {
                formulation.formulationStatus.push(7); // non restricted
              } else {
                formulation.formulationStatus.push(6); // passed
              }
            }
            this.filteredReportDataRulesResults.push(rule);
          }
        }
      });
    });
    /* istanbul ignore next */
    this.uniqueMultiComponentFormulationRequestDetails.forEach(formulation => {
      this.reportData.groupRuleResults.forEach(group => {
        if (group.subGroupSubstances !== null) {

          if (group.maxLimit !== null || group.minLimit !== null || group.ruleTypeID === 48) {
            group.subGroupSubstances.forEach(substance => {
              let insertData = {};
              if (formulation.pureCode === substance.pureCode) {
                insertData = {
                  groupApplicability: 'Group level',
                  marketNameForDisplay: group.marketNameForDisplay,
                  rpcListForDisplay: group.rpcList,
                  ruleTypeDisplayName: group.ruleTypeDisplayName,
                  statusCode: group.statusCode,
                  ruleExecutionStatus: group.ruleExecutionStatus,
                  pureCode: substance.pureCode,
                  pureDescription: (substance.pureDescription === null) ? '' : substance.pureDescription,
                  recipeQuantity: (group.groupSubstancesTotalQty !== undefined && group.groupSubstancesTotalQty !== null && group.ruleTypeID !== 48) ? group.groupSubstancesTotalQty : (group.groupSubstancesTotalQty !== undefined && group.groupSubstancesTotalQty !== null && group.ruleTypeID === 48 && substance.recipeQuantity !== null) ? substance.recipeQuantity : '',
                  inciNumber: group.inciNumber,
                  cfSubstanceExpressedAs: (group.ruleCFDetails !== null) ? group.ruleCFDetails.component : '',
                  cf: (group.ruleCFDetails !== null) ? group.ruleCFDetails.cf : '',
                  isAllergen: group.isAllergen,
                  hazardClass: null,
                  ruleRPC: group.ruleRPC,
                  allowedFuncFlag: null,
                  ruleFunctionName: group.ruleFunctionName,
                  rndInputFunction: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
                  substanceFunctionInput: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
                  equalityCondition: group.equalityCondition,
                  minLimit: group.minLimit,
                  maxLimit: group.maxLimit,
                  recipeUoM: (substance.recipeUoM !== undefined && substance.recipeUoM !== null) ? substance.recipeUoM : '',
                  ruleUoM: (group.ruleUoM === null) ? '' : group.ruleUoM,
                  groupName: group.groupName,
                  isExempted: substance.isExempted,
                  conflictingSubstance: null,
                  combinationSubstance: null,
                  combinationRatioToDisplay: null,
                  ruleSpecificConditionName: group.ruleSpecificConditionName,
                  ruleClaimName: group.ruleClaimName,
                  phaseOutDate: substance.phaseOutDate,
                  warningText: group.warningStatement,
                  otherRestrictionsAndRequirements: group.otherRestrictionsAndRequirements,
                  propertySource: null,
                  propertyClass: null,
                  propertyName: null,
                  ingPropertyType: null,
                  ingPropertyName: null,
                  ruleSource: (group.ruleSource === null || group.ruleSource === '') ? group.ruleSourceType : group.ruleSource,
                  validityDate: group.validityDate,
                  substanceID: substance.sirId,
                  casNumber: substance.casNumber,
                  parentCASNumber: substance.parentCASNumber,
                  einecsNumber: substance.einecsNumber,
                  eNumber: substance.eNumber,
                  insNumber: substance.insNumber,
                  multiComponentName: group.multiComponentName,
                  multiComponentID: group.multiComponentID,
                  preparationMethod: group.preparationMethod,
                  intendedUse: group.intendedUse,
                  commentForDisplay: group.commentForDisplay,
                  groupID: group.groupID,
                  ruleID: group.ruleID,
                  ruleVersion: group.ruleVersion,
                  convertedRecipeQty: (group.convertedRecipeQty !== undefined && group.convertedRecipeQty !== null && group.ruleTypeID !== 48) ? this.reportSubmitService.exponentialToDecimal(group.convertedRecipeQty) : (group.ruleTypeID === 48 && substance.recipeQuantity !== null) ? substance.recipeQuantity : '',
                  finalUoM: (substance.finalUoM !== undefined && substance.finalUoM !== null) ? substance.finalUoM : '',
                  acidityAsAceticAcid: '',
                  aceticAcidUoM: '',
                  brixOfLemon: '',
                  brixOfLemonUoM: '',
                  ruleTypeID: group.ruleTypeID,
                  specId: '',
                  ingredientPath: '',
                  ingredientDescription: '',
                  amendmentNumber: '',
                  supressMarketRuleForClusterLevel: group.supressMarketRuleForClusterLevel
                };
                this.filteredReportDataRulesResults.push(insertData);
              }
            });
          }
        } else {
          let elseInsertData = {};
          if (formulation.pureCode === group.pureCode) {
            elseInsertData = {
              groupApplicability: 'Individual level',
              rpcListForDisplay: group.rpcList,
              ruleTypeDisplayName: group.ruleTypeDisplayName,
              statusCode: group.statusCode,
              marketNameForDisplay: group.marketNameForDisplay,
              ruleExecutionStatus: group.ruleExecutionStatus,
              pureCode: group.pureCode,
              pureDescription: (group.pureDescription === null) ? '' : group.pureDescription,
              recipeQuantity: (group.recipeQuantity !== undefined && group.recipeQuantity !== null) ? group.recipeQuantity : '',
              inciNumber: group.inciNumber,
              cfSubstanceExpressedAs: (group.ruleCFDetails !== null) ? group.ruleCFDetails.component : '',
              cf: (group.ruleCFDetails !== null) ? group.ruleCFDetails.cf : '',
              isAllergen: group.isAllergen,
              hazardClass: null,
              ruleRPC: group.ruleRPC,
              allowedFuncFlag: null,
              ruleFunctionName: group.ruleFunctionName,
              rndInputFunction: group.rndInputFunction,
              substanceFunctionInput: group.substanceFunctionInput,
              equalityCondition: group.equalityCondition,
              minLimit: group.minLimit,
              maxLimit: group.maxLimit,
              recipeUoM: group.recipeUoM,
              ruleUoM: (group.ruleUoM === null) ? '' : group.ruleUoM,
              groupName: group.groupName,
              isExempted: group.isExempted,
              conflictingSubstance: null,
              combinationSubstance: null,
              combinationRatioToDisplay: null,
              ruleSpecificConditionName: group.ruleSpecificConditionName,
              ruleClaimName: group.ruleClaimName,
              phaseOutDate: group.phaseOutDate,
              warningText: group.warningStatement,
              otherRestrictionsAndRequirements: group.otherRestrictionsAndRequirements,
              propertySource: null,
              propertyClass: null,
              propertyName: null,
              ingPropertyType: null,
              ingPropertyName: null,
              ruleSource: (group.ruleSource === null || group.ruleSource === '') ? group.ruleSourceType : group.ruleSource,
              validityDate: group.validityDate,
              substanceID: group.substanceID,
              casNumber: group.casNumber,
              parentCASNumber: group.parentCASNumber,
              einecsNumber: group.einecsNumber,
              eNumber: group.eNumber,
              insNumber: group.insNumber,
              multiComponentName: group.multiComponentName,
              multiComponentID: group.multiComponentID,
              preparationMethod: group.preparationMethod,
              intendedUse: group.intendedUse,
              commentForDisplay: group.commentForDisplay,
              groupID: group.groupID,
              ruleID: group.ruleID,
              ruleVersion: group.ruleVersion,
              convertedRecipeQty: this.reportSubmitService.exponentialToDecimal(group.convertedRecipeQty),
              finalUoM: group.finalUoM,
              acidityAsAceticAcid: '',
              aceticAcidUoM: '',
              brixOfLemon: '',
              brixOfLemonUoM: '',
              ruleTypeID: group.ruleTypeID,
              specId: '',
              ingredientPath: '',
              ingredientDescription: '',
              amendmentNumber: '',
              supressMarketRuleForClusterLevel: group.supressMarketRuleForClusterLevel
            };
            this.filteredReportDataRulesResults.push(elseInsertData);
          }
        }
        if (group.conflictingSubGroupSubstances !== null) {
          if (group.maxLimit !== null || group.minLimit !== null) {
            group.conflictingSubGroupSubstances.forEach(substance => {
              let insertData = {};
              if (formulation.pureCode === substance.pureCode) {
                insertData = {
                  groupApplicability: 'Group level',
                  marketNameForDisplay: group.marketNameForDisplay,
                  rpcListForDisplay: group.rpcList,
                  ruleTypeDisplayName: group.ruleTypeDisplayName,
                  statusCode: group.statusCode,
                  ruleExecutionStatus: group.ruleExecutionStatus,
                  pureCode: substance.pureCode,
                  pureDescription: (substance.pureDescription === null) ? '' : substance.pureDescription,
                  recipeQuantity: (substance.recipeQuantity !== undefined && substance.recipeQuantity !== null) ? substance.recipeQuantity : '',
                  inciNumber: group.inciNumber,
                  cfSubstanceExpressedAs: (group.ruleCFDetails !== null) ? group.ruleCFDetails.component : '',
                  cf: (group.ruleCFDetails !== null) ? group.ruleCFDetails.cf : '',
                  isAllergen: group.isAllergen,
                  hazardClass: null,
                  ruleRPC: group.ruleRPC,
                  allowedFuncFlag: null,
                  ruleFunctionName: group.ruleFunctionName,
                  rndInputFunction: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
                  substanceFunctionInput: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
                  equalityCondition: group.equalityCondition,
                  minLimit: group.minLimit,
                  maxLimit: group.maxLimit,
                  recipeUoM: (substance.recipeUoM !== undefined && substance.recipeUoM !== null) ? substance.recipeUoM : '',
                  ruleUoM: (group.ruleUoM === null) ? '' : group.ruleUoM,
                  groupName: group.conflictingGroupName,
                  isExempted: substance.isExempted,
                  conflictingSubstance: null,
                  combinationSubstance: null,
                  combinationRatioToDisplay: null,
                  ruleSpecificConditionName: group.ruleSpecificConditionName,
                  ruleClaimName: group.ruleClaimName,
                  phaseOutDate: substance.phaseOutDate,
                  warningText: group.warningStatement,
                  otherRestrictionsAndRequirements: group.otherRestrictionsAndRequirements,
                  propertySource: null,
                  propertyClass: null,
                  propertyName: null,
                  ingPropertyType: null,
                  ingPropertyName: null,
                  ruleSource: (group.ruleSource === null || group.ruleSource === '') ? group.ruleSourceType : group.ruleSource,
                  validityDate: group.validityDate,
                  substanceID: substance.sirId,
                  casNumber: substance.casNumber,
                  parentCASNumber: substance.parentCASNumber,
                  einecsNumber: substance.einecsNumber,
                  eNumber: substance.eNumber,
                  insNumber: substance.insNumber,
                  multiComponentName: group.multiComponentName,
                  multiComponentID: group.multiComponentID,
                  preparationMethod: group.preparationMethod,
                  intendedUse: group.intendedUse,
                  commentForDisplay: group.commentForDisplay,
                  groupID: group.groupID,
                  ruleID: group.ruleID,
                  ruleVersion: group.ruleVersion,
                  convertedRecipeQty: (substance.convertedRecipeQty !== undefined && substance.convertedRecipeQty !== null) ? this.reportSubmitService.exponentialToDecimal(substance.convertedRecipeQty) : '',
                  finalUoM: (substance.finalUoM !== undefined && substance.finalUoM !== null) ? substance.finalUoM : '',
                  aceticAcidUoM: '',
                  brixOfLemon: '',
                  brixOfLemonUoM: '',
                  ruleTypeID: group.ruleTypeID,
                  specId: '',
                  ingredientDescription: '',
                  amendmentNumber: '',
                  supressMarketRuleForClusterLevel: group.supressMarketRuleForClusterLevel
                };
                this.filteredReportDataRulesResults.push(insertData);
              }
            });
          }
        }
      });
    });
    // Fragrance data
    if (this.reportData.fragranceRuleResults !== undefined && this.reportData.fragranceRuleResults !== null && this.fragranceFlag === 'true') {
      this.uniqueMultiComponentFormulationRequestDetails.forEach(formulation => {
        this.reportData.fragranceRuleResults.forEach(fragrance => {
          if (fragrance.ingredientSubstances !== null) {
            fragrance.ingredientSubstances.forEach(ingredient => {
              let fragranceInsertData = {};
              if (formulation.pureCode === ingredient.pureCode) {
                fragranceInsertData = {
                  groupApplicability: 'Ingredient Level',
                  rpcListForDisplay: fragrance.rpcListForDisplay,
                  ruleTypeDisplayName: fragrance.ruleTypeDisplayName,
                  statusCode: fragrance.statusCode,
                  marketNameForDisplay: fragrance.marketNameForDisplay,
                  ruleExecutionStatus: fragrance.ruleExecutionStatus,
                  pureCode: ingredient.pureCode,
                  pureDescription: (ingredient.pureDescription === null) ? '' : ingredient.pureDescription,
                  recipeQuantity: (ingredient.recipeQuantity !== undefined && ingredient.recipeQuantity !== null) ? ingredient.recipeQuantity : '',
                  inciNumber: '',
                  cfSubstanceExpressedAs: '',
                  cf: '',
                  isAllergen: '',
                  hazardClass: '',
                  ruleRPC: '',
                  allowedFuncFlag: '',
                  ruleFunctionName: '',
                  rndInputFunction: '',
                  substanceFunctionInput: '',
                  equalityCondition: fragrance.equalityCondition,
                  minLimit: fragrance.minLimit,
                  maxLimit: fragrance.maxLimit,
                  recipeUoM: ingredient.recipeUoM === null ? ingredient.uoM : ingredient.recipeUoM,
                  ruleUoM: (fragrance.ruleUoM === null) ? '' : fragrance.ruleUoM,
                  groupName: '',
                  isExempted: ingredient.isExempted,
                  conflictingSubstance: '',
                  combinationSubstance: '',
                  combinationRatioToDisplay: '',
                  ruleSpecificConditionName: '',
                  ruleClaimName: '',
                  phaseOutDate: '',
                  warningText: '',
                  otherRestrictionsAndRequirements: '',
                  propertySource: '',
                  propertyClass: '',
                  propertyName: '',
                  ingPropertyType: '',
                  ingPropertyName: '',
                  ruleSource: '',
                  validityDate: '',
                  substanceID: ingredient.sirId,
                  casNumber: '',
                  parentCASNumber: '',
                  einecsNumber: '',
                  eNumber: ingredient.eNumber,
                  insNumber: '',
                  multiComponentName: fragrance.multiComponentName == null ? '' : fragrance.multiComponentName,
                  multiComponentID: fragrance.multiComponentID == null ? '' : fragrance.multiComponentID,
                  preparationMethod: fragrance.preparationMethod,
                  intendedUse: fragrance.intendedUse,
                  commentForDisplay: fragrance.commentForDisplay,
                  groupID: '',
                  ruleID: '',
                  ruleVersion: '',
                  convertedRecipeQty: this.reportSubmitService.exponentialToDecimal(fragrance.convertedRecipeQty),
                  finalUoM: fragrance.finalUoM,
                  acidityAsAceticAcid: '',
                  aceticAcidUoM: '',
                  brixOfLemon: '',
                  brixOfLemonUoM: '',
                  ruleTypeID: fragrance.ruleTypeID,
                  specId: fragrance.specID,
                  ingredientDescription: fragrance.specDescription,
                  amendmentNumber: fragrance.amendmentNumber === null ? '' : fragrance.amendmentNumber
                };
                this.filteredReportDataRulesResults.push(fragranceInsertData);
              }
            });

          }

        });
      });
    }
    // end fragrance data
    const ClusterLevelRequest = this.reportData.marketRPCList.filter(m => m.isCluster === true && m.level >= 1 && m.marketName.toLowerCase() !== 'unilever');
    // const supressPureCodes = this.filteredReportDataRulesResults.filter(r=> r.supressMarketRuleForClusterLevel === true).map(s => s.pureCode);
    let isClusterLevelRequest = false;
    let checkedClusterMarketList = true;
    if (ClusterLevelRequest.length > 0) {
      isClusterLevelRequest = true;
      if (this.rpcLeafNodes !== undefined) {
        if (this.rpcLeafNodes.filter(m => m.isChecked === 'checked' && m.market.toLowerCase() !== this.reportData.marketDetails.marketName.toLowerCase()).length > 0) {
          checkedClusterMarketList = true;
        } else {
          checkedClusterMarketList = false;
        }
      }
    }
    const tempData = this.filteredReportDataRulesResults;
    this.filteredReportDataRulesResults = [];
    if (!isClusterLevelRequest) {
      tempData.forEach((rule, index) => {
        this.rpcLeafNodes.forEach(leaf => {
          if ((rule.ruleTypeID === 46 || rule.ruleTypeID === 47)) {
            const rowData = this.filteredReportDataRulesResults.filter(s => s.ruleTypeID === rule.ruleTypeID && s.pureCode === rule.pureCode);
            if (rowData.length <= 0) {
              this.filteredReportDataRulesResults.push(rule);
            }
          } else if (leaf.isChecked === 'checked' && rule.rpcListForDisplay === leaf.fullText && rule.marketNameForDisplay === leaf.market) {
            this.filteredReportDataRulesResults.push(rule);
          }
        });
      });
    } else if (isClusterLevelRequest) {
      tempData.forEach((rule, index) => {
        this.rpcLeafNodes.forEach(leaf => {
          if ((rule.ruleTypeID === 46 || rule.ruleTypeID === 47)) {
            const rowData = this.filteredReportDataRulesResults.filter(s => s.ruleTypeID === rule.ruleTypeID && s.pureCode === rule.pureCode);
            if (rowData.length <= 0) {
              this.filteredReportDataRulesResults.push(rule);
            }
          } else if (leaf.isChecked === 'checked' && rule.rpcListForDisplay === leaf.fullText && rule.marketNameForDisplay === leaf.market && rule.supressMarketRuleForClusterLevel === false && checkedClusterMarketList === true) {
            this.filteredReportDataRulesResults.push(rule);
          } else if (leaf.isChecked === 'checked' && rule.rpcListForDisplay === leaf.fullText && rule.marketNameForDisplay === leaf.market && checkedClusterMarketList === false) {
            this.filteredReportDataRulesResults.push(rule);
          }
        });
      });
      // Below logic handles to match number of pure counts
      if (this.initialtotalCompositionPures > 0) {
        const purecodes = this.filteredReportDataRulesResults.map(pure => {
          return pure.pureCode;
        });
        const leafCheckedlength = this.rpcLeafNodes.filter(m => m.isChecked === 'checked').length;
        const totalPurecounts = purecodes.filter((pure, index, self) => self.indexOf(pure) === index).filter(pure => pure !== null);
        if (totalPurecounts.length !== this.initialtotalCompositionPures && leafCheckedlength > 0) {
          if (totalPurecounts.length > this.initialtotalCompositionPures) {
            let isPureExist = false;
            this.filteredReportDataRulesResults.forEach(fresult => {
              isPureExist = false;
              this.initialUniquePures.forEach(supPureCode => {
                if (supPureCode === fresult.pureCode) {
                  isPureExist = true;
                }
              });
              if (isPureExist === false) {
                this.filteredReportDataRulesResults = this.filteredReportDataRulesResults.filter(({ pureCode }) => pureCode !== fresult.pureCode);
              }
            });
          } else if (totalPurecounts.length < this.initialtotalCompositionPures) {
            let isSuppPureExists = false;
            this.initialUniquePures.forEach(supPureCode => {
              isSuppPureExists = false;
              this.filteredReportDataRulesResults.forEach(result => {
                if (supPureCode === result.pureCode) {
                  isSuppPureExists = true;
                }
              });
              if (isSuppPureExists === false) {
                const rule = tempData.filter(t => t.pureCode === supPureCode && t.supressMarketRuleForClusterLevel === true).map(s => s);
                if (rule.length > 0) {
                  rule.forEach(ruledata => {
                    const isRecordtoPush = this.rpcLeafNodes.filter(f => f.isChecked === 'checked' && f.market.toLowerCase() === ruledata.marketNameForDisplay.toLowerCase()).length;
                    if (isRecordtoPush > 0) {
                      this.filteredReportDataRulesResults.push(ruledata);
                    }
                  });
                }
              }
            });
          }
          this.filteredReportDataRulesResults.sort((a, b) => (a.pureCode > b.pureCode) ? 1 : -1);
        }
      }
    }
    // details popup result data
    this.detailsPopupResultData = [];
    this.reportData.rulesResults.forEach(rule => {
      rule.cfSubstanceExpressedAs = (rule.ruleCFDetails !== null) ? rule.ruleCFDetails.component : '';
      rule.cf = (rule.ruleCFDetails !== null) ? rule.ruleCFDetails.cf : '';
      rule.groupApplicability = 'Not applicable';
      if (rule.ruleTypeDisplayName === 'Banned Substance' || rule.ruleTypeDisplayName === 'Banned list - Group') {
        rule.icon = 'banned'; // banned
      } else if (rule.statusCode === '30') {
        rule.icon = 'failed'; // failed
      } else if (rule.statusCode === '20') {
        rule.icon = 'warning'; // warning
      } else if (rule.statusCode === '41' || rule.statusCode === '00') {
        rule.icon = 'no-rule-found'; // Rule does not exist
      } else if (rule.statusCode === '45') {
        rule.icon = 'rule-execution-issues'; // Consult RA
      } else if (rule.statusCode === '40' || rule.statusCode === '42') {
        rule.icon = 'plm'; // Incorrect/Missing PLM data
      } else if (rule.statusCode === '10') {
        if (rule.ruleTypeID === 17 || rule.ruleTypeID === 25) {
          rule.icon = 'non-restricted'; // non restricted
        } else {
          rule.icon = 'passed'; // passed
        }
      }
      this.detailsPopupResultData.push(rule);
    });
    this.reportData.groupRuleResults.forEach(group => {
      if (group.subGroupSubstances !== null) {
        let statusIcon = '';
        if (group.ruleTypeDisplayName === 'Banned Substance' || group.ruleTypeDisplayName === 'Banned list - Group') {
          statusIcon = 'banned'; // banned
        } else if (group.statusCode === '30') {
          statusIcon = 'failed'; // failed
        } else if (group.statusCode === '20') {
          statusIcon = 'warning'; // warning
        } else if (group.statusCode === '41' || group.statusCode === '00') {
          statusIcon = 'no-rule-found'; // Rule does not exist
        } else if (group.statusCode === '45') {
          statusIcon = 'rule-execution-issues'; // Consult RA
        } else if (group.statusCode === '40' || group.statusCode === '42') {
          statusIcon = 'plm'; // Incorrect/Missing PLM data
        } else if (group.statusCode === '10') {
          if (group.ruleTypeID === 17 || group.ruleTypeID === 25) {
            statusIcon = 'non-restricted'; // non restricted
          } else {
            statusIcon = 'passed'; // passed
          }
        }
        const groupInsertData = {
          groupApplicability: 'Group level',
          icon: statusIcon,
          rpcListForDisplay: group.rpcList,
          ruleTypeDisplayName: group.ruleTypeDisplayName,
          statusCode: group.statusCode,
          marketNameForDisplay: group.marketNameForDisplay,
          ruleExecutionStatus: group.ruleExecutionStatus,
          pureCode: (group.pureCode === undefined) ? '' : group.pureCode,
          pureDescription: (group.pureDescription === undefined) ? '' : group.pureDescription,
          recipeQuantity: (group.groupSubstancesTotalQty !== undefined && group.groupSubstancesTotalQty !== null) ? group.groupSubstancesTotalQty : '',
          inciNumber: group.inciNumber,
          cfSubstanceExpressedAs: (group.ruleCFDetails !== null) ? group.ruleCFDetails.component : '',
          cf: (group.ruleCFDetails !== null) ? group.ruleCFDetails.cf : '',
          isAllergen: group.isAllergen,
          hazardClass: null,
          ruleRPC: group.ruleRPC,
          allowedFuncFlag: null,
          ruleFunctionName: group.ruleFunctionName,
          rndInputFunction: group.rndInputFunction,
          substanceFunctionInput: group.substanceFunctionInput,
          equalityCondition: group.equalityCondition,
          minLimit: group.minLimit,
          maxLimit: group.maxLimit,
          recipeUoM: group.recipeUoM,
          ruleUoM: (group.ruleUoM === null) ? '' : group.ruleUoM,
          groupName: group.groupName,
          isExempted: group.isExempted,
          conflictingSubstance: null,
          combinationSubstance: null,
          combinationRatioToDisplay: null,
          ruleSpecificConditionName: group.ruleSpecificConditionName,
          ruleClaimName: group.ruleClaimName,
          phaseOutDate: null,
          warningText: group.warningStatement,
          otherRestrictionsAndRequirements: group.otherRestrictionsAndRequirements,
          propertySource: null,
          propertyClass: null,
          propertyName: null,
          ingPropertyType: null,
          ingPropertyName: null,
          ruleSource: (group.ruleSource === null || group.ruleSource === '') ? group.ruleSourceType : group.ruleSource,
          validityDate: group.validityDate,
          substanceID: group.substanceID,
          casNumber: group.casNumber,
          parentCASNumber: group.parentCASNumber,
          einecsNumber: group.einecsNumber,
          eNumber: group.eNumber,
          insNumber: group.insNumber,
          multiComponentName: group.multiComponentName,
          multiComponentID: group.multiComponentID,
          preparationMethod: group.preparationMethod,
          intendedUse: group.intendedUse,
          commentForDisplay: group.commentForDisplay,
          groupID: group.groupID,
          ruleID: group.ruleID,
          ruleVersion: group.ruleVersion,
          convertedRecipeQty: this.reportSubmitService.exponentialToDecimal(group.convertedRecipeQty),
          finalUoM: group.finalUoM,
          acidityAsAceticAcid: '',
          aceticAcidUoM: '',
          brixOfLemon: '',
          brixOfLemonUoM: '',
          ruleTypeID: group.ruleTypeID,
          statusDescription: group.statusDescription,
          ingredientDescription: '',
          specId: '',
          amendmentNumber: '',
          supressMarketRuleForClusterLevel: group.supressMarketRuleForClusterLevel
        };
        this.detailsPopupResultData.push(groupInsertData);
        group.subGroupSubstances.forEach(substance => {
          let insertData = {};
          insertData = {
            groupApplicability: 'Group level',
            icon: statusIcon,
            marketNameForDisplay: group.marketNameForDisplay,
            rpcListForDisplay: group.rpcList,
            ruleTypeDisplayName: group.ruleTypeDisplayName,
            statusCode: group.statusCode,
            ruleExecutionStatus: group.ruleExecutionStatus,
            pureCode: substance.pureCode,
            pureDescription: (substance.pureDescription === null) ? '' : substance.pureDescription,
            recipeQuantity: (substance.recipeQuantity !== undefined && substance.recipeQuantity !== null) ? substance.recipeQuantity : '',
            inciNumber: group.inciNumber,
            cfSubstanceExpressedAs: (substance.cfDetails !== null) ? substance.cfDetails[0].component : '',
            cf: (substance.cfDetails !== null) ? substance.cfDetails[0].cf : '',
            isAllergen: group.isAllergen,
            hazardClass: null,
            ruleRPC: group.ruleRPC,
            allowedFuncFlag: null,
            ruleFunctionName: group.ruleFunctionName,
            rndInputFunction: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
            substanceFunctionInput: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
            equalityCondition: '',
            minLimit: '',
            maxLimit: '',
            recipeUoM: (substance.recipeUoM !== undefined && substance.recipeUoM !== null) ? substance.recipeUoM : '',
            ruleUoM: '',
            groupName: group.groupName,
            isExempted: substance.isExempted,
            conflictingSubstance: null,
            combinationSubstance: null,
            combinationRatioToDisplay: null,
            ruleSpecificConditionName: group.ruleSpecificConditionName,
            ruleClaimName: group.ruleClaimName,
            phaseOutDate: substance.phaseOutDate,
            warningText: group.warningStatement,
            warningTextStripped: (group.warningStatement == null || group.warningStatement === '') ? '' : this.handleSpecialCharacters(group.warningStatement),
            otherRestrictionsAndRequirements: group.otherRestrictionsAndRequirements,
            otherRestrictionsAndRequirementsStripped: (group.otherRestrictionsAndRequirements == null || group.otherRestrictionsAndRequirements === '') ? '' : this.handleSpecialCharacters(group.otherRestrictionsAndRequirements),
            propertySource: null,
            propertyClass: null,
            propertyName: null,
            ingPropertyType: null,
            ingPropertyName: null,
            ruleSource: (group.ruleSource === null || group.ruleSource === '') ? group.ruleSourceType : group.ruleSource,
            validityDate: group.validityDate,
            substanceID: substance.sirId,
            casNumber: substance.casNumber,
            parentCASNumber: substance.parentCASNumber,
            einecsNumber: substance.einecsNumber,
            eNumber: substance.eNumber,
            insNumber: substance.insNumber,
            multiComponentName: group.multiComponentName,
            multiComponentID: group.multiComponentID,
            preparationMethod: group.preparationMethod,
            intendedUse: group.intendedUse,
            commentForDisplay: group.commentForDisplay,
            groupID: group.groupID,
            ruleID: group.ruleID,
            ruleVersion: group.ruleVersion,
            convertedRecipeQty: (substance.convertedRecipeQty !== undefined && substance.convertedRecipeQty !== null) ? this.reportSubmitService.exponentialToDecimal(substance.convertedRecipeQty) : '',
            finalUoM: (substance.finalUoM !== undefined && substance.finalUoM !== null) ? substance.finalUoM : '',
            acidityAsAceticAcid: '',
            aceticAcidUoM: '',
            brixOfLemon: '',
            brixOfLemonUoM: '',
            ruleTypeID: group.ruleTypeID,
            statusDescription: group.statusDescription,
            ingredientDescription: '',
            specId: '',
            amendmentNumber: '',
            supressMarketRuleForClusterLevel: group.supressMarketRuleForClusterLevel
          };
          this.detailsPopupResultData.push(insertData);
        });
      } else {
        let elseInsertData = {};
        let statusIcon = '';
        if (group.ruleTypeDisplayName === 'Banned Substance' || group.ruleTypeDisplayName === 'Banned list - Group') {
          statusIcon = 'banned'; // banned
        } else if (group.statusCode === '30') {
          statusIcon = 'failed'; // failed
        } else if (group.statusCode === '20') {
          statusIcon = 'warning'; // warning
        } else if (group.statusCode === '41' || group.statusCode === '00') {
          statusIcon = 'no-rule-found'; // Rule does not exist
        } else if (group.statusCode === '45') {
          statusIcon = 'rule-execution-issues'; // Consult RA
        } else if (group.statusCode === '40' || group.statusCode === '42') {
          statusIcon = 'plm'; // Incorrect/Missing PLM data
        } else if (group.statusCode === '10') {
          if (group.ruleTypeID === 17 || group.ruleTypeID === 25) {
            statusIcon = 'non-restricted'; // non restricted
          } else {
            statusIcon = 'passed'; // passed
          }
        }
        elseInsertData = {
          groupApplicability: 'Individual level',
          icon: statusIcon,
          rpcListForDisplay: group.rpcList,
          ruleTypeDisplayName: group.ruleTypeDisplayName,
          statusCode: group.statusCode,
          marketNameForDisplay: group.marketNameForDisplay,
          ruleExecutionStatus: group.ruleExecutionStatus,
          pureCode: group.pureCode,
          pureDescription: (group.pureDescription === null) ? '' : group.pureDescription,
          recipeQuantity: (group.recipeQuantity !== undefined && group.recipeQuantity !== null) ? group.recipeQuantity : '',
          inciNumber: group.inciNumber,
          cfSubstanceExpressedAs: (group.ruleCFDetails !== null) ? group.ruleCFDetails.component : '',
          cf: (group.ruleCFDetails !== null) ? group.ruleCFDetails.cf : '',
          isAllergen: group.isAllergen,
          hazardClass: null,
          ruleRPC: group.ruleRPC,
          allowedFuncFlag: null,
          ruleFunctionName: group.ruleFunctionName,
          rndInputFunction: group.rndInputFunction,
          substanceFunctionInput: group.substanceFunctionInput,
          equalityCondition: group.equalityCondition,
          minLimit: group.minLimit,
          maxLimit: group.maxLimit,
          recipeUoM: group.recipeUoM,
          ruleUoM: (group.ruleUoM === null) ? '' : group.ruleUoM,
          groupName: group.groupName,
          isExempted: group.isExempted,
          conflictingSubstance: null,
          combinationSubstance: null,
          combinationRatioToDisplay: null,
          ruleSpecificConditionName: group.ruleSpecificConditionName,
          ruleClaimName: group.ruleClaimName,
          phaseOutDate: group.phaseOutDate,
          warningText: group.warningStatement,
          warningTextStripped: (group.warningStatement == null || group.warningStatement === '') ? '' : this.handleSpecialCharacters(group.warningStatement),
          otherRestrictionsAndRequirements: group.otherRestrictionsAndRequirements,
          otherRestrictionsAndRequirementsStripped: (group.otherRestrictionsAndRequirements == null || group.otherRestrictionsAndRequirements === '') ? '' : this.handleSpecialCharacters(group.otherRestrictionsAndRequirements),
          propertySource: null,
          propertyClass: null,
          propertyName: null,
          ingPropertyType: null,
          ingPropertyName: null,
          ruleSource: (group.ruleSource === null || group.ruleSource === '') ? group.ruleSourceType : group.ruleSource,
          validityDate: group.validityDate,
          substanceID: group.substanceID,
          casNumber: group.casNumber,
          parentCASNumber: group.parentCASNumber,
          einecsNumber: group.einecsNumber,
          eNumber: group.eNumber,
          insNumber: group.insNumber,
          multiComponentName: group.multiComponentName,
          multiComponentID: group.multiComponentID,
          preparationMethod: group.preparationMethod,
          intendedUse: group.intendedUse,
          commentForDisplay: group.commentForDisplay,
          groupID: group.groupID,
          ruleID: group.ruleID,
          ruleVersion: group.ruleVersion,
          convertedRecipeQty: this.reportSubmitService.exponentialToDecimal(group.convertedRecipeQty),
          finalUoM: group.finalUoM,
          acidityAsAceticAcid: '',
          aceticAcidUoM: '',
          brixOfLemon: '',
          brixOfLemonUoM: '',
          ruleTypeID: group.ruleTypeID,
          statusDescription: group.statusDescription,
          ingredientDescription: '',
          specId: '',
          amendmentNumber: ''
        };
        this.detailsPopupResultData.push(elseInsertData);
      }
      if (group.conflictingSubGroupSubstances !== null) {
        let statusIcon = '';
        if (group.ruleTypeDisplayName === 'Banned Substance' || group.ruleTypeDisplayName === 'Banned list - Group') {
          statusIcon = 'banned'; // banned
        } else if (group.statusCode === '30') {
          statusIcon = 'failed'; // failed
        } else if (group.statusCode === '20') {
          statusIcon = 'warning'; // warning
        } else if (group.statusCode === '41' || group.statusCode === '00') {
          statusIcon = 'no-rule-found'; // Rule does not exist
        } else if (group.statusCode === '45') {
          statusIcon = 'rule-execution-issues'; // Consult RA
        } else if (group.statusCode === '40' || group.statusCode === '42') {
          statusIcon = 'plm'; // Incorrect/Missing PLM data
        } else if (group.statusCode === '10') {
          if (group.ruleTypeID === 17 || group.ruleTypeID === 25) {
            statusIcon = 'non-restricted'; // non restricted
          } else {
            statusIcon = 'passed'; // passed
          }
        }
        group.conflictingSubGroupSubstances.forEach(substance => {
          let insertData = {};
          insertData = {
            groupApplicability: 'Group level',
            icon: statusIcon,
            marketNameForDisplay: group.marketNameForDisplay,
            rpcListForDisplay: group.rpcList,
            ruleTypeDisplayName: group.ruleTypeDisplayName,
            statusCode: group.statusCode,
            ruleExecutionStatus: group.ruleExecutionStatus,
            pureCode: substance.pureCode,
            pureDescription: (substance.pureDescription === null) ? '' : substance.pureDescription,
            recipeQuantity: (substance.recipeQuantity !== undefined && substance.recipeQuantity !== null) ? substance.recipeQuantity : '',
            inciNumber: group.inciNumber,
            cfSubstanceExpressedAs: (substance.cfDetails !== null) ? substance.cfDetails[0].component : '',
            cf: (substance.cfDetails !== null) ? substance.cfDetails[0].cf : '',
            isAllergen: group.isAllergen,
            hazardClass: null,
            ruleRPC: group.ruleRPC,
            allowedFuncFlag: null,
            ruleFunctionName: group.ruleFunctionName,
            rndInputFunction: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
            substanceFunctionInput: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
            equalityCondition: '',
            minLimit: '',
            maxLimit: '',
            recipeUoM: (substance.recipeUoM !== undefined && substance.recipeUoM !== null) ? substance.recipeUoM : '',
            ruleUoM: '',
            groupName: group.conflictingGroupName,
            isExempted: substance.isExempted,
            conflictingSubstance: null,
            combinationSubstance: null,
            combinationRatioToDisplay: null,
            ruleSpecificConditionName: group.ruleSpecificConditionName,
            ruleClaimName: group.ruleClaimName,
            phaseOutDate: substance.phaseOutDate,
            warningText: group.warningStatement,
            warningTextStripped: (group.warningStatement == null || group.warningStatement === '') ? '' : this.handleSpecialCharacters(group.warningStatement),
            otherRestrictionsAndRequirements: group.otherRestrictionsAndRequirements,
            propertySource: null,
            propertyClass: null,
            propertyName: null,
            ingPropertyType: null,
            ingPropertyName: null,
            ruleSource: (group.ruleSource === null || group.ruleSource === '') ? group.ruleSourceType : group.ruleSource,
            validityDate: group.validityDate,
            substanceID: substance.sirId,
            casNumber: substance.casNumber,
            parentCASNumber: substance.parentCASNumber,
            einecsNumber: substance.einecsNumber,
            eNumber: substance.eNumber,
            insNumber: substance.insNumber,
            multiComponentName: group.multiComponentName,
            multiComponentID: group.multiComponentID,
            preparationMethod: group.preparationMethod,
            intendedUse: group.intendedUse,
            commentForDisplay: group.commentForDisplay,
            groupID: group.groupID,
            ruleID: group.ruleID,
            ruleVersion: group.ruleVersion,
            convertedRecipeQty: (substance.convertedRecipeQty !== undefined && substance.convertedRecipeQty !== null) ? this.reportSubmitService.exponentialToDecimal(substance.convertedRecipeQty) : '',
            finalUoM: (substance.finalUoM !== undefined && substance.finalUoM !== null) ? substance.finalUoM : '',
            acidityAsAceticAcid: '',
            aceticAcidUoM: '',
            brixOfLemon: '',
            brixOfLemonUoM: '',
            ruleTypeID: group.ruleTypeID,
            statusDescription: group.statusDescription,
            ingredientDescription: '',
            specId: '',
            amendmentNumber: '',
            supressMarketRuleForClusterLevel: group.supressMarketRuleForClusterLevel
          };
          this.detailsPopupResultData.push(insertData);
        });
      }
    });
    // View All - fragrance results
    if (this.reportData.fragranceRuleResults !== undefined && this.reportData.fragranceRuleResults !== null) {
      this.reportData.fragranceRuleResults.forEach(group => {
        if (group.ingredientSubstances !== null) {
          let statusIcon = '';
          if (group.ruleTypeDisplayName === 'Banned Substance' || group.ruleTypeDisplayName === 'Banned list - Group') {
            statusIcon = 'banned'; // banned
          } else if (group.statusCode === '30') {
            statusIcon = 'failed'; // failed
          } else if (group.statusCode === '20') {
            statusIcon = 'warning'; // warning
          } else if (group.statusCode === '41' || group.statusCode === '00') {
            statusIcon = 'no-rule-found'; // Rule does not exist
          } else if (group.statusCode === '45') {
            statusIcon = 'rule-execution-issues'; // Consult RA
          } else if (group.statusCode === '40' || group.statusCode === '42') {
            statusIcon = 'plm'; // Incorrect/Missing PLM data
          } else if (group.statusCode === '10') {
            if (group.ruleTypeID === 17 || group.ruleTypeID === 25) {
              statusIcon = 'non-restricted'; // non restricted
            } else {
              statusIcon = 'passed'; // passed
            }
          }
          const groupInsertData = {
            groupApplicability: 'Ingredient Group level',
            icon: statusIcon,
            rpcListForDisplay: group.rpcListForDisplay,
            ruleTypeDisplayName: group.ruleTypeDisplayName,
            statusCode: group.statusCode,
            marketNameForDisplay: group.marketNameForDisplay,
            ruleExecutionStatus: group.ruleExecutionStatus,
            pureCode: (group.pureCode === undefined) ? '' : group.pureCode,
            pureDescription: (group.pureDescription === undefined) ? '' : group.pureDescription,
            recipeQuantity: '',
            inciNumber: '',
            cfSubstanceExpressedAs: '',
            cf: '',
            isAllergen: '',
            hazardClass: null,
            ruleRPC: group.ruleRPC,
            allowedFuncFlag: null,
            ruleFunctionName: '',
            rndInputFunction: '',
            substanceFunctionInput: '',
            equalityCondition: '',
            minLimit: group.minLimit,
            maxLimit: group.maxLimit,
            recipeUoM: '',
            ruleUoM: (group.ruleUoM === null) ? '' : group.ruleUoM,
            groupName: '',
            isExempted: '',
            conflictingSubstance: null,
            combinationSubstance: null,
            combinationRatioToDisplay: null,
            ruleSpecificConditionName: '',
            ruleClaimName: '',
            phaseOutDate: null,
            warningText: '',
            otherRestrictionsAndRequirements: '',
            propertySource: null,
            propertyClass: null,
            propertyName: null,
            ingPropertyType: null,
            ingPropertyName: null,
            ruleSource: '',
            validityDate: '',
            substanceID: '',
            casNumber: '',
            parentCASNumber: '',
            einecsNumber: '',
            eNumber: '',
            insNumber: '',
            multiComponentName: group.multiComponentName,
            multiComponentID: group.multiComponentID,
            preparationMethod: group.preparationMethod,
            intendedUse: group.intendedUse,
            commentForDisplay: group.commentForDisplay,
            groupID: '',
            ruleID: group.ruleID,
            ruleVersion: '',
            convertedRecipeQty: this.reportSubmitService.exponentialToDecimal(group.convertedRecipeQty),
            finalUoM: group.finalUoM,
            acidityAsAceticAcid: '',
            aceticAcidUoM: '',
            brixOfLemon: '',
            brixOfLemonUoM: '',
            ruleTypeID: group.ruleTypeID,
            statusDescription: group.statusDescription,
            ingredientDescription: '',
            specId: '',
            amendmentNumber: ''
          };
          this.detailsPopupResultData.push(groupInsertData);
          group.ingredientSubstances.forEach(substance => {
            let insertData = {};
            insertData = {
              groupApplicability: 'Ingredient Individual level',
              icon: statusIcon,
              marketNameForDisplay: group.marketNameForDisplay,
              rpcListForDisplay: group.rpcListForDisplay,
              ruleTypeDisplayName: group.ruleTypeDisplayName,
              statusCode: group.statusCode,
              ruleExecutionStatus: group.ruleExecutionStatus,
              pureCode: substance.pureCode,
              pureDescription: (substance.pureDescription === null) ? '' : substance.pureDescription,
              recipeQuantity: (substance.recipeQuantity !== undefined && substance.recipeQuantity !== null) ? substance.recipeQuantity : '',
              inciNumber: '',
              cfSubstanceExpressedAs: '',
              cf: '',
              isAllergen: '',
              hazardClass: null,
              ruleRPC: group.ruleRPC,
              allowedFuncFlag: null,
              ruleFunctionName: '',
              rndInputFunction: (substance.substanceFunction !== null) ? substance.substanceFunction.functionName : '',
              substanceFunctionInput: '',
              equalityCondition: '',
              minLimit: '',
              maxLimit: '',
              recipeUoM: (substance.recipeUoM !== undefined && substance.recipeUoM !== null) ? substance.recipeUoM : '',
              ruleUoM: '',
              groupName: '',
              isExempted: substance.isExempted,
              conflictingSubstance: null,
              combinationSubstance: null,
              combinationRatioToDisplay: null,
              ruleSpecificConditionName: '',
              ruleClaimName: '',
              phaseOutDate: '',
              warningText: '',
              warningTextStripped: '',
              otherRestrictionsAndRequirements: '',
              otherRestrictionsAndRequirementsStripped: '',
              propertySource: null,
              propertyClass: null,
              propertyName: null,
              ingPropertyType: null,
              ingPropertyName: null,
              ruleSource: '',
              validityDate: '',
              substanceID: substance.sirId,
              casNumber: substance.cas,
              parentCASNumber: substance.parentCASNumber,
              einecsNumber: '',
              eNumber: substance.eNumber,
              insNumber: '',
              multiComponentName: group.multiComponentName,
              multiComponentID: group.multiComponentID,
              preparationMethod: group.preparationMethod,
              intendedUse: group.intendedUse,
              commentForDisplay: group.commentForDisplay,
              groupID: '',
              ruleID: group.ruleID,
              ruleVersion: '',
              convertedRecipeQty: (substance.convertedRecipeQty !== undefined && substance.convertedRecipeQty !== null) ? this.reportSubmitService.exponentialToDecimal(substance.convertedRecipeQty) : '',
              finalUoM: (substance.finalUoM !== undefined && substance.finalUoM !== null) ? substance.finalUoM : '',
              acidityAsAceticAcid: '',
              aceticAcidUoM: '',
              brixOfLemon: '',
              brixOfLemonUoM: '',
              ruleTypeID: group.ruleTypeID,
              statusDescription: group.statusDescription,
              ingredientDescription: '',
              specId: '',
              amendmentNumber: ''
            };
            this.detailsPopupResultData.push(insertData);
          });
        }
      });
    }
    // ends
    const tempDetailsData = this.detailsPopupResultData;
    this.detailsPopupResultData = [];
    tempDetailsData.forEach((rule, index) => {
      this.rpcLeafNodes.forEach(leaf => {
        if ((rule.ruleTypeID === 46 || rule.ruleTypeID === 47)) {
          const rowData = this.detailsPopupResultData.filter(s => s.ruleTypeID === rule.ruleTypeID && s.pureCode === rule.pureCode);
          if (rowData.length <= 0) {
            this.detailsPopupResultData.push(rule);
          }
        } else if (leaf.isChecked === 'checked' && rule.rpcListForDisplay === leaf.fullText && rule.marketNameForDisplay === leaf.market) {
          this.detailsPopupResultData.push(rule);
        }
      });
    });
    // fragrance details popup
    this.detailFragrancePopupResultData = [];
    if (this.reportData.fragranceRuleResults !== undefined && this.reportData.fragranceRuleResults !== null && this.fragranceFlag === 'true') {
      this.reportData.fragranceRuleResults.forEach(group => {
        if (group.ingredientSubstances !== null) {
          let statusIcon = '';
          if (group.ruleTypeDisplayName === 'Banned Substance' || group.ruleTypeDisplayName === 'Banned list - Group') {
            statusIcon = 'banned'; // banned
          } else if (group.statusCode === '30') {
            statusIcon = 'failed'; // failed
          } else if (group.statusCode === '20') {
            statusIcon = 'warning'; // warning
          } else if (group.statusCode === '41' || group.statusCode === '00') {
            statusIcon = 'no-rule-found'; // Rule does not exist
          } else if (group.statusCode === '45') {
            statusIcon = 'rule-execution-issues'; // Consult RA
          } else if (group.statusCode === '40' || group.statusCode === '42') {
            statusIcon = 'plm'; // Incorrect/Missing PLM data
          } else if (group.statusCode === '10') {
            if (group.ruleTypeID === 17 || group.ruleTypeID === 25) {
              statusIcon = 'non-restricted'; // non restricted
            } else {
              statusIcon = 'passed'; // passed
            }
          }
          const groupInsertData = {
            groupApplicability: 'Ingredient Group level',
            icon: statusIcon,
            rpcListForDisplay: group.rpcListForDisplay,
            ruleTypeDisplayName: group.ruleTypeDisplayName,
            statusCode: group.statusCode,
            marketNameForDisplay: group.marketNameForDisplay,
            ruleExecutionStatus: group.ruleExecutionStatus,
            pureCode: (group.pureCode === undefined) ? '' : group.pureCode,
            pureDescription: (group.pureDescription === undefined) ? '' : group.pureDescription,
            recipeQuantity: '',
            inciNumber: '',
            cfSubstanceExpressedAs: '',
            cf: '',
            isAllergen: '',
            hazardClass: null,
            ruleRPC: group.ruleRPC,
            allowedFuncFlag: null,
            ruleFunctionName: '',
            rndInputFunction: '',
            substanceFunctionInput: '',
            equalityCondition: '',
            minLimit: group.minLimit,
            maxLimit: group.maxLimit,
            recipeUoM: (group.recipeUOM == null) ? '' : group.recipeUOM,
            ruleUoM: (group.ruleUoM === null) ? '' : group.ruleUoM,
            groupName: '',
            isExempted: '',
            conflictingSubstance: null,
            combinationSubstance: null,
            combinationRatioToDisplay: null,
            ruleSpecificConditionName: '',
            ruleClaimName: '',
            phaseOutDate: null,
            warningText: '',
            otherRestrictionsAndRequirements: '',
            propertySource: null,
            propertyClass: null,
            propertyName: null,
            ingPropertyType: null,
            ingPropertyName: null,
            ruleSource: '',
            validityDate: '',
            substanceID: '',
            casNumber: '',
            parentCASNumber: '',
            einecsNumber: '',
            eNumber: '',
            insNumber: '',
            multiComponentName: group.multiComponentName,
            multiComponentID: group.multiComponentID,
            preparationMethod: group.preparationMethod,
            intendedUse: group.intendedUse,
            commentForDisplay: group.commentForDisplay,
            groupID: '',
            ruleID: '',
            ruleVersion: '',
            convertedRecipeQty: this.reportSubmitService.exponentialToDecimal(group.convertedRecipeQty),
            finalUoM: group.finalUoM,
            acidityAsAceticAcid: '',
            aceticAcidUoM: '',
            brixOfLemon: '',
            brixOfLemonUoM: '',
            ruleTypeID: group.ruleTypeID,
            statusDescription: group.statusDescription,
            ingredientDescription: group.specDescription,
            specId: group.specID,
            amendmentNumber: group.amendmentNumber === null ? '-' : group.amendmentNumber
          };
          this.detailFragrancePopupResultData.push(groupInsertData);
          group.ingredientSubstances.forEach(substance => {
            let insertData = {};
            insertData = {
              groupApplicability: 'Ingredient level',
              icon: statusIcon,
              marketNameForDisplay: group.marketNameForDisplay,
              rpcListForDisplay: group.rpcListForDisplay,
              ruleTypeDisplayName: group.ruleTypeDisplayName,
              statusCode: group.statusCode,
              ruleExecutionStatus: group.ruleExecutionStatus,
              pureCode: substance.pureCode,
              pureDescription: (substance.pureDescription === null) ? '' : substance.pureDescription,
              recipeQuantity: (substance.recipeQuantity !== undefined && substance.recipeQuantity !== null) ? substance.recipeQuantity : '',
              inciNumber: '',
              cfSubstanceExpressedAs: '',
              cf: '',
              isAllergen: '',
              hazardClass: group.hazardClass == null ? '' : group.hazardClass,
              ruleRPC: group.ruleRPC == null ? '' : group.ruleRPC,
              allowedFuncFlag: '',
              ruleFunctionName: '',
              rndInputFunction: '',
              substanceFunctionInput: '',
              equalityCondition: '',
              minLimit: '',
              maxLimit: '',
              recipeUoM: (substance.uoM !== undefined && substance.uoM !== null) ? substance.uoM : '',
              ruleUoM: '',
              groupName: '',
              isExempted: substance.isExempted,
              conflictingSubstance: '',
              combinationSubstance: '',
              combinationRatioToDisplay: '',
              ruleSpecificConditionName: '',
              ruleClaimName: '',
              phaseOutDate: '',
              warningText: '',
              warningTextStripped: '',
              otherRestrictionsAndRequirements: '',
              otherRestrictionsAndRequirementsStripped: '',
              propertySource: '',
              propertyClass: '',
              propertyName: '',
              ingPropertyType: '',
              ingPropertyName: '',
              ruleSource: '',
              validityDate: '',
              substanceID: substance.sirId,
              casNumber: substance.cas,
              parentCASNumber: substance.parentCASNumber,
              einecsNumber: '',
              eNumber: substance.eNumber,
              insNumber: '',
              multiComponentName: group.multiComponentName == null ? '' : group.multiComponentName,
              multiComponentID: group.multiComponentID == null ? '' : group.multiComponentID,
              preparationMethod: group.preparationMethod,
              intendedUse: group.intendedUse,
              commentForDisplay: '', // group.commentForDisplay,
              groupID: '',
              ruleID: '',
              ruleVersion: '',
              convertedRecipeQty: (substance.convertedRecipeQty !== undefined && substance.convertedRecipeQty !== null) ? this.reportSubmitService.exponentialToDecimal(substance.convertedRecipeQty) : '',
              finalUoM: (substance.finalUoM !== undefined && substance.finalUoM !== null) ? substance.finalUoM : '',
              acidityAsAceticAcid: '',
              aceticAcidUoM: '',
              brixOfLemon: '',
              brixOfLemonUoM: '',
              ruleTypeID: group.ruleTypeID,
              statusDescription: group.statusDescription,
              ingredientDescription: '', // group.specDescription,
              specId: '', // group.specID
              amendmentNumber: '',
            };
            this.detailFragrancePopupResultData.push(insertData);
          });
        }
      });
      const tempFragranceDetailsData = this.detailFragrancePopupResultData;
      this.detailFragrancePopupResultData = [];
      tempFragranceDetailsData.forEach((rule, index) => {
        if ((rule.ruleTypeID === 46 || rule.ruleTypeID === 47)) {
          const rowData = this.detailFragrancePopupResultData.filter(s => s.ruleTypeID === rule.ruleTypeID && s.groupApplicability === rule.groupApplicability && s.pureCode === rule.pureCode);
          if (rowData.length <= 0) {
            this.detailFragrancePopupResultData.push(rule);
          }
        }
      });
    }
    // ends fragrance
    this.reportData.rulesResults.forEach(rule => {
      this.rpcLeafNodes.forEach(leaf => {
        if (leaf.isChecked === 'checked' && rule.rpcListForDisplay === leaf.fullText && rule.marketNameForDisplay === leaf.market) {
          // 45 Product Minimum Mandatory Substance(s) 41 Product Standard 10 Product Warnings 11 Minimum Mandatory Substance
          if (rule.ruleTypeID === 11 || rule.ruleTypeID === 41 || rule.ruleTypeID === 10 || rule.ruleTypeID === 45) {
            // tslint:disable-next-line: triple-equals
            rule.warningText = (rule.warningText == null || rule.warningText == '') ? rule.warningStatement : rule.warningText;
            this.filteredProductData.push(rule);
          }
        }
      });
    });
    this.reportData.productWarnings.forEach(rule => {
      this.rpcLeafNodes.forEach(leaf => {
        if (leaf.isChecked === 'checked' && rule.rpcList === leaf.fullText && rule.marketNameForDisplay === leaf.market) {
          rule.ruleTypeDisplayName = 'Product Warning';
          rule.rpcListForDisplay = rule.rpcList;
          // tslint:disable-next-line: triple-equals
          rule.warningText = (rule.warning == null || rule.warning == '') ? rule.warningStatement : rule.warning;
          this.filteredProductData.push(rule);
        }
      });
    });
    if (!isClusterLevelRequest) {
      this.reportData.productPropertiesResults.forEach(item => {
        this.rpcLeafNodes.forEach(leaf => {
          if (leaf.isChecked === 'checked' && item.rpcList === leaf.fullText && item.marketNameForDisplay === leaf.market) {
            this.filteredReportDataProductPropertiesResults.push(item);
          }
        });
      });
    } else if (isClusterLevelRequest) {
      this.reportData.productPropertiesResults.forEach(item => {
        this.rpcLeafNodes.forEach(leaf => {
          if (leaf.isChecked === 'checked' && item.rpcList === leaf.fullText && item.marketNameForDisplay === leaf.market && item.supressMarketRuleForClusterLevel === false && checkedClusterMarketList === true) {
            this.filteredReportDataProductPropertiesResults.push(item);
          } else if (leaf.isChecked === 'checked' && item.rpcList === leaf.fullText && item.marketNameForDisplay === leaf.market && checkedClusterMarketList === false) {
            this.filteredReportDataProductPropertiesResults.push(item);
          }
        });
      });
      // Below logic handles to match number of property counts
      if (this.initialtotalProperties > 0) {
        const propertyIds = this.filteredReportDataProductPropertiesResults.map(property => {
          return property.propertyId;
        });
        const leafCheckedPropertylength = this.rpcLeafNodes.filter(m => m.isChecked === 'checked').length;
        const totalProperties = propertyIds.filter((pure, index, self) => self.indexOf(pure) === index).filter(pure => pure !== null);
        if (totalProperties.length !== this.initialtotalProperties && leafCheckedPropertylength > 0) {
          if (totalProperties.length > this.initialtotalProperties) {
            let isPropertyExist = false;
            this.filteredReportDataProductPropertiesResults.forEach(fresult => {
              isPropertyExist = false;
              this.initialuniqueProperties.forEach(supProperty => {
                if (supProperty === fresult.propertyId) {
                  isPropertyExist = true;
                }
              });
              if (isPropertyExist === false) {
                this.filteredReportDataProductPropertiesResults = this.filteredReportDataProductPropertiesResults.filter(({ propertyId }) => propertyId !== fresult.propertyId);
              }
            });
          } else if (totalProperties.length < this.initialtotalProperties) {
            let isPropertyExist = false;
            this.initialuniqueProperties.forEach(supPropertyID => {
              isPropertyExist = false;
              this.filteredReportDataProductPropertiesResults.forEach(result => {
                if (supPropertyID === result.propertyId) {
                  isPropertyExist = true;
                }
              });
              if (isPropertyExist === false) {
                const ruleProperty = this.reportData.productPropertiesResults.filter(t => t.propertyId === supPropertyID && t.supressMarketRuleForClusterLevel === true).map(s => s);
                if (ruleProperty.length > 0) {
                  ruleProperty.forEach(rulePropertydata => {
                    const isPropRecordtoPush = this.rpcLeafNodes.filter(f => f.isChecked === 'checked' && f.market.toLowerCase() === rulePropertydata.marketNameForDisplay.toLowerCase()).length;
                    if (isPropRecordtoPush > 0) {
                      this.filteredReportDataProductPropertiesResults.push(rulePropertydata);
                    }
                  });

                }
              }
            });
          }
          this.filteredReportDataProductPropertiesResults.sort((a, b) => (a.propertyId > b.propertyId) ? 1 : -1);
        }
      }
    }
  }
  /* istanbul ignore next */
  formViewDetailedReportData() {
    this.filteredReportDataformulationRequestDetails = [];
    this.filterFormulation = [];
    this.reportData.formulationRequestDetails.forEach(formulation => {
      if (formulation.ruleTypeID !== 11 && formulation.ruleTypeID !== 41 && formulation.ruleTypeID !== 10 && formulation.ruleTypeID !== 45) {
        this.filterFormulation.push(formulation);
      }
    });
    this.filterFormulation.forEach(formulation => {
      this.filteredReportDataformulationRequestDetails.push(formulation);
    });
    this.viewDetailedReportData = this.filteredReportDataformulationRequestDetails;
    this.viewDetailedReportData.forEach(formulation => {
      formulation.recipeQuantity = this.reportSubmitService.exponentialToDecimal(formulation.recipeQuantity);
      formulation.formulationStatus = [];
      this.filteredReportDataRulesResults.forEach(rule => {
        if (formulation.pureCode === rule.pureCode) {
          if (rule.ruleTypeDisplayName === 'Banned Substance' || rule.ruleTypeDisplayName === 'Banned list - Group') {
            formulation.formulationStatus.push(1); // banned
          } else if (rule.statusCode === '30') {
            formulation.formulationStatus.push(2); // failed
          } else if (rule.statusCode === '20') {
            formulation.formulationStatus.push(3); // warning
          } else if (rule.statusCode === '41' || rule.statusCode === '00') {
            formulation.formulationStatus.push(4); // Rule does not exist
          } else if (rule.statusCode === '45') {
            formulation.formulationStatus.push(5); // Consult RA
          } else if (rule.statusCode === '40' || rule.statusCode === '42') {
            formulation.formulationStatus.push(8); // Incorrect/Missing PLM data
          } else if (rule.statusCode === '10') {
            if (rule.ruleTypeID === 17 || rule.ruleTypeID === 25) {
              formulation.formulationStatus.push(7); // non restricted
            } else {
              formulation.formulationStatus.push(6); // passed
            }
          }
          formulation.ruleTypeDisplayName = rule.ruleTypeDisplayName;
          if (rule.ruleTypeID === 46 || rule.ruleTypeID === 47) {
            formulation.groupApplicability = 'Ingredient Level';
          }
        }
      });
    });
    this.viewDetailedReportData.forEach(item => {
      if (item.formulationStatus.length >= 1) {
        item.formulationStatus.sort();
        if (item.formulationStatus[0] === 1) {
          item.icon = 'banned'; // banned
          item.tooltip = 'Banned';
          item.ordering = 'a';
        } else if (item.formulationStatus[0] === 2) {
          item.icon = 'failed'; // failed
          item.tooltip = 'Failed';
          item.ordering = 'b';
        } else if (item.formulationStatus[0] === 3) {
          item.icon = 'warning'; // warning
          item.tooltip = 'Warnings';
          item.ordering = 'c';
        } else if (item.formulationStatus[0] === 4) {
          item.icon = 'no-rule-found'; // Rule does not exist
          item.tooltip = 'Rule does not exist';
          item.ordering = 'h';
        } else if (item.formulationStatus[0] === 5) {
          item.icon = 'rule-execution-issues'; // Consult RA
          item.tooltip = 'Consult RA';
          item.ordering = 'f';
        } else if (item.formulationStatus[0] === 6) {
          item.icon = 'passed'; // passed
          item.tooltip = 'No Issues Identified';
          item.ordering = 'd';
        } else if (item.formulationStatus[0] === 7) {
          item.icon = 'non-restricted'; // non restricted
          item.tooltip = 'Non - Restricted';
          item.ordering = 'e';
        } else if (item.formulationStatus[0] === 8) {
          item.icon = 'plm'; // Incorrect/Missing PLM data
          item.tooltip = 'Input Missing / Data Mismatch';
          item.ordering = 'g';
        }
        if (item.formulationStatus.indexOf(4) >= 0 && item.formulationStatus.indexOf(6) >= 0 && item.formulationStatus.indexOf(1) === -1 && item.formulationStatus.indexOf(2) === -1 && item.formulationStatus.indexOf(3) === -1 && item.formulationStatus.indexOf(5) === -1 && item.formulationStatus.indexOf(7) === -1) {
          item.icon = 'passed'; // passed
          item.tooltip = 'No Issues Identified';
          item.ordering = 'd';
        }
        if (item.formulationStatus.indexOf(4) >= 0 && item.formulationStatus.indexOf(7) >= 0 && item.formulationStatus.indexOf(1) === -1 && item.formulationStatus.indexOf(2) === -1 && item.formulationStatus.indexOf(3) === -1 && item.formulationStatus.indexOf(5) === -1 && item.formulationStatus.indexOf(6) === -1) {
          item.icon = 'non-restricted'; // non restricted
          item.tooltip = 'Non - Restricted';
          item.ordering = 'e';
        }
      } else {
        item.icon = 'no-rule-found'; // Rule does not exist
        item.tooltip = 'Rule does not exist';
        item.ordering = 'h';
      }
    });
    if (this.reportData.isMultiComponent === 'Y') {
      this.sortedCompositionData = [];
      this.selectedMultiComponents = [];
      this.multiComponentData = this.reportData.multiComponent;
      this.multiComponentData.forEach(item => {
        this.viewDetailedReportData.forEach(composition => {
          if (item.multiComponentIngredientName === composition.specID && item.multiComponentName === composition.multiComponentName) {
            composition.componentSet = item.multiComponentName;
          }
        });
      });
      this.multiComponentData.forEach(item => {
        const filterCom = this.selectedMultiComponents.filter(com => com.component === item.multiComponentName);
        if (filterCom.length === 0) {
          this.selectedMultiComponents.push({
            specID: item.multiComponentIngredientName,
            component: item.multiComponentName,
          });
        }
      });
      this.selectedMultiComponents.forEach(component => {
        this.sortedCompositionData.push({
          show: true,
          composition: true,
          specID: component.component,
          specDescription: '',
          specQuantity: '',
          specUoM: '',
          ingredientPath: '',
          ingDescription: '',
          ingQuantity: '',
          pureCode: '',
          pureDescription: '',
          recipeQuantityToDisplay: '',
          recipeUoM: '',
          substanceFunctionInput: ''
        });
        this.viewDetailedReportData.forEach(composition => {
          if (component.component === composition.componentSet) {
            this.sortedCompositionData.push(composition);
          }
        });
      });
      this.viewDetailedReportData = this.sortedCompositionData;
    } else {
      // ordering
      const specIDs = this.viewDetailedReportData.map(item => {
        return item.specID;
      });
      this.uniqueSpecIDs = specIDs.filter((pure, index, self) => self.indexOf(pure) === index);
      this.uniqueSpecIDs.forEach(specID => {
        this.viewDetailedReportData.forEach(item => {
          if (specID === item.specID) {
            this.viewDetailedReportDataOrdering.push(item);
          }
        });
      });
      // ordering // grouping
      if (this.viewDetailedReportData.length > 0) {
        this.viewDetailedReportData[0].show = true;
        for (let i = 0; i < this.viewDetailedReportData.length - 1; i++) {
          for (let j = i + 1; j < this.viewDetailedReportData.length; j++) {
            this.viewDetailedReportData[j].show = true;
          }
        }
      }
    }
  }
  /* istanbul ignore next */
  completeRequest() {
    localStorage.setItem('isViewReportClicked', 'true');
    localStorage.setItem('requestStatus', 'Completed');
    sessionStorage.setItem('IsPLmRequest', 'yes');
    this.dataTrans.setComponentName('SubmitReportRA');
    //  this.loadingWorklistMessage = 'Getting FAsT Report';
    const apiurl = environment.APIFAsT + '/reportrequest/' + this.requestid;
    this.loading = true;
    this.worklistservice.getData(apiurl).subscribe((response1) => {
      if (response1 !== null) {
        const val: any = {
          requestID: response1.requestDisplayID,
          requestStatus: response1.requestStatus
        };
        this.worklistservice.setRecipeData(val);
        recipeModel.requestDisplayID = response1.requestDisplayID;
        this.sendRequestId(response1);
        localStorage.setItem('CompletedReport', JSON.stringify(response1));
        this.dataTrans.setSelectedStep(3);
        setTimeout(() => {
          const splitUrl = this.route.url.split('/');
          if (splitUrl[1] !== 'unauthorized') {
            this.route.navigate(['assess-request-ra/submit-report-plm/' + response1.requestDisplayID + '/' + this.divData + '']);
          }
        }, 1000);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
  sendRequestId(user) {
    this.dataTrans.updateRequestId(user);
  }
  /* istanbul ignore next */
  getRuleAssessmentRequest() {
    this.loading = true;
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
      this.loadingMessage = 'Getting FAsT Report';
    }
    const apiurl = environment.APIFAsTRule + '/rules/engine/assessment';
    const requestStatus = this.actualRequestStatus;
    sessionStorage.setItem('actualStatus', requestStatus);
    if (requestStatus === 'Completed') {
      const completedReport = JSON.parse(localStorage.getItem('CompletedReport'));
      const requestDisplayID = this.requestid !== undefined ? this.requestid : completedReport.requestDisplayID;
      sessionStorage.setItem('completeDate', completedReport.completedDate);
      this.commentsPayLoad = {
        RequestStatusDisplayId: this.requestid,
        ReportComments: null
      };
      this.getComments(this.commentsPayLoad);
      this.isCompleted = true;
      this.saveCommentButton = true;

      this.dataTrans.setSelectedStep(3);
      this.completeJSON = completedReport;
      const val: any = {
        requestID: this.requestid,
        requestStatus: 'Completed'
      };
      this.isRuleDoesntExistTabtoShow = completedReport.isRuleDoesnotExistToShow;
      this.worklistservice.setRecipeData(val);
      setTimeout(() => {
        this.renderReport(completedReport);
      }, 1500);
    } else {
      this.blinkcomment = true;
      this.isCompleted = false;
      this.saveCommentButton = false;
      localStorage.setItem('isCompleted', 'false');
      this.assessraService.postData(apiurl, this.assessmentModel).subscribe((response) => {
        this.commentsPayLoad = {
          RequestStatusDisplayId: response.requestDisplayID,
          ReportComments: null
        };
        this.getComments(this.commentsPayLoad);
        this.completeJSON = response;
        this.renderReport(response);
      }, (error) => {
        this.loading = false;
        this.handleErrors(error);
      });
    }
  }
  /* istanbul ignore next */
  renderReport(response: any) {
    this.completeJSON = response;
    recipeModel.rpcRefinementData = this.completeJSON;
    this.tfcPathMessage = this.reportData.tfc.tfcFullText;
    this.reportData = response;
    this.keyDate = response.marketDetails.projDetail === null || response.marketDetails.projDetail === undefined ? '' : response.marketDetails.projDetail.keyDate !== null ? response.marketDetails.projDetail.keyDate.split('T')[0] : '';
    this.validityArea = response.marketDetails.projDetail === null || response.marketDetails.projDetail === undefined ? '' : response.marketDetails.projDetail.validityArea != null ? response.marketDetails.projDetail.validityArea : '';
    this.validityAreaKeyDate = (this.validityArea === '' && this.keyDate === '') ? '' : this.validityArea + '/' + this.keyDate;
    this.reportData.compositionTabData = this.reportData.formulationRequestDetails;
    this.getCompletedDate();
    const data = this.reportData.formulationRequestDetails.filter(f => f.ingType === 'ZING_FRAGR');
    this.fragranceMessage = '';
    if (data.length > 0) {
      this.fragranceMessage = 'Note : More than one fragrance/s are present in the formula, an offline fragrance assessment by the fragrance expert is required.';
      this.fragranceMessage = data.map(s => s.specID).filter((n, i) => data.map(s => s.specID).indexOf(n) === i).length > 1 ? this.fragranceMessage : '';
    }
    if (this.fragranceMessage === '') {
      if ((this.reportData.fragranceRuleResults === undefined || this.reportData.fragranceRuleResults === null || this.reportData.fragranceRuleResults.length === 0) && (this.reportData.tfc.tfcFullText.split('_')[0] !== 'F&R')) {
        this.fragranceMessage = localStorage.getItem('FragranceMessage');
      }
    }
    if (this.reportData.productPropertiesResults !== null) {
      this.reportData.productPropertiesResults.forEach(property => {
        property.propertyId = `${property.propertyClass} - ${property.propertyName}`;
      });
    }
    this.isMultiComp = this.reportData.isMultiComponent === 'Y' ? 'Yes' : 'No';
    this.findUniqueMarkets = [];
    this.multiComponentData = this.reportData.multiComponent;
    if (this.reportData.marketRPCList !== null) {
      this.reportData.marketRPCList.forEach(market => {
        if (market.isCluster === true && market.marketName !== 'Unilever' && market.marketName !== this.reportData.marketDetails.marketName) {
          this.findUniqueMarkets.push(market.marketName);
        }
      });
    }
    const uniqueMarkets: Set<any> = new Set(this.findUniqueMarkets);
    this.uniqueMarkets = Array.from(uniqueMarkets);
    this.getTFCStatus(this.reportData.tfc.tfcId);
    this.getstaticMarketRPCs(this.reportData.marketDetails.marketId, this.reportData.tfc.tfcId);
    this.pureDetailsColumns = REPORT_PURE_DETAILS;
    this.detailsPopupColumns = DETAILS_POPUP.filter(w => w.show !== 'N');
    this.divisionData = this.reportData.tfc.tfcLevel === 5 ? this.reportData.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : this.reportData.tfc.parentTFC.parentTFC.parentTFC.tfcName;
    setTimeout(() => {
      this.isFRdivision = this.divData === 'F&R' ? false : true;
      this.divData === 'F&R' ? this.productViewDetailedReportColumns = PRODUCT_VIEW_DETAILED_REPORT_COLUMNS_VIEW : this.productViewDetailedReportColumns = PRODUCT_VIEW_DETAILED_REPORT_COLUMNS.filter(f => f.show !== 'N');
      this.divData === 'F&R' ? this.propertyDetailsColumns = PROPERTY_DETAIL_COLUMNS_TAB_VIEW : this.propertyDetailsColumns = PROPERTY_DETAIL_COLUMNS;
      this.divData === 'F&R' ? this.propertyDetailsColumnsNew = PROPERTY_DETAIL_COLUMNS_VIEW : this.propertyDetailsColumnsNew = PROPERTY_DETAIL_COLUMNS;
      this.divData === 'F&R' ? this.reportProductDetailsColumns = PRODUCT_DETAILS_COLUMNS_VIEW : this.reportProductDetailsColumns = PRODUCT_DETAILS_COLUMNS;
      this.sendData(this.completeJSON);
    }, 1000);
  }
  /* istanbul ignore next */
  handleErrors(error) {
    if (error.status === 401) {
      this.matSnackBar.open('Authentication Failed', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    } else if (error.error.message === undefined) {
      this.matSnackBar.open('System error occured. Please contact administrator.', 'close', {
        duration: 4200,
        panelClass: ['error']
      });
    } else {
      this.matSnackBar.open(error.error.message, 'close', {
        duration: 3700,
        panelClass: ['error']
      });
    }
  }
  /* istanbul ignore next */
  downloadExcel() {
    this.exportReportExcelService.generateReport(this.viewDetailedReportData);
  }
  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  newShowOverlay(htmlContent) {
    this.newOverlayText = htmlContent;
    this.newHideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  newHideOverlayPanel() {
    this.newHideOverlay = true;
  }
  viewReortShowOverlay(htmlContent) {
    this.viewReortOverlayText = htmlContent;
    this.viewReortHideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  viewReortHideOverlayPanel() {
    this.viewReortHideOverlay = true;
  }
  popupShowOverlay(htmlContent) {
    this.popupOverlayText = htmlContent;
    this.popupHideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  popupHideOverlayPanel() {
    this.popupHideOverlay = true;
  }
  onFilter(event) {
    this.filteredRows = Object.keys(event.filters).length === 0
      ? []
      : event.filteredValue;
  }

  clearFilters(dtshowDetailsPopup) {
    this.showDetailsPopup = false;
    dtshowDetailsPopup.reset();
    this.filteredRows = [];
    for (const val of this.detailsPopupColumns) {
      const colname = val.field + '_val';
      // tslint:disable-next-line: whitespace
      if (
        <HTMLInputElement> document.getElementById(colname) &&
        (<HTMLInputElement> document.getElementById(colname)).value
      ) {
        // tslint:disable-next-line: whitespace
        (<HTMLInputElement> document.getElementById(colname)).value = '';
      }
    }
  }
  clearFiltersEye(dtexstingPrepareRule) {
    this.isViewRules = false;
    dtexstingPrepareRule.reset();
    this.filteredRows = [];
    for (const val of this.viewRuleColumns) {
      const colname = val.field + '_val';
      // tslint:disable-next-line: whitespace
      if (
        <HTMLInputElement> document.getElementById(colname) &&
        (<HTMLInputElement> document.getElementById(colname)).value
      ) {
        // tslint:disable-next-line: whitespace
        (<HTMLInputElement> document.getElementById(colname)).value = '';
      }
    }
  }
  // /* istanbul ignore next */
  clearFragranceFilters(dtFragranceDetailsPopup) {
    this.showFragranceDetailsPopup = false;
    dtFragranceDetailsPopup.reset();
    this.filteredRows = [];
    for (const val of this.detailsFragrancePopupColumns) {
      const colname = val.field + '_val';
      // tslint:disable-next-line: whitespace
      if (
        <HTMLInputElement> document.getElementById(colname) &&
        (<HTMLInputElement> document.getElementById(colname)).value
      ) {
        // tslint:disable-next-line: whitespace
        (<HTMLInputElement>document.getElementById(colname)).value = '';
      }
    }
  }
  completeReportFeature() {
    setTimeout(() => {
      const [compBanned, compFailed, compWarning, compInputMissing, compositionRuleDoesnotExist, compRuleExecIssue, compNoIssue, compNonRest] = this.compostionTab;
      const [failed, warnings, inputMssing, ruleDoesNotExist, ruleExecutionIssues, noIssues] = this.prodTab;
      this.completeJSON['compBanned'] = compBanned;
      this.completeJSON['compFailed'] = compFailed;
      this.completeJSON['compInputMissing'] = compInputMissing;
      this.completeJSON['compositionRuleDoesnotExist'] = compositionRuleDoesnotExist;
      this.completeJSON['compNoIssue'] = compNoIssue;
      this.completeJSON['compNonRest'] = compNonRest;
      this.completeJSON['compRuleExecIssue'] = compRuleExecIssue;
      this.completeJSON['compWarning'] = compWarning;
      this.completeJSON['prodFailed'] = failed;
      this.completeJSON['prodWarning'] = warnings;
      this.completeJSON['prodInputMissing'] = inputMssing;
      this.completeJSON['prodRuleNotExist'] = ruleDoesNotExist;
      this.completeJSON['prodRuleExecIssue'] = ruleExecutionIssues;
      this.completeJSON['prodNoIssue'] = noIssues;
      this.completeJSON['compositionStatus'] = this.compositionReportBarTooltipText;
      this.completeJSON['productStatus'] = this.productReportBarTooltipText;
      this.completeJSON['compositionTotalPures'] = this.totalCompositionPures;
      this.completeJSON['productTotalPures'] = this.totalProductsProperties;
      this.loading = false;
    }, 5000);
  }

  toggleComments() {
    this.isComments = !this.isComments;
    this.isCompleted = localStorage.getItem('isCompleted') === 'true' ? true : this.isCompleted;
  }
  saveComments() {
    this.loading = true;
    this.loadingMessage = 'Comments are saving';
    const serviceCall = this.assessraService.getAndPostComments(this.commentsPayLoad);
    serviceCall.subscribe((data) => {
      this.commentsPayLoad.ReportComments = data.result;
      localStorage.setItem('ReportComments', data.result);
      if (this.isComments) {
        this.toggleComments();
        this.matSnackBar.open(`Comments saved successfully`, 'close', {
          duration: 5000,
          panelClass: ['success']
        });
        this.loading = false;
      }
    }, (error) => {
      if (error.status !== 200) {
        console.log('error');
      }
    });
  }
  setComments(data) {
    this.commentsPayLoad = data;
    const isAnkercount = parseInt(localStorage.getItem('ankerCount'), 0) <= 7 ? false : true;
    const isEditorCount = parseInt(localStorage.getItem('editorCount'), 0) <= 2000 ? false : true;
    if (isAnkercount === false && isEditorCount === false) {
      this.saveCommentButton = false;
    } else {
      this.saveCommentButton = true;
    }
  }
  getComments(payLoad) {
    this.assessraService.getAndPostComments(payLoad).subscribe((data) => {
      localStorage.setItem('ReportComments', data.result);
      if (data.result.length > 0) {
        this.editorData = data.result.replace(/class=\"table\"/g, '').replace(/"/g, '\'');
        this.blinkcomment = true;
      } else if (this.actualRequestStatus === 'Completed') {
        this.blinkcomment = false;
      }
    }, () => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  getCompletedDate() {
    const apiurl = environment.APIFAsT + '/getCompletedDetails/' + `${this.reportData.requestID}`;
    this.assessraService.getData(apiurl).subscribe((response) => {
      this.completedDate = response.result;
      this.completeJSON['completedDate'] = response.result;
    });
  }
  sendData(data) {
    this.dataTrans.getRequestData(data);
  }
  /* istanbul ignore next */
  loadReportData() {
    this.formMultiComponent();
    this.filterReportData();
    this.getUniquePures();
    this.findPureStatus();
    this.formPureTabContent();
    this.formViewDetailedReportData();
  }
  /* istanbul ignore next */
  addDefaultRPCForNonRegulatoryProductClass(marketname) {
    this.rpcLeafNodes.push({
      market: marketname,
      leaf: false,
      id: '0',
      isChecked: null,
      fullText: 'No Regulatory Product Class is Mapped',
      parentId: '#'
    });
    if (recipeModel.rpcRefinementData !== undefined) {
      recipeModel.rpcRefinementData.rpcLeafNodes = this.rpcLeafNodes;
    }
  }
  findLinkWarning(input) {
    if (input === undefined) {
      return false;
    } else {
      const strValue = input;
      const ar = strValue.match(/<a.*?<\/a>/g);
      if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
        return true;
      } else {
        return false;
      }
    }
  }
  iconCliecked(strValue) {
    this.newWarningVal = '';
    this.newWarningVal = strValue.replace(/<a/gi, '<a target="_blank"');
    this.iswarningLink = true;
  }
  closePopup() {
    this.iswarningLink = false;
  }
  findLinkORR(input) {
    const orrValue = input;
    const ar = orrValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      return true;
    } else {
      return false;
    }
  }
  iconClieckedORR(orrValue) {
    this.newORRVal = '';
    this.newORRVal = orrValue.replace(/<a/gi, '<a target="_blank"');
    this.isORRclicked = true;
  }
  closeORR() {
    this.isORRclicked = false;
  }
  // popup related functions
  iconPopupCliecked(strValue) {
    this.isPopupCompleted = true;
    this.newPopupWarningVal = '';
    this.newPopupWarningVal = strValue.replace(/<a/gi, '<a target="_blank"');
    this.isPopupWarningLink = true;
  }
  closeWarningPopup() {
    this.isPopupWarningLink = false;
    this.isPopupCompleted = false;
  }
  iconPopupClieckedORR(orrValue) {
    this.isPopupCompleted = true;
    this.newPopupORRVal = '';
    this.newPopupORRVal = orrValue.replace(/<a/gi, '<a target="_blank"');
    this.isPopupORRclicked = true;
  }
  closePopupORR() {
    this.isPopupORRclicked = false;
    this.isPopupCompleted = false;
  }
  closeLinkpopups() {
    this.iswarningLink = false;
    this.isORRclicked = false;
    this.isPopupWarningLink = false;
    this.isPopupORRclicked = false;
  }
  appendColortoAnchorTag(strValue) {
    const ar = strValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      return strValue.replace(/<a/gi, '<a style="color:orange !important"');
    } else {
      return strValue;
    }
  }
}
