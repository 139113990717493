import { Component, Input, OnChanges } from '@angular/core';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';

@Component({
  selector: 'app-rules-list',
  templateUrl: './rules-list.component.html',
  styleUrls: ['./rules-list.component.scss']
})
export class RulesListComponent implements OnChanges {
  @Input() data: any;
  @Input() tabName: any;
  masterworklistData: any;
  functionInput;
  functionName;
  functionRule;
  list = [];
  isIngredientPropertyValues = false;
  substanceFunctionInput = 'substanceFunctionInput';
  funcName = 'functionName';
  functionLstFromRules = 'functionLstFromRules';
  userInputClaimList = 'userInputClaimList';
  claimsListFromRules = 'claimsListFromRules';
  userInputSpecificConditionList = 'userInputSpecificConditionList';
  specialConditionListFromRules = 'specialConditionListFromRules';
  constructor(private assessRequestRaService: AssessRequestRaService) {
    this.masterworklistData = JSON.parse(localStorage.getItem('Data'));
  }
  ngOnChanges() {
    if (this.tabName === 'Mandatory Functions') {
      this.functionInput = this.substanceFunctionInput;
      this.functionName = this.funcName;
      this.functionRule = this.functionLstFromRules;
      if (!this.masterworklistData['formulationRequestDetails']) { return; }
      this.list = this.masterworklistData['formulationRequestDetails'].filter(v => v.functionLstFromRules !== null
        && v.functionLstFromRules.length > 0
      );
    } else if (this.tabName === 'Claims') {
      this.functionInput = this.userInputClaimList;
      this.functionName = this.funcName;
      this.functionRule = this.claimsListFromRules;
      let isProductPropsInputs = this.masterworklistData['productPropertiesInputs'];
      isProductPropsInputs = (!isProductPropsInputs) ? false : true;

      const propertyClaimList = this.masterworklistData['productPropertiesInputs'].filter(v => v.claimsListFromRules !== null
        && v.claimsListFromRules.length > 0);
      this.list = this.masterworklistData['formulationRequestDetails'].filter(v => v.claimsListFromRules !== null
        && v.claimsListFromRules.length > 0);

      this.list = this.list.concat(propertyClaimList);
    } else if (this.tabName === 'Conditional Requirements') {
      this.functionInput = this.userInputSpecificConditionList;
      this.functionName = this.funcName;
      this.functionRule = this.specialConditionListFromRules;
      let isProductPropsInputs = this.data['rpcRefinementData'];
      const isProductPropsInputsnew = isProductPropsInputs['rulesResults'];
      isProductPropsInputs = (!isProductPropsInputs) ? false : true;
      // const scClaimList = this.data['rulesResults'].filter(v => v.specialConditionListFromRules !== null
      //   && v.specialConditionListFromRules.length > 0);
      this.list = isProductPropsInputsnew.filter(v => v.specialConditionListFromRules !== null
        && v.specialConditionListFromRules.length > 0);
      // this.list = this.list.concat(scClaimList);
    } else if (this.tabName === 'Ingredient Property Values') {
      this.isIngredientPropertyValues = true;
      this.list = this.assessRequestRaService.INGREDIENT_PROPERTY_VALUES == null ? [] : this.assessRequestRaService.INGREDIENT_PROPERTY_VALUES;
    }
  }

}
